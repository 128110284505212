import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Badge, Card, CardBody, Container, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import { AttAdminApi } from "../../helpers/att_api_helper";
import { usePollingEffect } from "../../helpers/utils";
import ReactTimeAgo from "react-time-ago";
import AvailabilityStatus from "../../helpers/att-api-dtos/availability/availability-status.dto.interface";


const AvailabilityStatusPage = () => {

  const [integrationStatus, setIntegrationStatus] = useState<{
    integrationStatus: AvailabilityStatus[]
  }>({ integrationStatus: [] });

  usePollingEffect(
    async () => setIntegrationStatus(await AttAdminApi.availability.status.list()),
    [],
    { interval: 3000 }
  )

  const renderStatusBadge = (status: AvailabilityStatus) =>
    status.status === "online" ? (
      <Badge className="me-2 bg-success">Active</Badge>
    ) : (
      <Badge className="me-2 bg-danger">Offline</Badge>
    );

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Availability Status | ATT</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Live Availability" />
          <Row>
            <Card>
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  Status of Live Availability
                </h4>
              </div>
              <CardBody className="px-0">
                <SimpleBar className="table-responsive px-3">
                  <table className="table align-middle table-nowrap table-borderless">
                    <tbody>
                      {integrationStatus.integrationStatus.map((integrationStatus) => {
                        return (
                          <tr key={integrationStatus.provider}>
                            <td style={{ width: "50px" }}>
                              <div
                                className={
                                    integrationStatus.status === "online"
                                    ? "font-size-22 text-success"
                                    : "font-size-22 text-danger"
                                }
                              >
                                <i
                                  className={
                                    integrationStatus.status === "online"
                                      ? "bx bx-check-circle d-block"
                                      : "bx bx-dizzy d-block"
                                  }
                                ></i>
                              </div>
                            </td>

                            <td>
                              <div>
                                <h5 className="font-size-14 mb-1">
                                  {integrationStatus.provider}
                                </h5>
                                <p className="text-muted mb-0 font-size-12">
                                  {renderStatusBadge(integrationStatus)}
                                </p>
                              </div>
                            </td>

                            <td>
                              <div className="text-end">
                                <p className="text-muted mb-0 font-size-12">
                                  Last Checked
                                </p>
                                <h5 className="font-size-14 mb-0">
                                  {integrationStatus.lastCheckedAt ? <ReactTimeAgo date={new Date(integrationStatus.lastCheckedAt)} locale="en-US" /> : "Never"}
                                </h5>
                              </div>
                            </td>


                            <td>
                              <div className="text-end">
                                <p className="text-muted mb-0 font-size-12">
                                  Last Online
                                </p>
                                <h5 className="font-size-14 mb-0">
                                  {integrationStatus.lastOnlineAt ? <ReactTimeAgo date={new Date(integrationStatus.lastOnlineAt)} locale="en-US" /> : "Never"}
                                </h5>
                              </div>
                            </td>

                            <td>
                                <button
                                  type="button"
                                  className="btn btn-soft-primary waves-effect waves-light"
                                  onClick={async () => await AttAdminApi.availability.status.check(integrationStatus.provider)}
                                >
                                  Check Status
                                </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </SimpleBar>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AvailabilityStatusPage;
