import Pusher from "pusher-js";
import React, { useCallback, useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import _ from "lodash";

import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import { AttAdminApi } from "../../helpers/att_api_helper";
import { getDeviceId } from "../../helpers/utils";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import PusherAvailabilitySearchUpdatedDto from "../../helpers/att-api-dtos/availability/availability-search-update.pusher.interface";
import CuisineDto from "../../helpers/att-api-dtos/cuisines/cuisine.dto.interface";
import AvailabilitySearchResultDto from "../../helpers/att-api-dtos/availability/availability-search-result.dto.interface";
import SearchAvailabilityRequestDto from "../../helpers/att-api-dtos/availability/search-availability-request.dto.interface";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { GeoPoint } from "../../helpers/att-api-dtos/availability/geo-rect.dto.interface";
import { BookingProviderTypeDto } from "../../helpers/att-api-dtos/availability/booking-provider-type.dto.interface";
import { Link } from "react-router-dom";

const libraries: any[] = ["places"]

const VenueNearbyPage = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDgohtZ966jJlToDhVXQlJ_egGA7gvEkXc",
    libraries,
  });

  const [isLoadingWebRequest, setIsLoadingWebRequest] = useState<boolean>(false);


  const [canCheckMore, setCanCheckMore] = useState<boolean>(false);

  const [location, setLocation] = useState<GeoPoint | undefined>(undefined);

  const [cuisines, setCuisines] = useState<{ cuisines: CuisineDto[] }>({
    cuisines: [],
  });
  const [selectedCuisines, setSelectedCuisines] = useState<CuisineDto[]>([]);

  const [covers, setCovers] = useState<number>(2);
  const [dateTime, setDateTime] = useState<Date>(
    AttAdminApi.utils.getDefaultBookingDate()
  );
  const [searchSessionId, setSearchSessionId] = useState<string | undefined>(
    undefined
  );

  const placeSearchRef = useRef<Input | null>(null);
  const [searchResult, setSearchResult] =
    useState<google.maps.places.Autocomplete | null>(null);

  const [availableVenues, setAvailableVenues] = useState<
    AvailabilitySearchResultDto[]
  >([]);

  // States on the current nearby search
  const [searchAreaAvailableCount, setSearchAreaAvailableCount] = useState<
    number | undefined
  >(undefined);
  const [searchAreaCheckedCount, setSearchAreaCheckedCount] = useState<
    number | undefined
  >(undefined);
  const [searchAreaUnavailableCount, setSearchAreaUnavailableCount] = useState<
    number | undefined
  >(undefined);
  const [searchAreaVenueCount, setSearchAreaVenueCount] = useState<
    number | undefined
  >(undefined);

  const [targetPageSize] = useState<number>(16);

  const updateSearchAreaState = (
    id: string,
    venueCount: number,
    checkedCount: number,
    unAvailableCount: number,
    availableCount: number
  ) => {
    setSearchAreaAvailableCount(availableCount);
    setSearchAreaCheckedCount(checkedCount);
    setSearchAreaUnavailableCount(unAvailableCount);
    setSearchAreaVenueCount(venueCount);
  };

  // Download list of cuisines for filter
  const downloadCuisines = async () => {
    setCuisines(await AttAdminApi.cuisines.list());
  };

  const mappedCuisines = () =>
    cuisines.cuisines.map((c) => ({
      label: c.name,
      value: c.id,
    }));

  const searchNearbyVenues = async (offset?: number) => {
    if (!location) {
      console.log("Not searching as theres no location");
      return;
    }
    const searchParams: SearchAvailabilityRequestDto = {
      location,
      offset,

      availabilityParams: {
        dateTime,
        covers,
        cuisineFilterIds: selectedCuisines.map((c) => c.id),
      },

      searchSessionId,
    };

    console.log("searchParams:", searchParams);

    setIsLoadingWebRequest(true)
    const result = await AttAdminApi.availability.search(searchParams);

    setSearchAreaVenueCount(result.results.totalVenueCount)

    setIsLoadingWebRequest(false)

    console.log("search API result:", result);

    const shouldClearResults =
      Boolean(searchSessionId) && searchSessionId !== result.searchSessionId;

    if (shouldClearResults) {
      setAvailableVenues([]);
    }

    setSearchSessionId(result.searchSessionId);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceAvailabilitySearch = useCallback(
    _.debounce(searchNearbyVenues, 1000),
    [dateTime, covers, selectedCuisines, location]
  );

  useEffect(() => {
    debounceAvailabilitySearch();
  }, [
    dateTime,
    covers,
    debounceAvailabilitySearch,
    selectedCuisines,
    location,
  ]);

  // Handle pusher events
  const handleNewPusherUpdate = async (
    event: PusherAvailabilitySearchUpdatedDto
  ) => {
    // Check if we can paginate:
    const isCompletedSearchEvent =
      event.type === "availability_search_complete";
    const hasMoreVenuesToCheck =
      event.data.search.venueCount > event.data.search.checkedCount;

    console.log(
      `isCompletedSearchEvent: ${isCompletedSearchEvent}, hasMoreVenuesToCheck: ${hasMoreVenuesToCheck} (${event.data.search.venueCount} > ${event.data.search.checkedCount} )`,
      event.type
    );
    console.log(
      `so, CanCheckMore: ${isCompletedSearchEvent && hasMoreVenuesToCheck}`
    );
    setCanCheckMore(isCompletedSearchEvent && hasMoreVenuesToCheck);

    // Process the data
    const data = event.data?.remoteDataKey
      ? await AttAdminApi.utils.fetchRemotePusherData(event.data?.remoteDataKey)
      : event.data?.result;

    if (data) {
      if (searchSessionId && searchSessionId !== event.data.search.sessionId) {
        // TODO: Cancel the search:
        // Use redis
        return;
      }

      setAvailableVenues((availableVenues) => [...availableVenues, data]);

      updateSearchAreaState(
        event.data.searchId,
        event.data.search.venueCount,
        event.data.search.checkedCount,
        event.data.search.unAvailableCount,
        event.data.search.availableCount
      );
    }
  };

  // Listen for Pusher updates:
  useEffect(() => {
    downloadCuisines();

    const pusher = new Pusher("eefa214c8f373a9a30ef", {
      cluster: "eu",
    });

    const channelName = `device-${getDeviceId()}`;

    const pusherDeviceChannel = pusher.subscribe(channelName);

    pusherDeviceChannel.bind(
      "availability_search_update",
      function (event: PusherAvailabilitySearchUpdatedDto) {
        console.log("availability_search_update:", event);
        handleNewPusherUpdate(event);
      }
    );

    pusherDeviceChannel.bind(
      "availability_search_complete",
      function (event: PusherAvailabilitySearchUpdatedDto) {
        console.log("availability_search_complete:", event);
        handleNewPusherUpdate(event);
      }
    );

    return () => {
      console.log("pusher is unsubscribing..!");
      pusher.unsubscribe(channelName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoadingResults = (): boolean => {

    if ((searchAreaVenueCount || 0) > (searchAreaCheckedCount || 0)) {
      // Assuming waiting for more pushers if we haven't filled the page & there's more results

      console.log(`searchAreaAvailableCount(${searchAreaAvailableCount}) targetPageSize(${targetPageSize})`, (searchAreaAvailableCount || 0) % targetPageSize)
      const fullPage = ((searchAreaAvailableCount || 0) % targetPageSize) === 0
      return !fullPage
    }

    if (canCheckMore) {
      // Don't show loading when the user has the option to load more
      return false
    }

    return isLoadingWebRequest
  }

  const getProviderTags = (availableVenue: AvailabilitySearchResultDto) => {
    let providers: BookingProviderTypeDto[] = [];

    availableVenue.slots.forEach((slot) => {
      if (!providers.includes(slot.provider)) {
        providers.push(slot.provider);
      }
    });

    return (
      <>
        {providers.map((provider) => (
          <Badge className="me-1 rounded-pill bg-info">{provider}</Badge>
        ))}
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Nearby Venues | ATT</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Nearby Venues" />
          <Row>
            <Card>
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Nearby Venues</h4>
              </div>
              <CardHeader>
                <Form>
                  <Row>
                    <Col md={4}>
                      <FormGroup className="mb-4">
                        <Label>Date</Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            value={dateTime}
                            onChange={([date]: [date: Date]) => {
                              setDateTime(date);
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup className="mb-4">
                        <Label>Time</Label>
                        <InputGroup>
                          <div className="input-group">
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select time"
                              value={dateTime}
                              onChange={([date]: [date: Date]) => {
                                setDateTime(date);
                              }}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                              }}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-clock-outline" />
                              </span>
                            </div>
                          </div>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <Label className="form-Label">Covers</Label>
                      <select
                        onChange={(e) =>
                          setCovers(parseInt(e.target.value, 10))
                        }
                        className="form-select"
                        defaultValue={2}
                      >
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <label
                          htmlFor="choices-multiple-default"
                          className="form-label font-size-13 text-muted"
                        >
                          Cuisines
                        </label>
                        <Select
                          onChange={(e: { label: string; value: string }[]) => {
                            console.log(
                              "cuisines",
                              e.map((c) => ({ id: c.value, name: c.label }))
                            );
                            setSelectedCuisines(
                              e.map((c) => ({ id: c.value, name: c.label }))
                            );
                          }}
                          isMulti
                          options={mappedCuisines()}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div>
                        <Label
                          htmlFor="example-search-input"
                          className="form-Label"
                        >
                          Location
                        </Label>
                        {isLoaded ? (
                          <Autocomplete
                            onPlaceChanged={() => {
                              if (!searchResult) {
                                return;
                              }
                              const placeLocation =
                                searchResult.getPlace()?.geometry?.location;
                              console.log("place:", placeLocation?.lat(), placeLocation?.lng());

                              if (placeLocation) {
                                setLocation({
                                  latitude: parseFloat(placeLocation?.lat().toFixed(8)),
                                  longitude: parseFloat(placeLocation?.lng().toFixed(8)),
                                });
                              }
                            }}
                            onLoad={(autocomplete) =>
                              setSearchResult(autocomplete)
                            }
                          >
                            <Input
                              className="form-control"
                              type="search"
                              placeholder="Search for a location"
                              id="example-search-input"
                              ref={placeSearchRef}
                            />
                          </Autocomplete>
                        ) : (
                          <>Loading...</>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="card-title-desc">
                      {searchSessionId && (
                        <Alert color="info">
                          {`Checked ${searchAreaCheckedCount} of ${searchAreaVenueCount}. ${searchAreaAvailableCount} are available & ${searchAreaUnavailableCount} fully booked.`}
                          {canCheckMore && (
                            <Link
                              to="#"
                              onClick={() => {
                                // Load more results in this search area:
                                const nextOffset = searchAreaCheckedCount;
                                searchNearbyVenues(nextOffset);
                              }}
                              className="alert-link"
                            >
                              {" "}
                              Check more venues in this area
                            </Link>
                          )}
                        </Alert>
                      )}
                    </div>
                  </Row>
                </Form>
              </CardHeader>
              <CardBody className="px-0">
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <tbody>
                      {availableVenues.map((availableVenue) => {
                        return (
                          <tr key={availableVenue.venue.id}>
                            <th scope="row">
                              <Row>{availableVenue.venue.name}</Row>
                              <Row>{getProviderTags(availableVenue)}</Row>
                            </th>
                            <td>
                              <div
                                style={{
                                  width: "2000px",
                                  height: "200px",
                                  display: "flex",
                                  overflowX: "auto",
                                }}
                              >
                                {availableVenue.venue.imageUrls.map(
                                  (imageUrl) => (
                                    <img
                                      className="img-thumbnail"
                                      alt="200x200"
                                      width="200"
                                      src={imageUrl}
                                      data-holder-rendered="true"
                                    />
                                  )
                                )}
                              </div>
                              <div
                                style={{
                                  width: "2000px",
                                  height: "60px",
                                  display: "flex",
                                  overflowX: "auto",
                                }}
                              >
                                {availableVenue.slots.map((slot) => {
                                  return (
                                    <button
                                      type="button"
                                      className="btn btn-info btn-rounded w-md waves-effect waves-light"
                                    >
                                      {slot.timeSlot}
                                      <br />
                                      <small>{slot.tag}</small>
                                    </button>
                                  );
                                })}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>

              {
                isLoadingResults() && <Row>
                  <>Loading...</>
                </Row>
              }

              <Row>
                    <div className="card-title-desc">
                      {searchSessionId && (
                        <Alert color="info">
                          {`Checked ${searchAreaCheckedCount} of ${searchAreaVenueCount}. ${searchAreaAvailableCount} are available & ${searchAreaUnavailableCount} fully booked.`}
                          {canCheckMore && (
                            <Link
                              to="#"
                              onClick={() => {
                                // Load more results in this search area:
                                const nextOffset = 0;
                                searchNearbyVenues(nextOffset);
                              }}
                              className="alert-link"
                            >
                              {" "}
                              Check more venues in this area
                            </Link>
                          )}
                        </Alert>
                      )}
                    </div>
                  </Row>

            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VenueNearbyPage;
