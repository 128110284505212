import { AxiosRequestConfig } from "axios"
import { RegisterUserRequest } from "./att-api-dtos/auth/register-user-request.dto.interface"
import { RegisterUserResponse } from "./att-api-dtos/auth/register-user-response.dto.interface"
import CountryAPIResponse from "./att-api-dtos/country/countries-response.dto.interface"
import CuisineFilterDto from "./att-api-dtos/cuisines/cuisine-filter.dto.interface"
import CuisineDto from "./att-api-dtos/cuisines/cuisine.dto.interface"
import ListStatusDto from "./att-api-dtos/lists/list-status.dto.interface"
import { invoiceList } from "../common/data"
import { axiosInstance, del, get, post, put } from "./api_helper"
import AdminUserAuthenticationDto from "./att-api-dtos/admin/auth/admin-user-authentication.dto.interface"
import CrawlerStatusType from "./att-api-dtos/admin/worker/crawler-status-type.dto.interface"
import CrawlerDto from "./att-api-dtos/admin/worker/crawler.dto.interface"
import PusherAvailabilitySearchUpdatedDto from "./att-api-dtos/availability/availability-search-update.pusher.interface"
import AvailabilityStatus from "./att-api-dtos/availability/availability-status.dto.interface"
import SearchAvailabilityRequestDto from "./att-api-dtos/availability/search-availability-request.dto.interface"
import VenueDto from "./att-api-dtos/venue/venue.dto.interface"
import { AdminVenueDto } from "./att-api-dtos/venue/admin-venue.dto.interface"
import * as url from "./url_helper"
import AvailabilitySearchResultDto from "./att-api-dtos/availability/availability-search-result.dto.interface"
import AvailabilitySearchHttpResponseDto from "./att-api-dtos/availability/availability-search-http-response.dto.interface"
import SearchSessionDto from "./att-api-dtos/search-session/search-session.dto.interface"
import ListVenuesQueryParametersDto from "./att-api-dtos/venue/list-venues-query-parameters.interface.dto"
import { AdminCuratedListDto, AdminCuratedListSummaryDto } from "./att-api-dtos/curated-lists/admin-curated-list.interface.dto"
import { WebsiteCrawlerResultDto } from "./att-api-dtos/admin/venue/admin-crawler-results.dto.interface"
import { RestaurantAccountDto } from "./att-api-dtos/restaurant-account/restaurant-account.dto"
import { RestaurantAccountUserDto } from "./att-api-dtos/restaurant-account/restaurant-account-user.dto"
import { RestaurantAccountVenueDto } from "./att-api-dtos/venue/restaurant-account-venue.dto"
import { PartnerDto } from "./att-api-dtos/partner/partner.dto"
import { PartnerUserDto } from "./att-api-dtos/partner/partner-user.dto"
import { PartnerVenueDto } from "./att-api-dtos/partner/partner-venue.dto"
import { CountryDto } from "./att-api-dtos/country/country.dto"
import { LinkedVenuePartnerLeadDto } from "./att-api-dtos/venue/linked-venue-partner-lead.dto"
import { VenueLeadDto } from "./att-api-dtos/venue/venue-lead.dto"
import Country from "./att-api-dtos/types/country.type"

const handleErrorAndThrow = (err: any) => {
  var message
  if (err?.response?.data) {
    if (err.response.data.message) {
      throw err.response.data
    }
    switch (err.response.status) {
      case 404:
        message = "Sorry! the page you are looking for could not be found"
        break
      case 500:
        message =
          "Sorry! something went wrong, please contact our support team"
        break
      case 401:
        message = "Invalid credentials"
        break
      default:
        message = err[1]
        break
    }
  }
  throw message
}

// Gets the logged in user data from local session
const getLoggedInUser = (): RegisterUserResponse | null => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

// Get the logged in support (admin) user
const getLoggedInSupportUser = (): RegisterUserResponse | null => {
  const user = localStorage.getItem("authSupportUser")
  if (user) return JSON.parse(user)
  return null
}

// Gets the users profile
const getUserProfile = (): AdminUserAuthenticationDto | null => {
  const user = localStorage.getItem("profileUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

const isSupportUserAuthenticated = () => {
  return getLoggedInSupportUser() !== null
}

const GetRequest = <ReturnType>(url: string, config?: AxiosRequestConfig): Promise<ReturnType> => {
  return axiosInstance()
    .get<ReturnType>(url, config)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

const PostRequest = <ReturnType>(url: string, data?: any, config?: AxiosRequestConfig): Promise<ReturnType> => {
  return axiosInstance()
    .post<ReturnType>(url, data, config)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => handleErrorAndThrow(err))
}

const PutRequest = <ReturnType>(url: string, data?: any, config?: AxiosRequestConfig): Promise<ReturnType> => {
  return axiosInstance()
    .put<ReturnType>(url, data, config)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

const DeleteRequest = <ReturnType>(url: string, config?: AxiosRequestConfig): Promise<ReturnType> => {
  return axiosInstance()
    .delete<ReturnType>(url, config)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// fetch countries
const fetchCountries = (): Promise<CountryAPIResponse[]> => {
  return GetRequest<CountryAPIResponse[]>(url.GET_COUNTRIES)
}

// Login Method
const postFakeLogin = (data: any) => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = (data: any) => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = (data: any) => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = (data: any) => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postUser = (data: RegisterUserRequest): Promise<RegisterUserResponse> => {
  return PostRequest<RegisterUserResponse>(url.POST_USER, data)
}

// Request login otp
const requestOTP = (email: string): Promise<{ userId: string }> => {
  return PostRequest<{ userId: string }>(url.POST_USER_REQUEST_MAGIC_LINK, { email })
}

// Validate OTP sent via email

const validateOTP = (userId: string, otp: string): Promise<RegisterUserResponse> => {
  return PostRequest<RegisterUserResponse>(url.POST_USER_VALIDATE_MAGIC_LINK, {
    userId,
    otp,
  })
}

// postForgetPwd
const postJwtForgetPwd = (data: any) => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

const postSocialLogin = async (data: any): Promise<RegisterUserResponse> => {
  return PostRequest<RegisterUserResponse>(`/admin/auth/login/google`, {
    ...data,
  })
}

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = (id: number) =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = (event: any) => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = (event: any) => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = (event: any) =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = (message: any) => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = (order: any) => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = (order: any) => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = (order: any) =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = (customer: any) => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = (customer: any) => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = (customer: any) =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => invoiceList

// get invoice details
export const getInvoiceDetail = (id: number) =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = (id: number) =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get contacts
export const getUsers = () => get(url.GET_USERS)

// add user
export const addNewUser = (user: any) => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = (user: any) => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = (user: any) => del(url.DELETE_USER, { headers: { user } })

/** PROJECT */
// add user
export const addNewProject = (project: any) => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = (project: any) => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = (project: any) =>
  del(url.DELETE_PROJECT, { headers: { project } })


const AttAdminApi = {
  auth: {
    profile: (): Promise<AdminUserAuthenticationDto> => {
      return GetRequest<AdminUserAuthenticationDto>('/admin/auth/me ')
    },
  },
  countries: {
    list: (): Promise<CountryDto[]> => {
      return GetRequest<CountryDto[]>('/country', {
        params: {
          locate: true,
        }
      })
    },
  },
  leads: {
    createLead(venueId: string): Promise<VenueLeadDto> {
      return PostRequest<VenueLeadDto>('/admin/venue-leads', { venueId })
    },
    getLead(leadId: string): Promise<VenueLeadDto> {
      return GetRequest<VenueLeadDto>(`/admin/venue-leads/${leadId}`)
    },
    list(params: {
      offset?: number,
    }): Promise<{ leads: VenueLeadDto[] }> {
      return GetRequest<{ leads: VenueLeadDto[] }>('/admin/venue-leads', {
        params,
      })
    },
    partnerLinked: {
      list(params: {
        offset?: number,
      }): Promise<{ linkedVenueLeads: LinkedVenuePartnerLeadDto[] }> {
        return GetRequest<{ linkedVenueLeads: LinkedVenuePartnerLeadDto[] }>('/admin/venue-leads/partner-linked', {
          params,
        })
      }
    },
  },
  partners: {
    list: (params: {
      offset?: number,
    }): Promise<{ partners: PartnerDto[] }> => {
      return GetRequest<{ partners: PartnerDto[] }>('/admin/partners', { params })
    },
    get: (id: string): Promise<PartnerDto> => {
      return GetRequest<PartnerDto>(`/admin/partners/${id}`)
    },
    create: (partner: PartnerDto): Promise<PartnerDto> => {
      return PostRequest<PartnerDto>('/admin/partners', partner)
    },
    put: (partner: PartnerDto): Promise<PartnerDto> => {
      return PutRequest<PartnerDto>(`/admin/partners/${partner.id}`, partner)
    },
    venues: {
      list: (partnerId: string, params: ListVenuesQueryParametersDto): Promise<{
        venues: PartnerVenueDto[];
      }> => {
        return GetRequest<{
          venues: PartnerVenueDto[];
        }>(`/admin/partners/${partnerId}/venue`, { params })
      },
      add: (partnerId: string, venueId: string): Promise<void> => {
        return PostRequest<void>(`/admin/partners/${partnerId}/venue/${venueId}`)
      },
      remove: (partnerId: string, venueId: string): Promise<void> => {
        return DeleteRequest<void>(`/admin/partners/${partnerId}/venue/${venueId}`)
      },
    },
    users: {
      list: (partnerId: string): Promise<{ users: PartnerUserDto[] }> => {
        return GetRequest<{ users: PartnerUserDto[] }>(`/admin/partners/${partnerId}/user`)
      },
      add: (partnerId: string, user: {
        firstName: string,
        lastName: string,
        email: string,
      }): Promise<PartnerUserDto> => {
        return PostRequest<PartnerUserDto>(`/admin/partners/${partnerId}/user`, user)
      },
      put: (partnerId: string, userId: string, user: {
        firstName: string,
        lastName: string,
        email: string,
        deletedAt?: Date,
      }): Promise<PartnerUserDto> => {
        return PutRequest<PartnerUserDto>(`/admin/partners/${partnerId}/user/${userId}`, user)
      }
    }
  },
  restaurantAccounts: {
    create: (body: {
      firstName: string
      lastName: string
      email: string
      country: Country
      businessName: string
      website: string
      logoUrl?: string
    }): Promise<RestaurantAccountDto> => {
      return PostRequest<RestaurantAccountDto>('/admin/restaurant-account', body)
    },
    get: (restaurantAccountId: string): Promise<RestaurantAccountDto> => {
      return GetRequest<RestaurantAccountDto>(
        `/admin/restaurant-account/${restaurantAccountId}`,
      )
    },
    list: (params: {
      offset?: number,
      query?: string,
    }): Promise<{ restaurantAccounts: RestaurantAccountDto[] }> => {
      return GetRequest<{ restaurantAccounts: RestaurantAccountDto[] }>(
        `/admin/restaurant-account`, {
        params,
      })
    },
    updateDetails: (restaurantAccountId: string, body: {
      businessName: string,
    }): Promise<void> => {
      return PostRequest<void>(`/admin/restaurant-account/${restaurantAccountId}`, body)
    },
    venues: {
      linked: {
        list: (restaurantAccountId: string): Promise<{ venues: RestaurantAccountVenueDto[] }> => {
          return GetRequest<{ venues: RestaurantAccountVenueDto[] }>(`admin/restaurant-account/${restaurantAccountId}/venue/linked`)
        },
        add: (restaurantAccountId: string, venueId: string): Promise<void> => {
          return PostRequest(`admin/restaurant-account/${restaurantAccountId}/venue/linked/${venueId}`)
        },
        remove: (restaurantAccountId: string, venueId: string): Promise<void> => {
          return DeleteRequest(`admin/restaurant-account/${restaurantAccountId}/venue/linked/${venueId}`)
        },
      },
      list: (restaurantAccountId: string, params: ListVenuesQueryParametersDto): Promise<{
        venues: VenueDto[];
        offsetVenueId?: string;
        limit: number;
      }> => {
        return GetRequest<{
          venues: VenueDto[];
          offsetVenueId?: string;
          limit: number;
        }>(`admin/restaurant-account/${restaurantAccountId}/venue/`, { params })
      },
    },
    users: {
      addUser: (restaurantAccountId: string, user: {
        firstName: string,
        lastName: string,
        email: string,
        sendWelcomeEmail: boolean,
      }): Promise<RestaurantAccountUserDto> => {
        return PostRequest<RestaurantAccountUserDto>(`/admin/restaurant-account/${restaurantAccountId}/user`, user)
      },
      editUser: (restaurantAccountId: string, userId: string, user: {
        firstName: string,
        lastName: string,
        email: string,
        deletedAt?: Date,
      }): Promise<RestaurantAccountUserDto> => {
        return PutRequest<RestaurantAccountUserDto>(`/admin/restaurant-account/${restaurantAccountId}/user/${userId}`, user)
      },
      removeUser: (restaurantAccountId: string, userId: string): Promise<void> => {
        return DeleteRequest<void>(`/admin/restaurant-account/${restaurantAccountId}/user/${userId}`)
      },
    },
  },
  searchSessions: {
    list: (offsetId?: string): Promise<{ searchSessions: SearchSessionDto[] }> => {
      return GetRequest<{ searchSessions: SearchSessionDto[] }>(`./admin/search-sessions`, {
        params: {
          offsetId,
        }
      })
    },
  },
  cuisines: {
    list: (): Promise<{ cuisines: CuisineDto[] }> => {
      return GetRequest<{ cuisines: CuisineDto[] }>(`/cuisines/`)
    },
    listUnMapped: (): Promise<{ cuisines: CuisineDto[] }> => {
      return GetRequest<{ cuisines: CuisineDto[] }>('/admin/cuisine-filters/unmapped-cuisines')
    },
    cuisineFilters: {
      list: (): Promise<{ cuisineFilters: CuisineFilterDto[] }> => {
        return GetRequest<{ cuisineFilters: CuisineFilterDto[] }>('/admin/cuisine-filters')
      },
      mapCuisine: (cuisineFilterId: string, cuisineId: string): Promise<{}> => {
        return PostRequest<{}>(`/admin/cuisine-filters/${cuisineFilterId}`, {
          id: cuisineId,
        })
      },
      unMapCuisine: (cuisineFilterId: string, cuisineId: string): Promise<{}> => {
        return DeleteRequest<{}>(`/admin/cuisine-filters/${cuisineFilterId}/${cuisineId}`)
      },
    },
  },
  crawlers: {
    status: {
      list: (): Promise<{ crawlers: CrawlerDto[] }> => {
        return GetRequest<{ crawlers: CrawlerDto[] }>('/admin/crawlers/status')
      }
    },
    start: (crawler: CrawlerStatusType): Promise<void> => {
      return PostRequest<void>(`/admin/crawlers/trigger/${crawler}`)
    }
  },
  curatedLists: {
    list: (params: {
      query?: string,
      offset?: number,
      published?: boolean,
      pageSize?: number,
    }): Promise<{
      lists: AdminCuratedListDto[],
      offset?: number,
    }> => {
      return GetRequest<{ lists: AdminCuratedListDto[], offset?: number }>('/admin/curated-lists', {
        params,
      })
    },
    get: {
      summary: (id: string): Promise<AdminCuratedListSummaryDto> => {
        return GetRequest<AdminCuratedListSummaryDto>(`/admin/curated-lists/${id}/summary`)
      }
    },
    create: (params: { name: string, description: string }): Promise<AdminCuratedListDto> => {
      return PostRequest<AdminCuratedListDto>('/admin/curated-lists/', params)
    },
    update: (id: string, params: { name: string, description: string }): Promise<AdminCuratedListDto> => {
      return PutRequest<AdminCuratedListDto>(`/admin/curated-lists/${id}`, params)
    },
    publishing: {
      unpublish: (id: string): Promise<void> => {
        return PostRequest<void>(`/admin/curated-lists/${id}/unpublish`)
      },
      publish: (id: string): Promise<void> => {
        return PostRequest<void>(`/admin/curated-lists/${id}/publish`)
      }
    },
    venues: {
      list: (id: string, params: {
        offset?: number,
        pageSize?: number,
        query?: string,

      }): Promise<{ venues: VenueDto[], offset?: number, pageSize: number }> => {
        return GetRequest<{ venues: VenueDto[], offset?: number, pageSize: number }>(`/admin/curated-lists/${id}/venue`, { params })
      },
      add: (id: string, venueId: string): Promise<void> => {
        return PostRequest<void>(`/admin/curated-lists/${id}/venue/${venueId}`)
      },
      remove: (id: string, venueId: string): Promise<void> => {
        return DeleteRequest<void>(`/admin/curated-lists/${id}/venue/${venueId}`)
      },
    },
  },
  lists: {
    status: {
      list: (): Promise<{ listStatus: ListStatusDto[] }> => {
        return GetRequest<{ listStatus: ListStatusDto[] }>('/admin/lists/status')
      },
      scan: (id: string): Promise<void> => {
        return PostRequest<void>(`/admin/lists/scan/${id}`)
      }
    },
  },
  availability: {
    status: {
      list: (): Promise<{ integrationStatus: AvailabilityStatus[] }> => {
        return GetRequest<{ integrationStatus: AvailabilityStatus[] }>('/admin/availability/status')
      },
      check: (id: string): Promise<void> => {
        return PostRequest<void>(`/admin/availability/status/check/${id}`)
      }
    },
    search: (body: SearchAvailabilityRequestDto): Promise<AvailabilitySearchHttpResponseDto> => {
      return PostRequest<AvailabilitySearchHttpResponseDto>(`/availability`, body)
    },
    fetchData: (id: string): Promise<{ data?: PusherAvailabilitySearchUpdatedDto }> => {
      return GetRequest<{ data?: PusherAvailabilitySearchUpdatedDto }>(`/availability/data/${id}`)
    }
  },
  venues: {
    admin: {
      get: (id: string): Promise<VenueDto> => {
        return GetRequest<VenueDto>(`/admin/venues/${id}`)
      },
      build: {
        create(venue: AdminVenueDto): Promise<{ venue: AdminVenueDto }> {
          return PostRequest<{ venue: AdminVenueDto }>('/admin/venue/build/create-venue', { venue })
        },
        update(venue: AdminVenueDto): Promise<{ venue: AdminVenueDto }> {
          return PostRequest<{ venue: AdminVenueDto }>('/admin/venue/build/update-venue', { venue })
        },
        discoverDetails: (params: {
          websiteUrl: string,
          venueAddress?: string,
        }): Promise<{ results: WebsiteCrawlerResultDto[] }> => {
          return PostRequest<{
            results: WebsiteCrawlerResultDto[]
          }>(`/admin/venue/build/detect-provider`, params)
        }
      }
    },
    get: (id: string): Promise<{ venue: AdminVenueDto }> => {
      return GetRequest<{ venue: AdminVenueDto }>(`/venues/${id}`)
    },
    list: (params: ListVenuesQueryParametersDto): Promise<{
      venues: VenueDto[];
      offsetVenueId?: string;
      limit: number;
    }> => {
      return GetRequest<{
        venues: VenueDto[];
        offsetVenueId?: string;
        limit: number;
      }>(`/venues`, { params })
    },
  },
  utils: {
    fetchRemotePusherData: async (
      remoteDataKey: string
    ): Promise<AvailabilitySearchResultDto | undefined> => {
      const { data } = await AttAdminApi.availability.fetchData(remoteDataKey);
      return data?.data?.result;
    },
    getDefaultBookingDate: (): Date => {
      var tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(19, 30, 0, 0);
      return tomorrow;
    },
  }
}

export {

  AttAdminApi,

  // support
  postSocialLogin,
  isSupportUserAuthenticated,
  getLoggedInSupportUser,

  //Auth session
  getLoggedInUser,
  isUserAuthenticated,

  postUser,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  validateOTP,
  postJwtForgetPwd,
  postJwtProfile,
  requestOTP,
  fetchCountries,
  getUserProfile,
}
