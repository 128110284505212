import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Autocomplete,
} from "@react-google-maps/api";
import Select from "react-select";
import Pusher from "pusher-js";
import _ from "lodash";

import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { AttAdminApi } from "../../helpers/att_api_helper";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import SearchAvailabilityRequestDto from "../../helpers/att-api-dtos/availability/search-availability-request.dto.interface";
import { getDeviceId, isValidDate } from "../../helpers/utils";
import PusherAvailabilitySearchUpdatedDto from "../../helpers/att-api-dtos/availability/availability-search-update.pusher.interface";
import AvailabilitySearchResultDto from "../../helpers/att-api-dtos/availability/availability-search-result.dto.interface";

// Map Marker Images
import mapMarkerDesignMyNight from "../../assets/images/map-pins/booking-providers/map-pin-design-my-night.svg";
import mapMarkerOpenTable from "../../assets/images/map-pins/booking-providers/map-pin-opentable.svg";
import mapMarkerQuandoo from "../../assets/images/map-pins/booking-providers/map-pin-quandoo.svg";
import mapMarkerResDiary from "../../assets/images/map-pins/booking-providers/map-pin-resdiary.svg";
import mapMarkerResy from "../../assets/images/map-pins/booking-providers/map-pin-resy.svg";
import mapMarkerSevenRooms from "../../assets/images/map-pins/booking-providers/map-pin-seven-rooms.svg";
import mapMarkerTheFork from "../../assets/images/map-pins/booking-providers/map-pin-the-fork.svg";
import { Link } from "react-router-dom";
import VenueInfoModel from "../availability-status/components/venue-info.modal";
import CuisineDto from "../../helpers/att-api-dtos/cuisines/cuisine.dto.interface";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 51.505496,
  lng: -0.0888645,
};

const libraries: any[] = ["places"]

const VenueMapSearchPage = ({
  google,
  zoom,
}: {
  google: Object;
  zoom: number;
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDgohtZ966jJlToDhVXQlJ_egGA7gvEkXc",
    libraries,
  });

  const [cuisines, setCuisines] = useState<{ cuisines: CuisineDto[] }>({
    cuisines: [],
  });
  const [selectedCuisines, setSelectedCuisines] = useState<CuisineDto[]>([]);

  const [map, setMap] = useState<google.maps.Map | null>();
  const [covers, setCovers] = useState<number>(2);
  const [dateTime, setDateTime] = useState<Date>(AttAdminApi.utils.getDefaultBookingDate());
  const [searchSessionId, setSearchSessionId] = useState<string | undefined>(
    undefined
  );

  const placeSearchRef = useRef<Input | null>(null);
  const [searchResult, setSearchResult] =
    useState<google.maps.places.Autocomplete | null>(null);

  const [availableVenues, setAvailableVenues] = useState<
    AvailabilitySearchResultDto[]
  >([]);

  const [selectedSearchResult, setSelectedSearchResult] = useState<
    AvailabilitySearchResultDto | undefined
  >(undefined);

  // Stats on the current search area (reset when the search area changes)
  const [searchAreaId, setSearchAreaId] = useState<string | undefined>(
    undefined
  );
  const [searchAreaAlreadyAvailableCount, setSearchAreaAlreadyAvailableCount] =
    useState<number | undefined>(undefined);
  const [searchAreaAvailableCount, setSearchAreaAvailableCount] = useState<
    number | undefined
  >(undefined);
  const [searchAreaCheckedCount, setSearchAreaCheckedCount] = useState<
    number | undefined
  >(undefined);
  const [searchAreaUnavailableCount, setSearchAreaUnavailableCount] = useState<
    number | undefined
  >(undefined);
  const [searchAreaVenueCount, setSearchAreaVenueCount] = useState<
    number | undefined
  >(undefined);
  const [canCheckMore, setCanCheckMore] = useState<boolean>(false);

  // Reset search session when params change:
  useEffect(() => {
    setSearchSessionId(undefined);
    setSearchAreaId(undefined);
  }, [covers, dateTime]);

  const updateSearchAreaState = (
    id: string,
    venueCount: number,
    checkedCount: number,
    unAvailableCount: number,
    availableCount: number,
    alreadyAvailableCount: number
  ) => {
    setSearchAreaAvailableCount(availableCount);
    setSearchAreaCheckedCount(checkedCount);
    setSearchAreaUnavailableCount(unAvailableCount);
    setSearchAreaVenueCount(venueCount);
    setSearchAreaAlreadyAvailableCount(alreadyAvailableCount);
  };

  const handleNewPusherUpdate = async (
    event: PusherAvailabilitySearchUpdatedDto
  ) => {
    // Check if we can paginate:
    const isCompletedSearchEvent =
      event.type === "availability_search_complete";
    const hasMoreVenuesToCheck =
      event.data.search.venueCount > event.data.search.checkedCount;

    console.log(
      `isCompletedSearchEvent: ${isCompletedSearchEvent}, hasMoreVenuesToCheck: ${hasMoreVenuesToCheck} (${event.data.search.venueCount} > ${event.data.search.checkedCount} )`,
      event.type
    );
    console.log(
      `so, CanCheckMore: ${isCompletedSearchEvent && hasMoreVenuesToCheck}`
    );
    setCanCheckMore(isCompletedSearchEvent && hasMoreVenuesToCheck);

    // Process the data
    const data = event.data?.remoteDataKey
      ? await AttAdminApi.utils.fetchRemotePusherData(event.data?.remoteDataKey)
      : event.data?.result;

    if (data) {
      if (searchSessionId && searchSessionId !== event.data.search.sessionId) {
        // TODO: Cancel the search:
        // Use redis
        return;
      }

      setAvailableVenues((availableVenues) => [...availableVenues, data]);
      updateSearchAreaState(
        event.data.searchId,
        event.data.search.venueCount,
        event.data.search.checkedCount,
        event.data.search.unAvailableCount,
        event.data.search.availableCount,
        event.data.search.alreadyAvailableCount
      );
    }
  };

  // Download list of cuisines for filter
  const downloadCuisines = async () => {
    setCuisines(await AttAdminApi.cuisines.list());
  };

  const mappedCuisines = () =>
    cuisines.cuisines.map((c) => ({
      label: c.name,
      value: c.id,
    }));

  // Listen for Pusher updates:
  useEffect(() => {
    downloadCuisines();

    const pusher = new Pusher("eefa214c8f373a9a30ef", {
      cluster: "eu",
    });

    const channelName = `device-${getDeviceId()}`;

    const pusherDeviceChannel = pusher.subscribe(channelName);

    pusherDeviceChannel.bind(
      "availability_search_update",
      function (event: PusherAvailabilitySearchUpdatedDto) {
        console.log("availability_search_update:", event);
        handleNewPusherUpdate(event);
      }
    );

    pusherDeviceChannel.bind(
      "availability_search_complete",
      function (event: PusherAvailabilitySearchUpdatedDto) {
        console.log("availability_search_complete:", event);
        handleNewPusherUpdate(event);
      }
    );

    return () => {
      console.log("pusher is unsubscribing..!");
      pusher.unsubscribe(channelName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchMapArea = async (paginatedSearchAreaId?: string) => {
    if (!map) {
      console.warn("[debounceSearchMapArea] Map still loading");
      return;
    }
    console.trace(`[debounceSearchMapArea] Searching...`, searchSessionId);

    const northEast = map.getBounds()?.getNorthEast();
    const southWest = map.getBounds()?.getSouthWest();

    const searchParams: SearchAvailabilityRequestDto = {
      searchAreaRect: {
        northWest: {
          latitude: northEast?.lat()!,
          longitude: southWest?.lng()!,
        },
        northEast: {
          latitude: northEast?.lat()!,
          longitude: northEast?.lng()!,
        },
        southWest: {
          latitude: southWest?.lat()!,
          longitude: southWest?.lng()!,
        },
        southEast: {
          latitude: southWest?.lat()!,
          longitude: northEast?.lng()!,
        },
      },
      availabilityParams: {
        dateTime,
        covers,
        cuisineFilterIds: selectedCuisines.map((c) => c.id),
      },

      searchSessionId,
      searchAreaId: paginatedSearchAreaId,
    };

    console.log(dateTime);
    console.log("isValidDate:", isValidDate(dateTime));
    // Trigger the Pusher data:
    console.log("searchParams:", searchParams);

    const result = await AttAdminApi.availability.search(searchParams);
    console.log("search API result:", result);

    const shouldClearResults =
      Boolean(searchSessionId) && searchSessionId !== result.searchSessionId;
    if (shouldClearResults) {
      setAvailableVenues([]);
    }

    setSearchSessionId(result.searchSessionId);
    setSearchAreaId(result.searchAreaId);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchMapArea = useCallback(_.debounce(searchMapArea, 1000), [
    map,
    dateTime,
    covers,
    selectedCuisines,
  ]);

  useEffect(() => {
    debounceSearchMapArea();
  }, [map, dateTime, covers, debounceSearchMapArea, selectedCuisines]);

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onDragEnd = React.useCallback(
    function callback() {
      //const bounds = map?.getBounds();
      debounceSearchMapArea();
    },
    [debounceSearchMapArea]
  );

  const onZoomChanged = React.useCallback(
    function callback() {
      //const bounds = map?.getBounds();
      debounceSearchMapArea();
    },
    [debounceSearchMapArea]
  );

  const onUnmount = React.useCallback(function callback(map) {
    console.log("map unmounted");
    setMap(null);
  }, []);

  const getMarkerImage = (result: AvailabilitySearchResultDto) => {
    const firstResultSlot = result.slots[0]
    switch (firstResultSlot.provider) {
      case "designMyNight":
        return mapMarkerDesignMyNight;
      case "openTable":
        return mapMarkerOpenTable;
      case "quandoo":
        return mapMarkerQuandoo;
      case "resDiary":
        return mapMarkerResDiary;
      case "resy":
        return mapMarkerResy;
      case "sevenRooms":
        return mapMarkerSevenRooms;
      case "theFork":
        return mapMarkerTheFork;
      default:
        throw new Error(
          `Cant generate marker for unknown provider [${firstResultSlot?.provider}]` + result
        );
    }
  };

  const [venueIdHovered, setVenueIdHovered] = useState<string | undefined>(
    undefined
  );

  const mouseMarker = (e: AvailabilitySearchResultDto) => {
    console.log(`mouseMarker`, e);
    setVenueIdHovered(e.venue.id);
  };

  const googleMap = isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={16}
      options={{
        mapId: "e5d70ce2d53e8838",
        mapTypeControl: false,
        streetViewControl: false,
      }}
      // When the user moves the map:
      onDragEnd={onDragEnd}
      onZoomChanged={onZoomChanged}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {availableVenues.map((availableVenue) => {
        return (
          <Marker
            key={`marker-${availableVenue.venue.id}`}
            title={availableVenue.venue.name}
            label={
              availableVenue.venue.id === venueIdHovered
                ? availableVenue.venue.name
                : undefined
            }
            icon={getMarkerImage(availableVenue)}
            position={{
              lat: availableVenue.venue.location.latitude,
              lng: availableVenue.venue.location.longitude,
            }}
            onMouseOver={() => mouseMarker(availableVenue)}
            onMouseOut={() => setVenueIdHovered(undefined)}
            onClick={() => setSelectedSearchResult(availableVenue)}
          />
        );
      })}
    </GoogleMap>
  ) : (
    <>Loading...</>
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Map Search | ATT</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Map Search" />
          <Row>
            <Card>
              <CardHeader>
                <Form>
                  <Row>
                    <Col md={4}>
                      <FormGroup className="mb-4">
                        <Label>Date</Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            value={dateTime}
                            onChange={([date]: [date: Date]) => {
                              setDateTime(date);
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup className="mb-4">
                        <Label>Time</Label>
                        <InputGroup>
                          <div className="input-group">
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select time"
                              value={dateTime}
                              onChange={([date]: [date: Date]) => {
                                setDateTime(date);
                              }}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                              }}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-clock-outline" />
                              </span>
                            </div>
                          </div>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <Label className="form-Label">Covers</Label>
                      <select
                        onChange={(e) =>
                          setCovers(parseInt(e.target.value, 10))
                        }
                        className="form-select"
                        defaultValue={2}
                      >
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <label
                          htmlFor="choices-multiple-default"
                          className="form-label font-size-13 text-muted"
                        >
                          Cuisines
                        </label>
                        <Select
                          onChange={(e: { label: string; value: string }[]) => {
                            console.log(
                              "cuisines",
                              e.map((c) => ({ id: c.value, name: c.label }))
                            );
                            setSelectedCuisines(
                              e.map((c) => ({ id: c.value, name: c.label }))
                            );
                          }}
                          isMulti
                          options={mappedCuisines()}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div>
                        <Label
                          htmlFor="example-search-input"
                          className="form-Label"
                        >
                          Location
                        </Label>
                        {isLoaded ? (
                          <Autocomplete
                            onPlaceChanged={() => {
                              if (!searchResult) {
                                return;
                              }
                              const placeLocation =
                                searchResult.getPlace()?.geometry?.location;
                              console.log("place:", placeLocation);

                              if (placeLocation) {
                                map?.panTo({
                                  lat: placeLocation?.lat(),
                                  lng: placeLocation?.lng(),
                                });
                              }
                            }}
                            onLoad={(autocomplete) =>
                              setSearchResult(autocomplete)
                            }
                          >
                            <Input
                              className="form-control"
                              type="search"
                              placeholder="Search for a location"
                              id="example-search-input"
                              ref={placeSearchRef}
                            />
                          </Autocomplete>
                        ) : (
                          <>Loading...</>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardHeader>
              <CardBody style={{ height: "600px" }}>
                <div className="card-title-desc">
                  {searchAreaId && (
                    <Alert color="info">
                      {`Checked ${searchAreaCheckedCount} of ${searchAreaVenueCount} bookable venues in this area. ${searchAreaUnavailableCount} are fully booked, and ${
                        (searchAreaAvailableCount || 0) +
                        (searchAreaAlreadyAvailableCount || 0)
                      } venues are available.`}
                      {canCheckMore && (
                        <Link
                          to="#"
                          onClick={() => {
                            // Load more results in this search area:
                            searchMapArea(searchAreaId);
                          }}
                          className="alert-link"
                        >
                          {" "}
                          Check more venues in this area
                        </Link>
                      )}
                    </Alert>
                  )}
                </div>
                {googleMap}
              </CardBody>
            </Card>
          </Row>
          <VenueInfoModel
            searchResult={selectedSearchResult}
            toggle={() => setSelectedSearchResult(undefined)}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VenueMapSearchPage;
