import React, { useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Alert, Badge, Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Modal, Row, Table } from "reactstrap";
import { AttAdminApi } from "../../helpers/att_api_helper";
import { useInfiniteQuery, useQuery } from "react-query";
import { AdminCuratedListDto, AdminCuratedListSummaryDto } from "src/helpers/att-api-dtos/curated-lists/admin-curated-list.interface.dto";
import { Link } from "react-router-dom";
import VenueDto from "src/helpers/att-api-dtos/venue/venue.dto.interface";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { GeoPoint } from "../../helpers/att-api-dtos/availability/geo-rect.dto.interface";
import { formatVenueAddress } from "src/helpers/utils";
import SimpleBar from "simplebar-react";

interface ManageCuratedListPageProps {
    google: Object
    history: any
    match: {
        params: {
            curatedListId: string
        }
    }
}

const libraries: any[] = ["places"]

const ManageCuratedListPage = (props: ManageCuratedListPageProps) => {

    const pageSize = 100

    // Google place search:
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyDgohtZ966jJlToDhVXQlJ_egGA7gvEkXc",
        libraries,
    });
    const placeSearchRef = useRef<Input | null>(null);
    const [searchResult, setSearchResult] =
        useState<google.maps.places.Autocomplete | null>(null);
    const [location, setLocation] = useState<GeoPoint | undefined>(undefined);

    // Adding a venue:
    const [venueSearchQuery, setVenueSearchQuery] = useState<string>('');
    const [isLoadingAddVenue, setIsLoadingAddVenue] = useState(false);

    // Managing venues in the list:
    const [listVenuesSearchQuery, ] = useState<string>('');

    const [isRemoving, setIsRemoving] = useState<string[]>([])

    // Editing list details
    const [editCuratedList, setEditCuratedList] = useState<
        AdminCuratedListDto | undefined
    >();
    const [editCuratedListError, setEditCuratedListError] = useState<string | undefined>();
    const [isLoadingEditCuratedList, setIsLoadingEditCuratedList] = useState(false);
    const [isLoadingPublishing, setIsLoadingPublishing] = useState(false);

    const [isAddingVenue, setAddingVenue] = useState(false);
    const [addingVenueError, setAddingVenueError] = useState<string | undefined>();

    const {
        data: venueListData,
        error: venueListError,
        isLoading: isLoadingVenueList,
       // refetch: refetchVenueList
    } = useQuery<
        {
            venues: VenueDto[];
            offsetVenueId?: string;
            limit: number;
        },
        Error
    >({
        queryKey: [`list-venues`, venueSearchQuery, location],
        queryFn: () => AttAdminApi.venues.list({
            location,
            query: venueSearchQuery,
        }),
    });

    const {
        data: curatedListSummaryData,
        error,
        isLoading,
        refetch: refetchCuratedListSummary
    } = useQuery<
        AdminCuratedListSummaryDto,
        Error
    >({
        queryKey: [`curated-list-${props.match.params.curatedListId}`, props.match.params.curatedListId],
        queryFn: () => AttAdminApi.curatedLists.get.summary(props.match.params.curatedListId),
    });

    const {
        data: curatedListVenuesData,
        error: curatedListVenuesError,
        isLoading: isLoadingCuratedListVenues,
        refetch: refetchCuratedListVenues,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery<
        {
            venues: VenueDto[]
            offset?: number,
            pageSize: number,
        },
        Error
    >({
        queryKey: [
            `curated-list-venues`,
            listVenuesSearchQuery,
        ],
        queryFn: ({ pageParam }) => AttAdminApi.curatedLists.venues.list(props.match.params.curatedListId, {
            offset: pageParam as any,
            query: listVenuesSearchQuery,
            pageSize,
        }),
        getNextPageParam: (lastPage, pages) => (lastPage.offset || 0) + lastPage.pageSize,
    });

    if (isLoading) {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{curatedListSummaryData?.name || ''} | Curated List | ATT</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Home" breadcrumbItem="Curated List" />
                        Loading...
                    </Container>
                </div>
            </React.Fragment>
        );
    }

    if (error) {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{curatedListSummaryData?.name || ''} | Curated List | ATT</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Home" breadcrumbItem="Curated List" />

                        <Row>
                            <Card>
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {error.name || 'Error'}
                                    </h4>
                                </div>
                                <CardBody className="px-0">
                                    {error.message || 'An error occurred.'}
                                </CardBody>
                            </Card>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }

    const renderPublishedStatusBadge = (publishedAt?: Date) =>
        Boolean(publishedAt) ? (
            <Badge className="me-2 bg-success">Published</Badge>
        ) : (
            <Badge className="me-2 bg-danger">Unpublished</Badge>
        );

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{curatedListSummaryData?.name || ''} | Curated List | ATT</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Home" breadcrumbItem="Curated List" />
                    <Row>
                        <Card>
                            <CardHeader>
                                <div className="row align-ite  ms-center">
                                    <div className="col-md-8 align-items-left">
                                        <h4 className="card-title mb-0 flex-grow-1">
                                            {curatedListSummaryData?.name || 'Curated List'}
                                            <br />
                                            {
                                                renderPublishedStatusBadge(curatedListSummaryData?.publishedAt)
                                            }
                                        </h4>
                                    </div>
                                    <div className="col-md-4">
                                        <Link
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setEditCuratedList({
                                                    ...curatedListSummaryData!,
                                                })
                                            }}
                                            to="#"
                                            className="btn btn-secondary"
                                        >
                                            Edit Details
                                        </Link>
                                        <Link
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAddingVenue(true);
                                            }}
                                            to="#"
                                            className="btn btn-primary m-2"
                                        >
                                            Add Venue
                                        </Link>
                                    </div>
                                </div>
                                <p>
                                    {curatedListSummaryData?.description || ''}
                                </p>
                            </CardHeader>
                            <CardBody className="px-0">
                                <SimpleBar className="table-responsive px-3">
                                    <table className="table align-middle table-nowrap table-borderless">
                                        <tbody>
                                            {curatedListVenuesData?.pages.map((curatedListVenuesPage) => curatedListVenuesPage.venues?.map((venue) => {
                                                return (
                                                    <tr key={`curated-list-venue-${venue.id}`}>
                                                        <td>
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0 me-3">
                                                                    {
                                                                        venue.images?.length > 0 ? (
                                                                            <img
                                                                                className="rounded avatar-md"
                                                                                data-holder-rendered="true"
                                                                                src={venue.images[0].url}
                                                                                alt=""
                                                                            />
                                                                        ) : null
                                                                    }
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <h5>{venue.name}</h5>
                                                                    <p className="mb-0">
                                                                        {venue.cuisineFilters.map((cuisine) => (
                                                                            <Badge pill className="badge-soft-success me-1">
                                                                                {cuisine.name}
                                                                            </Badge>
                                                                        ))}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{formatVenueAddress(venue)}</td>
                                                        <td>
                                                            <Badge className="me-2 bg-info">
                                                                {venue.evaluation.score}
                                                            </Badge>
                                                            <Badge className="me-2 bg-dark">
                                                                {venue.evaluation.reviews.count} Reviews
                                                            </Badge>
                                                            <Badge className="me-2 bg-dark">
                                                                {venue.evaluation.reviews.average} Avg Review
                                                            </Badge>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                disabled={isRemoving.includes(venue.id)}
                                                                onClick={async (e) => {
                                                                    e.preventDefault();

                                                                    if (!window.confirm('Are you sure you want to remove this venue?')) {
                                                                        return;
                                                                    }
                                                                    
                                                                    setIsRemoving((old) => [...old, venue.id])

                                                                    try {
                                                                        await AttAdminApi.curatedLists.venues.remove(
                                                                            props.match.params.curatedListId,
                                                                            venue.id,
                                                                        );
                                                                        refetchCuratedListVenues()
                                                                    } catch (e: any) {
                                                                        alert(e.message || e)
                                                                    }

                                                                    setIsRemoving((old) => [...old.filter(i => i !== venue.id)])
                                                                    
                                                                }}
                                                                to="#"
                                                                className="btn btn-danger"
                                                            >
                                                                {
                                                                    isRemoving.includes(venue.id) ? 'Removing...' : 'Remove'
                                                                }
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                );
                                            }))
                                            }
                                        </tbody>
                                    </table>

                                    {
                                        curatedListVenuesError && (
                                            <Alert color="danger">
                                                {curatedListVenuesError.message || 'An error occurred listing venues.'}
                                            </Alert>
                                        )
                                    }
                                    {
                                        isLoadingCuratedListVenues && 'Loading...'
                                    }
                                    {
                                        isFetchingNextPage && 'Loading more...'
                                    }
                                    {
                                        hasNextPage && (
                                            <button
                                                onClick={() => fetchNextPage()}
                                                disabled={isFetchingNextPage}
                                            >
                                                Load More
                                            </button>
                                        )
                                    }
                                </SimpleBar>
                            </CardBody>
                        </Card>
                    </Row>

                    <Modal
                        size="xl"
                        isOpen={isAddingVenue}
                        toggle={() => {
                            setAddingVenue(false);
                        }}
                        scrollable={true}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">Add a venue to "{curatedListSummaryData?.name}"</h5>
                            <button
                                type="button"
                                onClick={() => setAddingVenue(false)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Cancel"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <CardHeader>
                            <Form>
                                <Row>
                                    <Col md={6}>
                                        <div>
                                            <Label
                                                htmlFor="query-search-input"
                                                className="form-Label"
                                            >
                                                Search
                                            </Label>
                                            <Input
                                                className="form-control"
                                                type="search"
                                                placeholder="Search for a venue"
                                                id="query-search-input"
                                                value={venueSearchQuery}
                                                onChange={(e) => setVenueSearchQuery(e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div>
                                            <Label
                                                htmlFor="example-search-input"
                                                className="form-Label"
                                            >
                                                Location
                                            </Label>
                                            {isLoaded ? (
                                                <Autocomplete
                                                    onPlaceChanged={() => {
                                                        if (!searchResult) {
                                                            return;
                                                        }
                                                        const placeLocation =
                                                            searchResult.getPlace()?.geometry?.location;

                                                        if (placeLocation) {
                                                            setLocation({
                                                                latitude: parseFloat(placeLocation?.lat().toFixed(8)),
                                                                longitude: parseFloat(placeLocation?.lng().toFixed(8)),
                                                            });
                                                        }
                                                    }}
                                                    onLoad={(autocomplete) =>
                                                        setSearchResult(autocomplete)
                                                    }
                                                >
                                                    <Input
                                                        className="form-control"
                                                        type="search"
                                                        placeholder="Search for a location"
                                                        id="example-search-input"
                                                        ref={placeSearchRef}
                                                    />
                                                </Autocomplete>
                                            ) : (
                                                <>Loading...</>
                                            )}
                                        </div>
                                    </Col>
                                </Row>

                            </Form>
                        </CardHeader>

                        <div className="modal-body">
                            <CardBody className="px-0">
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Address</th>
                                                <th>Evaluation</th>
                                                <th>Options</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                venueListError && (<Alert color="danger">{venueListError.message || 'An error occurred listing venues.'}</Alert>)
                                            }
                                            {
                                                isLoadingVenueList && 'Loading...'
                                            }
                                            {venueListData?.venues.map((venue) => {
                                                return (
                                                    <tr key={venue.id}>
                                                        <td>
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0 me-3">
                                                                    {
                                                                        venue.images?.length > 0 ? (
                                                                            <img
                                                                                className="rounded avatar-md"
                                                                                data-holder-rendered="true"
                                                                                src={venue.images[0].url}
                                                                                alt=""
                                                                            />
                                                                        ) : null
                                                                    }
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <h5>{venue.name}</h5>
                                                                    <p className="mb-0">
                                                                        {venue.cuisineFilters.map((cuisine) => (
                                                                            <Badge pill className="badge-soft-success me-1">
                                                                                {cuisine.name}
                                                                            </Badge>
                                                                        ))}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{formatVenueAddress(venue)}</td>
                                                        <td>
                                                            <Badge className="me-2 bg-info">
                                                                {venue.evaluation.score}
                                                            </Badge>
                                                            <Badge className="me-2 bg-dark">
                                                                {venue.evaluation.reviews.count} Reviews
                                                            </Badge>
                                                            <Badge className="me-2 bg-dark">
                                                                {venue.evaluation.reviews.average} Avg Review
                                                            </Badge>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                disabled={isLoadingAddVenue}
                                                                onClick={async (e) => {
                                                                    e.preventDefault();
                                                                    setIsLoadingAddVenue(true);
                                                                    try {
                                                                        await AttAdminApi.curatedLists.venues.add(
                                                                            props.match.params.curatedListId,
                                                                            venue.id,
                                                                        );
                                                                        refetchCuratedListVenues()
                                                                        setAddingVenue(false)
                                                                    } catch (e: any) {
                                                                        setAddingVenueError(e.message || e)
                                                                    }
                                                                    setIsLoadingAddVenue(false);
                                                                }}
                                                                to="#"
                                                                className="btn btn-primary"
                                                            >
                                                                Select
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                            <div>
                                <div>
                                    {addingVenueError ? (
                                        <Alert color="danger">{addingVenueError}</Alert>
                                    ) : null}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setAddingVenue(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={Boolean(editCuratedList)}
                        toggle={() => {
                            setEditCuratedList(undefined);
                        }}
                        scrollable={true}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">Edit Details</h5>
                            <button
                                type="button"
                                onClick={() => setEditCuratedList(undefined)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Cancel"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div>
                                    {editCuratedListError ? (
                                        <Alert color="danger">{editCuratedListError}</Alert>
                                    ) : null}
                                </div>

                                <div>
                                    <div className="mb-3">
                                        <Label
                                            htmlFor="example-text-input"
                                            className="form-Label"
                                        >
                                            Name
                                        </Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            placeholder="Rooftop bars"
                                            value={editCuratedList?.name}
                                            onChange={(e) =>
                                                setEditCuratedList((old) => {
                                                    if (!old) return old;
                                                    return { ...old, name: e.target.value };
                                                })
                                            }
                                            defaultValue=""
                                            id="example-text-input"
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="example-text-input" className="form-Label">
                                        Description
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="textarea"
                                        placeholder="Description"
                                        value={editCuratedList?.description}
                                        onChange={(e) =>
                                            setEditCuratedList((old) => {
                                                if (!old) return old;
                                                return { ...old, description: e.target.value };
                                            })
                                        }
                                        defaultValue=""
                                        id="example-text-input"
                                    />
                                </div>

                                <div className="mb-3">
                                    <Label htmlFor="example-text-input" className="form-Label">
                                        Publishing
                                    </Label>

                                    <div className="form-check">
                                        <input
                                            onChange={async (e) => {

                                                const isChecked = e.target.checked

                                                console.log('isChecked', isChecked)

                                                setIsLoadingPublishing(true)

                                                try {
                                                    if (isChecked) {
                                                        await AttAdminApi.curatedLists.publishing.publish(
                                                            props.match.params.curatedListId,
                                                        )
                                                        setEditCuratedList((old) => {
                                                            if (!old) return old;
                                                            return {
                                                                ...old,
                                                                publishedAt: new Date(),
                                                            }
                                                        })
                                                        refetchCuratedListSummary()
                                                    } else {
                                                        await AttAdminApi.curatedLists.publishing.unpublish(
                                                            props.match.params.curatedListId,
                                                        )
                                                        setEditCuratedList((old) => {
                                                            if (!old) return old;
                                                            return {
                                                                ...old,
                                                                publishedAt: undefined,
                                                            }
                                                        })
                                                        refetchCuratedListSummary()
                                                    }
                                                } catch (e: any) {
                                                    setEditCuratedListError(e.message || e)
                                                }
                                                setIsLoadingPublishing(false)


                                            }}
                                            checked={Boolean(editCuratedList?.publishedAt)}
                                            disabled={isLoadingPublishing}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="published-list"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="capabilities-online-payments"
                                        >
                                            {isLoadingPublishing && (
                                                <b>[Updating...] </b>
                                            )}
                                            List is published
                                        </label>
                                    </div>


                                </div>

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setEditCuratedList(undefined)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={isLoadingEditCuratedList}
                                    onClick={async () => {

                                        setIsLoadingEditCuratedList(true)
                                        setEditCuratedListError(undefined)

                                        try {
                                            await AttAdminApi.curatedLists.update(
                                                props.match.params.curatedListId,
                                                editCuratedList!,
                                            );
                                            setEditCuratedList(undefined)
                                            refetchCuratedListSummary()
                                        } catch (e: any) {
                                            setEditCuratedListError(e.message || e)
                                            return
                                        }

                                        setIsLoadingEditCuratedList(false)
                                    }}
                                >
                                    {isLoadingEditCuratedList ? 'Updating...' : 'Update details'}
                                </button>
                            </div>
                        </div>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default ManageCuratedListPage;
