import MetaTags from "react-meta-tags";
import React from "react";
import { Row, Alert, Container } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";

import { socialLogin } from "../../store/actions";
import logo from "../../assets/images/logo-sm.svg";

interface LoginProps {
  history: object;
}

const LoginSupport = ({ history }: LoginProps) => {
  const dispatch = useDispatch();

  const { error } = useSelector((state: any) => ({
    error: state.login.error,
    otpSent: state.login.otpSent,
  }));

  const signIn = (res: any, type: any) => {
    if (type === "google" && res) {
      const postData = {
        idToken: res.credential,
      };
      dispatch(socialLogin(postData, history, type));
    }
  };

  const googleResponse = (response: Object) => {
    console.log("google response:", response);
    signIn(response, "google");
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | AllTheTables</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="/dashboard" className="d-block auth-logo">
                      <img src={logo} alt="" height="28" />{" "}
                      <span className="logo-txt">AllTheTables Admin</span>
                    </Link>
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <h5 className="mb-0">Welcome</h5>
                      <p className="text-muted mt-2">AllTheTables Admin 🩺</p>

                      <GoogleLogin
                        onSuccess={googleResponse}
                        onError={() => {
                          alert(`Google Signed in Failed`);
                        }}
                      />
                    </div>
                    {error ? <Alert color="danger">{error}</Alert> : null}
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()} AllTheTables . Made with{" "}
                      <i className="mdi mdi-heart text-danger"></i> by
                      AllTheTables
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(LoginSupport);
