import React, { useState } from "react";
// import {
//     useJsApiLoader,
// } from "@react-google-maps/api";

import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Alert, Badge, Card, CardBody, CardHeader, Container, Form, Input, Label, Row, Table } from "reactstrap";
import { AttAdminApi } from "../../helpers/att_api_helper";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { AdminVenueDto } from "src/helpers/att-api-dtos/venue/admin-venue.dto.interface";
import { WebsiteCrawlerResultDto } from "src/helpers/att-api-dtos/admin/venue/admin-crawler-results.dto.interface";

interface ManageBuilderPageProps {
    google: Object
    history: any
    match: {
        params: {
        }
    }
}

// const libraries: any[] = ["places"]

const ManageBuilderPage = (props: ManageBuilderPageProps) => {

    const [venueWebsiteUrl, setVenueWebsiteUrl] = useState<string>('')
    const [venueAddress, setVenueAddress] = useState<string | undefined>(undefined)
    const [selectedVenue, setSelectedVenue] = useState<AdminVenueDto | null>(null)

    const {
        data: crawlerResults,
        error: crawlerResultsError,
        isLoading: isLoadingCrawlerResults,
        refetch: fetchCrawlerResults,
    } = useQuery<
        { results: WebsiteCrawlerResultDto[] },
        Error
    >({
        queryKey: [`build-venue-from-url`, venueWebsiteUrl],
        queryFn: () => AttAdminApi.venues.admin.build.discoverDetails({
            websiteUrl: venueWebsiteUrl,
            venueAddress,
        }),
        refetchOnWindowFocus: false,
        enabled: false,
    });

    const {
        data: createdVenueData,
        error: createdVenueError,
        isLoading: isLoadingCreatingVenue,
        refetch: createVenue,
    } = useQuery<
        { venue: AdminVenueDto },
        Error
    >({
        queryKey: [`create-venue`, selectedVenue],
        queryFn: () => AttAdminApi.venues.admin.build.create(selectedVenue!),
        refetchOnWindowFocus: false,
        enabled: false,
    });

    const {
        data: updatedVenueData,
        error: updatedVenueError,
        // isLoading: isLoadingUpdatingVenue,
        refetch: updateVenue,
    } = useQuery<
        { venue: AdminVenueDto },
        Error
    >({
        queryKey: [`update-venue`, selectedVenue],
        queryFn: () => AttAdminApi.venues.admin.build.update(selectedVenue!),
        refetchOnWindowFocus: false,
        enabled: false,
    });

    /*
    const { isLoaded: isGoogleMapLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyDgohtZ966jJlToDhVXQlJ_egGA7gvEkXc",
        libraries,
    });
    */

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Venue Builder | ATT</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Home" breadcrumbItem="Venue" />
                    <Row>
                        <Card>
                            <CardHeader>
                                {
                                    Boolean(crawlerResultsError) && <Alert color="danger">
                                        {crawlerResultsError}
                                    </Alert>
                                }
                                <div className="row align-ite  ms-center">
                                    <div className="col-md-8 align-items-left">
                                        <h4 className="card-title mb-0 flex-grow-1">
                                            Venue Builder (Website Scanner)
                                        </h4>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <Input
                                                value={venueWebsiteUrl}
                                                onChange={(e) => {
                                                    setVenueWebsiteUrl(e.target.value);
                                                }}
                                                placeholder="https://venue-website.com/hello"
                                                type="text"
                                                className="form-control"
                                                id={`display-venue-search-website-url`}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Input
                                                value={venueAddress}
                                                onChange={(e) => {
                                                    setVenueAddress(e.target.value);
                                                }}
                                                placeholder="Address (optional)"
                                                type="text"
                                                className="form-control"
                                                id={`display-venue-search-website-address`}
                                            />
                                        </div> 
                                        <Link
                                            onClick={(e) => {
                                                if (isLoadingCrawlerResults) {
                                                    return;
                                                }
                                                e.preventDefault();
                                                fetchCrawlerResults();
                                            }}
                                            to="#"
                                            className="btn btn-primary m-2"
                                        >
                                            {
                                                isLoadingCrawlerResults
                                                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    : 'Scan Website'
                                            }
                                        </Link>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody className="px-0">
                                <Form>
                                    {
                                        createdVenueData && <Alert color="success">
                                            Venue created successfully
                                        </Alert>
                                    }
                                    {
                                        updatedVenueData && <Alert color="success">
                                            Venue updated successfully
                                        </Alert>
                                    }
                                    {
                                        crawlerResults && crawlerResults.results.length > 0 && (
                                            <div className="table-responsive">
                                                <Table className="table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Venue Details</th>
                                                            <th>Booking Links & Images</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {crawlerResults.results.map((result) => {
                                                            return (
                                                                <tr key={result.venue.id}>
                                                                    <td>
                                                                        <b>{result.venue.name}</b>
                                                                        <div>
                                                                            {
                                                                                Boolean(result.venue.id?.length)
                                                                                    ? <p>Saved ({result.venue.id}) &nbsp;
                                                                                        
                                                                                        {
                                                                                            updatedVenueError && <Badge className="bg-danger">
                                                                                                Error: {updatedVenueError.message}
                                                                                            </Badge>
                                                                                        }

                                                                                        <Badge className="me-2 bg-success" onClick={() => { }}>Click to view</Badge>
                                                                                        <Badge className="me-2 bg-success" onClick={() => {
                                                                                            setSelectedVenue(result.venue)

                                                                                            if (!selectedVenue) {
                                                                                                return
                                                                                            }

                                                                                            updateVenue()

                                                                                            setSelectedVenue(null)
                                                                                        }}>Click to update</Badge>
                                                                                    </p>
                                                                                    : <p>
                                                                                        Not in database &nbsp;
                                                                                        {
                                                                                            createdVenueError && <Badge className="bg-danger">
                                                                                                Error: {createdVenueError.message}
                                                                                            </Badge>
                                                                                        }

                                                                                        <Badge className="me-2 bg-warning" onClick={async () => {

                                                                                            setSelectedVenue(result.venue)

                                                                                            if (!selectedVenue) {
                                                                                                return
                                                                                            }

                                                                                            await createVenue()

                                                                                            setSelectedVenue(null)

                                                                                        }}>{
                                                                                                isLoadingCreatingVenue
                                                                                                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                                                    : 'Click to save'
                                                                                            }


                                                                                        </Badge>

                                                                                    </p>
                                                                            }
                                                                        </div>
                                                                        <p>
                                                                            {result.venue?.location.address.line1}
                                                                            {result.venue?.location?.address?.line2}
                                                                            {result.venue?.location?.address?.street}
                                                                            {result.venue?.location?.address?.town}
                                                                            {result.venue?.location?.address?.countryCode}
                                                                        </p>
                                                                        <Input
                                                                            className="form-control"
                                                                            type="textarea"
                                                                            placeholder="Description"
                                                                            rows={16}
                                                                            value={result.venue?.description}
                                                                            id="formrow-display-description-input"
                                                                        />

                                                                        {
                                                                            result.venue.cuisines.length === 0 && <div>No Cuisines Detected</div>
                                                                        }

                                                                        {
                                                                            result.venue.cuisines.map((cuisine) => {
                                                                                return <div
                                                                                    className="btn btn-outline-secondary"
                                                                                    key={`cuisine-${cuisine.id}`}
                                                                                >
                                                                                    {cuisine.name}
                                                                                </div>
                                                                            })
                                                                        }

                                                                        <div className="btn btn-outline-secondary">
                                                                            {
                                                                                Boolean(result?.venue?.social?.instagram?.length)
                                                                                    ? result?.venue?.social?.instagram
                                                                                    : 'No Instagram'
                                                                            }
                                                                        </div>
                                                                        <div className="btn btn-outline-secondary">
                                                                            {
                                                                                Boolean(result?.venue?.social?.facebook?.length)
                                                                                    ? result?.venue?.social?.facebook
                                                                                    : 'No Facebook'
                                                                            }
                                                                        </div>
                                                                        <div className="btn btn-outline-secondary">
                                                                            {
                                                                                Boolean(result?.venue?.social?.twitter?.length)
                                                                                    ? result?.venue?.social?.twitter
                                                                                    : 'No Twitter'
                                                                            }
                                                                        </div>
                                                                        {
                                                                            result.venue.openingTimes.length === 0 && <Alert color="warning">
                                                                                No opening times set
                                                                            </Alert>
                                                                        }
                                                                        {
                                                                            result.venue.openingTimes.map((openingTime) => {
                                                                                return <div>
                                                                                    <Label className="form-label" htmlFor={`display-opening-time-${openingTime.day}-input`}>
                                                                                        {openingTime.day}:
                                                                                        <ul>
                                                                                            {
                                                                                                openingTime.periods.map((period, i) => {
                                                                                                    return <li>From {period.startHHmm} to {period.finishHHmm}</li>
                                                                                                })
                                                                                            }
                                                                                        </ul>
                                                                                    </Label>
                                                                                </div>
                                                                            })
                                                                        }
                                                                        {
                                                                            <p>Website: {result.venue.contact.website || '-'}</p>
                                                                        }
                                                                        {
                                                                            <p>Email: {result.venue.contact.email || '-'}</p>
                                                                        }
                                                                        {
                                                                            <p>Phone: {result.venue.contact.phone || '-'}</p>
                                                                        }

                                                                        {
                                                                            <p>Facebook: {result.venue.social.facebook || '-'}</p>
                                                                        }
                                                                        {
                                                                            <p>Instagram: {result.venue.social.instagram || '-'}</p>
                                                                        }
                                                                        {
                                                                            <p>Twitter: {result.venue.social.twitter || '-'}</p>
                                                                        }
                                                                    </td>
                                                                    <td style={{
                                                                        maxWidth: '50%',
                                                                        width: '50%',
                                                                    }}>
                                                                        {
                                                                            result.bookingLinks.map((provider) => {
                                                                                return (
                                                                                    <div key={provider.providerId}>

                                                                                        {provider.providerType}
                                                                                        {provider.providerSlug}
                                                                                        {provider.providerId}
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        }

                                                                        {
                                                                            !Boolean(result.venue.images.length) && <Alert color="warning">
                                                                                No images found
                                                                            </Alert>
                                                                        }
                                                                        {
                                                                            result.venue.images.map((image) => {
                                                                                return <img
                                                                                    className="img-thumbnail offer-image-preview"
                                                                                    alt={'Thumbnail'}
                                                                                    src={image.url}
                                                                                    data-holder-rendered="true"
                                                                                />
                                                                            })
                                                                        }

                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        )

                                    }
                                </Form>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ManageBuilderPage;
