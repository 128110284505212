import React, { useState, useEffect } from "react";
import {
    GoogleMap,
    Marker,
    useJsApiLoader,
} from "@react-google-maps/api";
import Select from "react-select";

import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Alert, Badge, Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Row, Table } from "reactstrap";
import { AttAdminApi } from "../../helpers/att_api_helper";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { AdminVenueDto } from "src/helpers/att-api-dtos/venue/admin-venue.dto.interface";
import MapMarkerImage from "../../assets/images/map-pins/booking-providers/map-pin-the-fork.svg";
import CuisineFilterDto from "src/helpers/att-api-dtos/cuisines/cuisine-filter.dto.interface";

const libraries: any[] = ["places"]

interface ManageVenuePageProps {
    google: Object
    history: any
    match: {
        params: {
            venueId: string
        }
    }
}

interface CuisineFilterOption {
    label: string,
    options: {
        label: string,
        value: string,
    }[]
}

interface CuisineFilter {
    id: string
    name: string
    cuisines: {
        id: string
        name: string
    }[]
}

const ManageVenuePage = (props: ManageVenuePageProps) => {

    const {
        data: cuisineFiltersData,
        // error: errorFetchingCuisineFilters,
        // isLoading: isLoadingCuisineFilters,
        // refetch: refetchCuisineFilters,
    } = useQuery<
        { cuisineFilters: CuisineFilterDto[] },
        Error
    >({
        queryKey: [`cuisine-filters`],
        queryFn: () => AttAdminApi.cuisines.cuisineFilters.list(),
    });

    const {
        data: venueData,
        error,
        isLoading,
        // refetch: refetchVenueData,
    } = useQuery<
        { venue: AdminVenueDto },
        Error
    >({
        queryKey: [`manage-venue-${props.match.params.venueId}`, props.match.params.venueId],
        queryFn: () => AttAdminApi.venues.get(props.match.params.venueId),
    });

    const [cuisineFilterOptions, setCuisineFilterOptions] = useState<CuisineFilterOption[]>([]);
    const [selectedCuisineFilters, setSelectedCuisineFilters] = useState<CuisineFilter[]>([]);

    const { isLoaded: isGoogleMapLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyDgohtZ966jJlToDhVXQlJ_egGA7gvEkXc",
        libraries,
    });

    useEffect(() => {
        setCuisineFilterOptions(cuisineFiltersData?.cuisineFilters.map((cuisineFilter) => {
            return {
                label: cuisineFilter.name,
                options: cuisineFilter.mappedCuisines.map((mappedCuisine) => {
                    return {
                        label: mappedCuisine.name,
                        value: mappedCuisine.id,
                    }
                })
            }
        }) || []);
    }, [cuisineFiltersData])

    useEffect(() => {
        setSelectedCuisineFilters(venueData?.venue?.cuisineFilters || []);
    }, [venueData])

    if (isLoading) {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Loading Venue | ATT</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Home" breadcrumbItem="Venue" />
                        Loading...
                    </Container>
                </div>
            </React.Fragment>
        );
    }

    if (error) {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{venueData?.venue?.name || '?'} | ATT</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Home" breadcrumbItem="Venue" />
                        <Row>
                            <Card>
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {error.name || 'Error'}
                                    </h4>
                                </div>
                                <CardBody className="px-0">
                                    {error.message || 'An error occurred.'}
                                </CardBody>
                            </Card>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }

    const renderPublishedStatusBadge = (unpublishedAt?: Date) =>
        Boolean(unpublishedAt) ? (
            <Badge className="me-2 bg-danger">Unpublished</Badge>
        ) : (
            <Badge className="me-2 bg-success">Published</Badge>
        );

    const googleMapToPreviewCoordinates = isGoogleMapLoaded && Boolean(venueData?.venue?.location?.geo) ? (
        <GoogleMap
            mapContainerStyle={{
                width: "300px",
                height: "300px",
            }}
            center={{
                lat: venueData!.venue.location.geo.latitude!,
                lng: venueData!.venue.location.geo.longitude!,
            }}
            zoom={16}
            options={{
                mapId: "e5d70ce2d53e8838",
                mapTypeControl: false,
                streetViewControl: false,
            }}
        >
            <Marker
                key={`marker-${venueData?.venue.id}`}
                title={venueData?.venue.name}
                icon={MapMarkerImage}
                position={{
                    lat: venueData?.venue.location.geo.latitude!,
                    lng: venueData?.venue.location.geo.longitude!,
                }}
            />
        </GoogleMap>
    ) : (
        <>Loading map...</>
    );


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{venueData?.venue?.name || ''} | ATT</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Home" breadcrumbItem="Venue" />
                    <Row>
                        <Card>
                            <CardHeader>
                                <div className="row align-ite  ms-center">
                                    <div className="col-md-8 align-items-left">
                                        <h4 className="card-title mb-0 flex-grow-1">
                                            {venueData?.venue?.name || '? Venue'}
                                            <br />
                                            {
                                                renderPublishedStatusBadge(venueData?.venue?.unpublishedAt)
                                            }
                                        </h4>
                                    </div>
                                    <div className="col-md-4">
                                        <Link
                                            onClick={(e) => {
                                                e.preventDefault();

                                            }}
                                            to="#"
                                            className="btn btn-primary m-2"
                                        >
                                            Add Venue
                                        </Link>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody className="px-0">
                                <Form>

                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="formrow-display-name-input">
                                                    Display name
                                                </Label>
                                                <Input
                                                    value={venueData?.venue?.name}
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-display-name-input"
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="formrow-display-description-input">
                                                    Description
                                                </Label>
                                                <Input
                                                    className="form-control"
                                                    type="textarea"
                                                    placeholder="Description"
                                                    rows={16}
                                                    value={venueData?.venue?.description}
                                                    onChange={(e) => {
                                                        // e.target.value
                                                    }}
                                                    id="formrow-display-description-input"
                                                />
                                            </div>

                                            <Col>
                                                <h4>Cuisines</h4>

                                                <div className="">
                                                    <Select
                                                        value={setSelectedCuisineFilters}
                                                        onChange={(cuisine: { label: string, value: string }) => {

                                                            const parentItem = cuisineFiltersData?.cuisineFilters.find((c) => {
                                                                const isInItem = c.mappedCuisines.findIndex((o) => o.id === cuisine.value);
                                                                return isInItem > -1
                                                            });

                                                            const selectedParentItem = selectedCuisineFilters.find((c) => {
                                                                return c.id === parentItem?.id;
                                                            });

                                                            if (selectedParentItem) {
                                                                const isInParentItemAlready = selectedParentItem.cuisines.findIndex((c) => c.id === cuisine.value);

                                                                if (isInParentItemAlready > -1) {
                                                                    selectedParentItem.cuisines = selectedParentItem.cuisines.filter((c) => c.id !== cuisine.value);

                                                                    if (selectedParentItem.cuisines.length === 0) {
                                                                        setSelectedCuisineFilters(selectedCuisineFilters.filter((c) => c.id !== selectedParentItem.id))
                                                                    } else {
                                                                        setSelectedCuisineFilters((old => {
                                                                            return old.map((c) => {
                                                                                if (c.id === selectedParentItem.id) {
                                                                                    return selectedParentItem;
                                                                                }
                                                                                return c;
                                                                            })
                                                                        }))
                                                                    }
                                                                } else {
                                                                    selectedParentItem.cuisines.push({
                                                                        id: cuisine.value,
                                                                        name: cuisine.label,
                                                                    });
                                                                    setSelectedCuisineFilters((old => {
                                                                        return old.map((c) => {
                                                                            if (c.id === selectedParentItem.id) {
                                                                                return selectedParentItem;
                                                                            }
                                                                            return c;
                                                                        })
                                                                    }))
                                                                }
                                                            } else {
                                                                setSelectedCuisineFilters([
                                                                    ...selectedCuisineFilters,
                                                                    {
                                                                        id: parentItem!.id,
                                                                        name: parentItem!.name,
                                                                        cuisines: [
                                                                            {
                                                                                id: cuisine.value,
                                                                                name: cuisine.label,
                                                                            }
                                                                        ]
                                                                    }
                                                                ])
                                                            }

                                                        }}
                                                        options={cuisineFilterOptions}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="d-flex flex-wrap gap-2 mt-2">
                                                    {
                                                        selectedCuisineFilters.map((cuisineFilter) => {
                                                            return <div
                                                                className="btn btn-outline-secondary"
                                                            >
                                                                {cuisineFilter.name} &nbsp;
                                                                <span className="badge bg-danger mr-1" onClick={() => {
                                                                    setSelectedCuisineFilters(selectedCuisineFilters.filter((c) => c.id !== cuisineFilter.id))
                                                                }}> x </span>

                                                                <div className="d-flex flex-wrap gap-2 mt-1">
                                                                    {
                                                                        cuisineFilter.cuisines.map((cuisine) => {
                                                                            return <span className="badge bg-primary mr-1" onClick={() => {

                                                                                const parentItem = cuisineFiltersData?.cuisineFilters.find((c) => {
                                                                                    const isInItem = c.mappedCuisines.findIndex((o) => o.id === cuisine.id);
                                                                                    return isInItem > -1
                                                                                });

                                                                                const selectedParentItem = selectedCuisineFilters.find((c) => {
                                                                                    return c.id === parentItem?.id;
                                                                                });

                                                                                if (selectedParentItem) {
                                                                                    const isInParentItemAlready = selectedParentItem.cuisines.findIndex((c) => c.id === cuisine.id);

                                                                                    if (isInParentItemAlready > -1) {
                                                                                        selectedParentItem.cuisines = selectedParentItem.cuisines.filter((c) => c.id !== cuisine.id);

                                                                                        if (selectedParentItem.cuisines.length === 0) {
                                                                                            setSelectedCuisineFilters(selectedCuisineFilters.filter((c) => c.id !== selectedParentItem.id))
                                                                                        } else {
                                                                                            setSelectedCuisineFilters((old => {
                                                                                                return old.map((c) => {
                                                                                                    if (c.id === selectedParentItem.id) {
                                                                                                        return selectedParentItem;
                                                                                                    }
                                                                                                    return c;
                                                                                                })
                                                                                            }))
                                                                                        }
                                                                                    }
                                                                                }

                                                                            }}>{cuisine.name} X</span>
                                                                        })
                                                                    }
                                                                </div>

                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </Col>

                                            <Col className="mt-2">
                                                <h4>Contact Details</h4>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="display-phone-input">
                                                        Phone
                                                    </Label>
                                                    <Input
                                                        value={venueData?.venue?.contact?.phone}
                                                        type='tel'
                                                        className="form-control"
                                                        id="display-phone-input"
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="display-email-input">
                                                        Email
                                                    </Label>
                                                    <Input
                                                        value={venueData?.venue?.contact?.email}
                                                        type='email'
                                                        className="form-control"
                                                        id="display-email-input"
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="display-website-input">
                                                        Website
                                                    </Label>
                                                    <Input
                                                        value={venueData?.venue?.contact?.website}
                                                        type='url'
                                                        className="form-control"
                                                        id="display-website-input"
                                                    />
                                                </div>
                                            </Col>

                                            <Col className="mt-2">
                                                <h4>Social Media</h4>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="display-instagram-input">
                                                        Instagram
                                                    </Label>
                                                    <Input
                                                        value={venueData?.venue?.social.instagram}
                                                        type='url'
                                                        className="form-control"
                                                        id="display-instagram-input"
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="display-facebook-input">
                                                        Facebook
                                                    </Label>
                                                    <Input
                                                        value={venueData?.venue?.social.facebook}
                                                        type='url'
                                                        className="form-control"
                                                        id="display-facebook-input"
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="display-twitter-input">
                                                        Twitter
                                                    </Label>
                                                    <Input
                                                        value={venueData?.venue?.social.twitter}
                                                        type='url'
                                                        className="form-control"
                                                        id="display-twitter-input"
                                                    />
                                                </div>
                                            </Col>

                                            <Col className="mt-2">
                                                <h4>Opening Times</h4>

                                                {
                                                    venueData?.venue.openingTimes.length === 0 && <Alert color="warning">
                                                        No opening times set
                                                    </Alert>
                                                }
                                                {
                                                    venueData?.venue.openingTimes.map((openingTime) => {
                                                        return <div className="mb-3">
                                                            <Label className="form-label" htmlFor={`display-opening-time-${openingTime.day}-input`}>
                                                                {openingTime.day}
                                                            </Label>
                                                            {
                                                                openingTime.periods.map((period, i) => {
                                                                    return <div className="d-flex gap-2">
                                                                        <Input
                                                                            value={period.startHHmm}
                                                                            type='text'
                                                                            className="form-control"
                                                                            id={`display-opening-time-${openingTime.day}-${i}-start-input`}
                                                                        />
                                                                        <Input
                                                                            value={period.finishHHmm}
                                                                            type='text'
                                                                            className="form-control"
                                                                            id={`display-opening-time-${openingTime.day}-${i}=finish-input`}
                                                                        />
                                                                    </div>
                                                                })
                                                            }

                                                        </div>
                                                    })
                                                }
                                            </Col>
                                        </Col>
                                        <Col lg={6}>

                                            <h4>Booking providers</h4> [ADD]
                                            <Card>
                                                <CardBody className="px-0">
                                                    <Table className="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Provider</th>
                                                                <th>Identifiers</th>
                                                                <th>Settings</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                venueData?.venue?.providers?.map((provider, i) => {
                                                                    return <tr>
                                                                        <th scope="row">{provider.providerType}</th>
                                                                        <td>

                                                                            <div className="mb-3">
                                                                                <Label className="form-label" htmlFor={`display-provider-id-${i}-input`}>
                                                                                    ID
                                                                                </Label>
                                                                                <Input
                                                                                    value={provider.providerId}
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id={`display-provider-id-${i}-input`}
                                                                                />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <Label className="form-label" htmlFor={`display-provider-active-${i}-input`}>
                                                                                    Slug
                                                                                </Label>
                                                                                <Input
                                                                                    value={provider.providerSlug}
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id={`display-provider-slug-${i}-input`}
                                                                                />
                                                                            </div>

                                                                            <div className="mb-3">
                                                                                <Label className="form-label" htmlFor={`display-provider-active-${i}-input`}>
                                                                                    Inactive?
                                                                                </Label>
                                                                                <Input
                                                                                    checked={provider.isProviderInactive}
                                                                                    type='checkbox'
                                                                                    className="form-control"
                                                                                    id={`display-provider-active-${i}-input`}
                                                                                />
                                                                            </div>


                                                                            <div className="mb-3">
                                                                                <Label className="form-label" htmlFor={`display-provider-closed-${i}-input`}>
                                                                                    Closed?
                                                                                </Label>
                                                                                <Input
                                                                                    checked={provider.permanentlyClosed}
                                                                                    type='checkbox'
                                                                                    className="form-control"
                                                                                    id={`display-provider-closed-${i}-input`}
                                                                                />
                                                                            </div>

                                                                        </td>
                                                                        <td>
                                                                            <Button
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                }}
                                                                                to="#"
                                                                                className="btn btn-primary m-2"
                                                                            >
                                                                                Update
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }

                                                            <tr>
                                                                <th scope="row">The Fork</th>
                                                                <td>

                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                        to="#"
                                                                        className="btn btn-primary m-2"
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>

                                            <h4>Address</h4>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="display-address-line-1input">
                                                    Line 1
                                                </Label>
                                                <Input
                                                    value={venueData?.venue?.location.address.line1}
                                                    type="text"
                                                    className="form-control"
                                                    id="display-address-line-1input"
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="display-address-line-2-input">
                                                    Line 2
                                                </Label>
                                                <Input
                                                    value={venueData?.venue?.location?.address?.line2}
                                                    type="text"
                                                    className="form-control"
                                                    id="display-address-line-2-input"
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="display-address-street-input">
                                                    Street
                                                </Label>
                                                <Input
                                                    value={venueData?.venue?.location?.address?.street}
                                                    type="text"
                                                    className="form-control"
                                                    id="display-address-street-input"
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="display-address-town-input">
                                                    Town
                                                </Label>
                                                <Input
                                                    value={venueData?.venue?.location?.address?.town}
                                                    type="text"
                                                    className="form-control"
                                                    id="display-address-town-input"
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="display-address-country-code-input">
                                                    Country code
                                                </Label>
                                                <Input
                                                    value={venueData?.venue?.location?.address?.countryCode}
                                                    type="text"
                                                    className="form-control"
                                                    id="display-address-country-code-input"
                                                />
                                            </div>

                                            <h4>GPS Coordinate (move to component)</h4>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="display-address-latitude-input">
                                                    Latitude
                                                </Label>
                                                <Input
                                                    value={venueData?.venue?.location?.geo?.latitude}
                                                    type="number"
                                                    className="form-control"
                                                    id="display-address-latitude-input"
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="display-address-longitude-input">
                                                    Longitude
                                                </Label>
                                                <Input
                                                    value={venueData?.venue?.location?.geo?.longitude}
                                                    type="number"
                                                    className="form-control"
                                                    id="display-address-longitude-input"
                                                />
                                            </div>
                                            {googleMapToPreviewCoordinates}

                                        </Col>
                                    </Row>

                                </Form>

                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ManageVenuePage;
