import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useQuery } from "react-query";
import {
    Card,
    CardBody,
    Container,
    Badge,
    CardHeader,
    Button,
} from "reactstrap";
import { RestaurantAccountDto } from "src/helpers/att-api-dtos/restaurant-account/restaurant-account.dto";
import { AttAdminApi } from "src/helpers/att_api_helper";
import RestaurantAccountDetails from "./components/restaurant-account-details.component";
import RestaurantAccountUsers from "./components/restaurant-account-users.component";
import RestaurantAccountVenues from "./components/restaurant-account-venues.component";
import RestaurantAccountPaymentMethods from "./components/restaurant-account-payment-methods.component";

interface RestaurantAccountPageProps {
    history: any
    match: {
        params: {
            restaurantAccountId: string
        }
    }
}

const RestaurantAccountPage = (props: RestaurantAccountPageProps) => {

    const [restaurantAccount, setRestaurantAccount] = useState<RestaurantAccountDto>()

    const {
        data: restaurantAccountData,
        error: restaurantAccountError,
        isLoading: isLoadingRestaurantAccount,
        refetch: fetchRestaurantAccount,
    } = useQuery<
        RestaurantAccountDto,
        Error
    >({
        queryKey: [`restaurant-account`, props.match.params.restaurantAccountId],
        queryFn: () => AttAdminApi.restaurantAccounts.get(
            props.match.params.restaurantAccountId,
        ),
        refetchOnWindowFocus: false,
        enabled: true,
    });

    useEffect(() => {
        console.log('restaurantAccountData', restaurantAccountData)
        if (restaurantAccountData) {
            setRestaurantAccount(restaurantAccountData)
        }
    }, [restaurantAccountData])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{restaurantAccount?.businessName || 'Restaurant Account'} | ATT</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Restaurant Accounts" breadcrumbItem={restaurantAccount?.businessName || 'Restaurant Account'} />
                    <div style={{
                        margin: 20,
                    }}>
                        <Card>

                            <CardHeader>
                                <Button color="dark" onClick={() => props.history.push('/restaurant-accounts')}>
                                    Sign in as account owner
                                </Button>
                            </CardHeader>

                            {
                                isLoadingRestaurantAccount && <CardBody>
                                    <h3>Loading...</h3>
                                </CardBody>
                            }
                            {
                                restaurantAccountError && <CardBody>
                                    <Badge className="bg-danger">
                                        {restaurantAccountError}
                                    </Badge>
                                </CardBody>
                            }
                            {
                                restaurantAccount && <CardBody>
                                    { // 1] Edit account details (business name) 
                                    }
                                    <RestaurantAccountDetails restaurantAccount={restaurantAccount} />

                                    { // 2] Edit account users (add people in, optional invite email) + warning if venue isn't linked  
                                    }
                                    <RestaurantAccountUsers
                                        restaurantAccount={restaurantAccount}
                                        userAdded={() => fetchRestaurantAccount()}
                                        userEdited={() => fetchRestaurantAccount()}
                                    />

                                    { // 3] Linked venues to the account
                                    }
                                    <RestaurantAccountVenues
                                        restaurantAccount={restaurantAccount}
                                        venueAdded={() => fetchRestaurantAccount()}
                                        venueRemoved={() => fetchRestaurantAccount()}
                                        history={props.history}
                                    />

                                    { // 4] View and manage payment methods
                                    }
                                    <RestaurantAccountPaymentMethods
                                        restaurantAccount={restaurantAccount}
                                    />

                                    { // 5] View and manage capacity profiles (reuse component from restaurant portal)
                                    }

                                    { // 6] View bookings (Do this later - as bookings aren't live anyway)
                                    }

                                </CardBody>
                            }
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default RestaurantAccountPage
