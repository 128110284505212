import React, { useCallback, useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import VenueDto from "../../helpers/att-api-dtos/venue/venue.dto.interface";
import { AttAdminApi } from "../../helpers/att_api_helper";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { GeoPoint } from "../../helpers/att-api-dtos/availability/geo-rect.dto.interface";
import { formatVenueAddress } from "src/helpers/utils";

const libraries: any[] = ["places"]

const VenueDirectoryPage = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDgohtZ966jJlToDhVXQlJ_egGA7gvEkXc",
    libraries,
  });
  const placeSearchRef = useRef<Input | null>(null);
  const [searchResult, setSearchResult] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [location, setLocation] = useState<GeoPoint | undefined>(undefined);

  const [venueLoadingError, setVenueLoadingError] = useState<
    string | undefined
  >(undefined);
  const [isLoadingVenueList, setIsLoadingVenueList] = useState<boolean>(false);

  const [query, setQuery] = useState<string>('');

  const [venueData, setVenueData] = useState<{
    venues: VenueDto[];
    offsetVenueId?: string;
    limit: number;
  }>({
    venues: [],
    limit: 20,
  });

  const loadVenues = useCallback(async (offsetVenueId?: string) => {
    setIsLoadingVenueList(true);
    setVenueLoadingError(undefined);
    try {
      await setVenueData(
        await AttAdminApi.venues.list({
          offsetVenueId,
          location,
          query,
        })
      );
    } catch (error: any) {
      setVenueLoadingError(error?.message || error);
    }
    setIsLoadingVenueList(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, query]);

  useEffect(() => {
    loadVenues();
  }, [loadVenues, location, query]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Venue Directory | ATT</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Venue Directory" />
          <Row>
            <Card>
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Venue Directory</h4>
              </div>
              <CardHeader>
                <Form>
                  <Row>
                    <Col md={6}>
                    <div>
                        <Label
                          htmlFor="query-search-input"
                          className="form-Label"
                        >
                          Search
                        </Label>
                            <Input
                              className="form-control"
                              type="search"
                              placeholder="Search for a venue"
                              id="query-search-input"
                              value={query}
                              onChange={(e) => setQuery(e.target.value)}
                            />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div>
                        <Label
                          htmlFor="example-search-input"
                          className="form-Label"
                        >
                          Location
                        </Label>
                        {isLoaded ? (
                          <Autocomplete
                            onPlaceChanged={() => {
                              if (!searchResult) {
                                return;
                              }
                              const placeLocation =
                                searchResult.getPlace()?.geometry?.location;
                              console.log("place:", placeLocation?.lat(), placeLocation?.lng());

                              if (placeLocation) {
                                setLocation({
                                  latitude: parseFloat(placeLocation?.lat().toFixed(8)),
                                  longitude: parseFloat(placeLocation?.lng().toFixed(8)),
                                });
                              }
                            }}
                            onLoad={(autocomplete) =>
                              setSearchResult(autocomplete)
                            }
                          >
                            <Input
                              className="form-control"
                              type="search"
                              placeholder="Search for a location"
                              id="example-search-input"
                              ref={placeSearchRef}
                            />
                          </Autocomplete>
                        ) : (
                          <>Loading...</>
                        )}
                      </div>
                    </Col>
                  </Row>
                  
                </Form>
              </CardHeader>
              <CardBody className="px-0">
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Evaluation</th>
                        <th>Providers</th>
                      </tr>
                    </thead>
                    <tbody>

{
  venueLoadingError && (<Alert color="danger">{venueLoadingError}</Alert>)
}
{
  isLoadingVenueList && (<tr><td colSpan={4}>Loading...</td></tr>)
}


                      {venueData.venues.map((venue) => {
                        return (
                          <tr key={venue.id} onClick={async () =>
                            window.location.assign(`/venue-directory/${venue.id}`)
                        }>
                            <td>
                              {venue.name}
                              {venue.cuisineFilters.map((cuisine) => (
                                <Badge pill className="badge-soft-success me-1">
                                  {cuisine.name}
                                </Badge>
                              ))}
                            </td>
                            <td>{formatVenueAddress(venue)}</td>
                            <td>
                              <Badge className="me-2 bg-info">
                                {venue.evaluation.score}
                              </Badge>
                              <Badge className="me-2 bg-dark">
                                {venue.evaluation.reviews.count} Reviews
                              </Badge>
                              <Badge className="me-2 bg-dark">
                                {venue.evaluation.reviews.average} Avg Review
                              </Badge>
                            </td>
                            <td>
                              {venue.cuisineFilters.map((cuisine) => (
                                <Badge pill className="badge-soft-success me-1">
                                  {cuisine.name}
                                </Badge>
                              ))}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VenueDirectoryPage;
