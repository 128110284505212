import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Badge, Card, CardBody, Container, Row } from "reactstrap";
import { AttAdminApi } from "../../helpers/att_api_helper";
import { useDispatch } from "react-redux";
import ListStatusDto from "../../helpers/att-api-dtos/lists/list-status.dto.interface";
import SimpleBar from "simplebar-react";
import ReactTimeAgo from "react-time-ago";

const ManageListsPage = () => {
  const dispatch = useDispatch();

  const [listStatuses, setListStatuses] = useState<{
    listStatus: ListStatusDto[];
  }>({ listStatus: [] });

  const downloadListStatus = async () => {
    setListStatuses(await AttAdminApi.lists.status.list());
  };

  useEffect(() => {
    downloadListStatus();
  }, [dispatch]);

  const renderStatusBadge = (listStatus: ListStatusDto) =>
    listStatus.status === "active" ? (
      <Badge className="me-2 bg-success">Active</Badge>
    ) : (
      <Badge className="me-2 bg-danger">Inactive</Badge>
    );

  const getPercentageMapped = (listStatus: ListStatusDto): string => {
    const percentage = (listStatus.mappedCount / listStatus.listSize) * 100;
    return percentage.toFixed(0);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>External Lists | ATT</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Manage Lists" />
          <Row>
            <Card>
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">External Lists</h4>
              </div>
              <CardBody className="px-0">
                <SimpleBar className="table-responsive px-3">
                  <table className="table align-middle table-nowrap table-borderless">
                    <tbody>
                      {listStatuses.listStatus.map((listStatus) => {
                        return (
                          <tr key={listStatus.id}>
                            <td style={{ width: "50px" }}>
                              <div
                                className={
                                  listStatus.status === "active"
                                    ? "font-size-22 text-success"
                                    : "font-size-22 text-danger"
                                }
                              >
                                <i
                                  className={
                                    listStatus.status === "active"
                                      ? "bx bx-check-circle d-block"
                                      : "bx bx-dizzy d-block"
                                  }
                                ></i>
                              </div>
                            </td>

                            <td>
                              <div>
                                <h5 className="font-size-14 mb-1">
                                  {listStatus.name}
                                </h5>
                                <p className="text-muted mb-0 font-size-12">
                                  {renderStatusBadge(listStatus)}
                                </p>
                              </div>
                            </td>

                            <td>
                              <div className="text-end">
                                <p className="text-muted mb-0 font-size-12">
                                  Last Updated
                                </p>
                                <h5 className="font-size-14 mb-0">
                                  {listStatus.lastUpdatedAt ? (
                                    <ReactTimeAgo
                                      date={new Date(listStatus.lastUpdatedAt)}
                                      locale="en-US"
                                    />
                                  ) : (
                                    "Never"
                                  )}
                                </h5>
                              </div>
                            </td>

                            <td>
                              <div className="text-end">
                                <p className="text-muted mb-0 font-size-12">
                                  List Size
                                </p>
                                <h5 className="font-size-14 mb-0">
                                  {listStatus.listSize}
                                </h5>
                              </div>
                            </td>

                            <td>
                              <div className="text-end">
                                <p className="text-muted mb-0 font-size-12">
                                  Mapped
                                </p>
                                <h5 className="font-size-14 mb-0">
                                  {listStatus.mappedCount} (
                                  {getPercentageMapped(listStatus)}%)
                                </h5>
                              </div>
                            </td>

                            <td>
                              <button
                                type="button"
                                className="btn btn-soft-primary waves-effect waves-light"
                                onClick={async () =>
                                  await AttAdminApi.lists.status.scan(
                                    listStatus.id
                                  )
                                }
                              >
                                Scan
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </SimpleBar>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageListsPage;
