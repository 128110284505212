import React, { useEffect, useState } from "react";
import { Badge, Modal } from "reactstrap";
import AvailabilitySearchResultDto from "../../../helpers/att-api-dtos/availability/availability-search-result.dto.interface";
import VenueDto from "../../../helpers/att-api-dtos/venue/venue.dto.interface";
import { AttAdminApi } from "../../../helpers/att_api_helper";
import VenueImagesSlider from "./venue-images-slider.component";

const VenueInfoModel = (props: {
  searchResult?: AvailabilitySearchResultDto;
  toggle: () => void;
}) => {
  const [venue, setVenue] = useState<VenueDto | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const loadVenue = async () => {
    setIsLoading(true);
    const { venue } = await AttAdminApi.venues.get(props.searchResult!.venue.id);
    setVenue(venue);
    setIsLoading(false);
  };
  useEffect(() => {
    if (!props.searchResult) {
      return;
    }

    loadVenue();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchResult]);

  const cuisineSummary = () => {
    return venue?.cuisineFilters.map((cuisine) => (
          <Badge pill className="badge-soft-success me-1">
            {cuisine.name}
          </Badge>
        ))
  };

  const availabilitySummary = () => {
    if (!props.searchResult) {
      return <></>
    }

    return props.searchResult.slots.map((slot) => (<button
      type="button"
      className="btn btn-info btn-rounded w-md waves-effect waves-light"
    >
      {slot.timeSlot}<br />
      <small>{slot.tag}</small>
    </button>))

  }

  const content =
    isLoading || !venue ? (
      <div>
        <p>Loading...</p>
      </div>
    ) : (
      <div>
        {cuisineSummary()}
        <VenueImagesSlider images={venue.images!} />
        {availabilitySummary()}
        <div
          className="card-title-desc"
          dangerouslySetInnerHTML={{ __html: venue?.description }}
        />
      </div>
    );

  return (
    <React.Fragment>
      <Modal
        isOpen={Boolean(props.searchResult)}
        centered={true}
        toggle={props.toggle}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{props.searchResult?.venue?.name}</h5>
          <button
            type="button"
            onClick={() => props.toggle()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">{content}</div>
      </Modal>
    </React.Fragment>
  );
};

export default VenueInfoModel;
