import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { RegisterTypes } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postUser,
} from "../../../helpers/att_api_helper"
import { UnregisteredUser } from "./types"
import { RegisterUserRequest } from "../../../helpers/att-api-dtos/auth/register-user-request.dto.interface"

// initialize relavant method of both Auth
// const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }: { payload: {
  user: UnregisteredUser,
  history: any,
}, type: RegisterTypes }) {

  console.log('running registerUser with data:', user)

  try {
    const registrationPayload: RegisterUserRequest = { user }
    const response: Promise<any> = yield call(postUser, registrationPayload)

    // Save the users session
    localStorage.setItem("authUser", JSON.stringify(response));
    history.push("/dashboard");


    yield put(registerUserSuccessful(response))

  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(RegisterTypes.REGISTER_USER, registerUser)
}

function* registerSaga() {
  yield all([fork(watchUserRegister)])
}

export default registerSaga
