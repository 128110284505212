import AdminUserAuthenticationDto from "../../../helpers/att-api-dtos/admin/auth/admin-user-authentication.dto.interface"
import { ProfileTypes } from "./actionTypes"

// Fetch profile
export const fetchSupportProfile = () => {
  return {
    type: ProfileTypes.FETCH_PROFILE_SUPPORT,
    payload: {},
  }
}

export const fetchSupportProfileSuccess = (userProfile: Promise<AdminUserAuthenticationDto>) => {
  return {
    type: ProfileTypes.FETCH_PROFILE_SUPPORT_SUCCESS,
    payload: userProfile,
  }
}

// Update profile
export const editSupportProfile = (user: any) => {
  return {
    type: ProfileTypes.UPDATE_EDIT_PROFILE_SUPPORT,
    payload: { user },
  }
}

export const supportProfileSuccess = (msg: any) => {
  return {
    type: ProfileTypes.UPDATE_PROFILE_SUPPORT_SUCCESS,
    payload: msg,
  }
}

export const supportProfileError = (error: any) => {
  return {
    type: ProfileTypes.UPDATE_PROFILE_SUPPORT_ERROR,
    payload: error,
  }
}

export const resetSupportProfileFlag = () => {
  return {
    type: ProfileTypes.UPDATE_RESET_PROFILE_SUPPORT_FLAG,
  }
}
