import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useQuery } from "react-query";
import {
    Card,
    CardBody,
    Container,
    CardHeader,
    Button,
} from "reactstrap";
import { AttAdminApi } from "src/helpers/att_api_helper";
import { VenueLeadDto } from "src/helpers/att-api-dtos/venue/venue-lead.dto";

interface VenueLeadPageProps {
    history: any
    match: {
        params: {
            leadId: string
        }
    }
}

const VenueLeadPage = (props: VenueLeadPageProps) => {

    const [venueLead, setVenueLead] = useState<VenueLeadDto>()

    const {
        data: venueLeadData,
        error: venueLeadError,
        isLoading: isLoadingVenueLead,
        refetch: fetchVenueLead,
    } = useQuery<
        VenueLeadDto,
        Error
    >({
        queryKey: [`venue-lead`, props.match.params.leadId],
        queryFn: () => AttAdminApi.leads.getLead(props.match.params.leadId),
        refetchOnWindowFocus: false,
        enabled: true,
    });

    useEffect(() => {
        if (venueLeadData) {
            setVenueLead(venueLeadData)
        }
    }, [venueLeadData])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{venueLead?.venue.name || 'Venue Lead'} | ATT</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Venue Leads" breadcrumbItem={venueLead?.venue.name || 'Venue Lead'} />
                    <div style={{
                        margin: 20,
                    }}>
                        <Card>

                            <CardHeader>
                                <Button color="dark" onClick={() => props.history.push('/venue-leads')}>
                                    View in HubSpot
                                </Button>
                            </CardHeader>

                            {
                                isLoadingVenueLead && <CardBody>
                                    <h3>Loading...</h3>
                                </CardBody>
                            }
                            {
                                venueLeadError && <CardBody className="bg-danger">
                                    {venueLeadError}
                                    {
                                        <a href="#!" onClick={() => fetchVenueLead()} style={{ marginLeft: 10 }}>
                                            Retry
                                        </a>
                                    }
                                </CardBody>
                            }
                            {
                                venueLead && <CardBody>
                                    { // 1] Edit account details (business name) 
                                    }


                                </CardBody>
                            }
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default VenueLeadPage
