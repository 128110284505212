// import { formatInTimeZone } from "date-fns-tz";

import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Badge, Card, CardBody, Container, Row, Table } from "reactstrap";
import { AttAdminApi } from "../../helpers/att_api_helper";
import { useDispatch } from "react-redux";
import SearchSessionDto from "../../helpers/att-api-dtos/search-session/search-session.dto.interface";

const SearchSessionsPage = () => {
  const dispatch = useDispatch();

  const [searchSessions, setSearchSessions] = useState<{
    searchSessions: SearchSessionDto[];
  }>({ searchSessions: [] });

  const [showingAreas, setShowingAreas] = useState<Record<string, boolean>>({})

  const downloadSearchSessions = async () => {
    setSearchSessions(await AttAdminApi.searchSessions.list());
  };

  useEffect(() => {
    downloadSearchSessions();
  }, [dispatch]);


  const generateBadge = (searchSession: SearchSessionDto) => {
    switch (searchSession.status) {
      case "incomplete":
        return <Badge
          pill
          className="badge-soft-danger me-1"
        >
          {searchSession.query.location && (
            <small>
              <small>
                <i className="dripicons-pin"></i>
              </small>
            </small>
          )}
          {searchSession.results.searchAreas?.length > 0 && (
            <small>
              <small>
                <i className="dripicons-map"></i>
              </small>
            </small>
          )}
          &nbsp;Incomplete
        </Badge>
      case "complete": return <Badge
        pill
        className="badge-soft-success me-1"
      >
        {searchSession.query.location && (
          <small>
            <small>
              <i className="dripicons-pin"></i>
            </small>
          </small>
        )}
        {searchSession.results.searchAreas?.length > 0 && (
          <small>
            <small>
              <i className="dripicons-map"></i>
            </small>
          </small>
        )}
        &nbsp;Complete
      </Badge>
      case "cancelled": return <Badge
        pill
        className="badge-soft-warning me-1"
      >
        {searchSession.query.location && (
          <small>
            <small>
              <i className="dripicons-pin"></i>
            </small>
          </small>
        )}
        {searchSession.results.searchAreas?.length > 0 && (
          <small>
            <small>
              <i className="dripicons-map"></i>
            </small>
          </small>
        )}
        &nbsp;Cancelled
      </Badge>
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Search Sessions | ATT</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Search Sessions" />
          <Row>
            <Card>
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Search Sessions</h4>
              </div>
              <CardBody className="px-0">
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Created</th>
                        <th>Device</th>
                        <th>Query</th>
                        <th>Venue Population</th>
                        <th>Checked</th>
                        <th>Available</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchSessions.searchSessions.map((searchSession) => {
                        return (
                          <>
                            <tr key={searchSession.id} onClick={() => {
                              console.log('toggled')
                              setShowingAreas((prev) => {

                                prev[searchSession.id] = !prev[searchSession.id]

                                return {...prev}
                              })
                            }} >
                              <td>
                                <div>
                                  {
                                    generateBadge(searchSession)
                                  }
                                </div>
                              </td>
                              <td>
                                {
                                  searchSession.createdAt
                                }
                                {/* {formatInTimeZone(
                                    searchSession.createdAt,
                                    "Europe/London",
                                    "h:mmaaa do LLLL"
                                  )} */}

                              </td>
                              <td>
                                <code className="highlighter-rouge">
                                  <small>{searchSession.owner.deviceId}</small>
                                </code>
                              </td>
                              <td>
                                {searchSession.query.covers} covers,{" "}
                                {
                                  searchSession.query.dateTime
                                }
                                {/* {formatInTimeZone(
                                    searchSession.query.dateTime,
                                    "Europe/London",
                                    "eee MM-dd HH:mm",
                                  )} */}
                                {searchSession.query.cuisineFilters.map(
                                  (cuisineFilter) => (
                                    <Badge pill className="badge-soft-dark me-1">
                                      {cuisineFilter.name}
                                    </Badge>
                                  )
                                )}
                              </td>
                              <td>{searchSession.results.venueCount}</td>
                              <td>{searchSession.results.checkedCount}</td>
                              <td>{searchSession.results.availableCount}</td>
                            </tr>
                            {
                              showingAreas[searchSession.id] && searchSession.results.searchAreas?.map((searchArea) => {

                                return <tr key={searchArea.id} className="table-light">
                                  <td></td>
                                  <td>
                                    <small>
                                      { 
                                      searchArea.createdAt}
                                      {/* {formatInTimeZone(
                                          searchArea.createdAt,
                                          "Europe/London",
                                          "h:mmaaa do LLL",
                                        )} */}
                                    </small>
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <small>{searchArea.results.venueCount}</small>
                                  </td>
                                  <td>
                                    <small>{searchArea.results.checkedCount}</small>
                                  </td>
                                  <td>
                                    <small>{searchArea.results.availableCount}</small>
                                  </td>
                                </tr>
                              })
                            }
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SearchSessionsPage;
