import React, { useEffect, useState } from "react";
import { RestaurantAccountDto } from "src/helpers/att-api-dtos/restaurant-account/restaurant-account.dto";
import { useQuery } from "react-query";
import { AttAdminApi } from "src/helpers/att_api_helper";
import { Table } from "reactstrap";
import { RestaurantAccountUserDto } from "src/helpers/att-api-dtos/restaurant-account/restaurant-account-user.dto";
import { Col, Input, Label, Badge, Modal, Card, CardBody } from "reactstrap";

const RestaurantAccountUsers = ({
    restaurantAccount,
    userAdded,
    userEdited,
}: {
    restaurantAccount: RestaurantAccountDto,
    userAdded: (user: RestaurantAccountUserDto) => void,
    userEdited: (user: RestaurantAccountUserDto) => void,
}) => {

    const [isAddingUser, setIsAddingUser] = useState(false)

    const [editingUser, setEditingUser] = useState<RestaurantAccountUserDto | undefined>()
    const [inviteUrlText, setInviteUrlText] = useState<string>("Copy")

    const [users, setUsers] = useState<RestaurantAccountUserDto[]>(restaurantAccount.users)

    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [sendWelcomeEmail, setSendWelcomeEmail] = useState<boolean>(false)

    const {
        data: editedUser,
        error: errorUpdatingUser,
        isLoading: isLoadingUpdatedUser,
        refetch: updateUser,
    } = useQuery<
        RestaurantAccountUserDto,
        Error
    >({
        queryKey: [`edit-restaurant-user`, restaurantAccount.id],
        queryFn: () => AttAdminApi.restaurantAccounts.users.editUser(
            restaurantAccount.id,
            editingUser!.id, {
            firstName: editingUser!.firstName,
            lastName: editingUser!.lastName,
            email: editingUser!.email?.trim(),
            deletedAt: editingUser!.deletedAt,
        }),
        refetchOnWindowFocus: false,
        enabled: false,
    });

    useEffect(() => {
        if (editedUser) {
            const index = restaurantAccount.users.findIndex(u => u.id === editedUser.id)
            restaurantAccount.users[index] = editedUser
            setEditingUser(undefined)
            userEdited(editedUser)
        }
    }, [editedUser, restaurantAccount.users, userEdited])

    const {
        data: addedUser,
        error: errorAddingUser,
        isLoading: isLoadingAddingUser,
        refetch: addUser,
    } = useQuery<
        RestaurantAccountUserDto,
        Error
    >({
        queryKey: [`add-restaurant-user`, restaurantAccount.id],
        queryFn: () => AttAdminApi.restaurantAccounts.users.addUser(
            restaurantAccount.id, {
            firstName,
            lastName,
            email,
            sendWelcomeEmail,
        }),
        refetchOnWindowFocus: false,
        enabled: false,
    });

    useEffect(() => {
        setUsers(restaurantAccount.users)
    }, [restaurantAccount])

    useEffect(() => {
        if (addedUser) {
            restaurantAccount.users.push(addedUser)
            setFirstName('')
            setLastName('')
            setEmail('')
            userAdded(addedUser)
            setIsAddingUser(false)
        }
    }, [addedUser, restaurantAccount.users, userAdded])

    return (
        <React.Fragment>
            {
                errorAddingUser && (
                    <div className="alert alert-danger" role="alert">
                        {errorAddingUser}
                    </div>
                )
            }
            {
                <div className="table-responsive mt-4">
                    <Label>
                        Account users &nbsp;
                        <Badge
                            className="rounded-pill badge-soft-primary ms-1"
                            onClick={() => setIsAddingUser(true)}
                        >
                            &nbsp;Add new&nbsp;
                        </Badge>
                    </Label>
                    <Table className="table table-hover mb-0">
                        <tbody>
                            {users.map((userAccount) => {
                                return (<tr key={userAccount.id}  onClick={(e) => {
                                    setEditingUser(userAccount)
                                    e.preventDefault()
                                }}>
                                    <td style={{
                                        width: '100%',
                                    }}>
                                        {userAccount.firstName} {userAccount.lastName}
                                        {
                                            userAccount.deletedAt && (
                                                <Badge
                                                    className="badge-soft-danger rounded-pill ms-1"
                                                >
                                                    Archived
                                                </Badge>
                                            )
                                        }
                                    </td>
                                    <td>
                                        {
                                            userAccount.email
                                        }
                                    </td>
                                    <td style={{
                                        minWidth: 200,
                                        float: 'right',
                                    }}>
                                        <button
                                            style={{
                                                float: 'right',
                                                marginLeft: 10,
                                            }}
                                            type="button"
                                            className="btn btn-sm btn-outline-secondary waves-effect"
                                        >
                                            Copy invite link
                                        </button>
                                        <button
                                            style={{
                                                float: 'right',
                                            }}
                                            onClick={() => {
                                                setEditingUser(userAccount)
                                            }}
                                            type="button"
                                            className="btn btn-sm btn-light waves-effect"
                                        >
                                            Edit
                                        </button>
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                );
                            })}
                        </tbody>
                    </Table>

                    <Modal
                        size="md"
                        isOpen={Boolean(editingUser)}
                        toggle={() => {
                            setEditingUser(undefined)
                        }}
                        scrollable={true}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">Editing {editingUser?.firstName} {editingUser?.lastName}</h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setEditingUser(undefined)
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Cancel"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <Card>
                                <CardBody>

                                    <div className="row gx-3 gy-2 align-items-center">

                                        <Col sm={6}>
                                            <Label className="form-label" htmlFor="formrow-firstname-input">
                                                First name
                                            </Label>
                                            <Input
                                                required
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-input"
                                                value={editingUser?.firstName}
                                                onChange={(e) => setEditingUser((prev) => ({
                                                    ...prev!,
                                                    firstName: e.target.value
                                                }))}
                                            />
                                        </Col>

                                        <Col sm={6}>
                                            <Label className="form-label" htmlFor="formrow-lastname-input">
                                                Last name
                                            </Label>
                                            <Input
                                                required
                                                type="text"
                                                className="form-control"
                                                id="formrow-lastname-input"
                                                value={editingUser?.lastName}
                                                onChange={(e) => setEditingUser((prev) => ({
                                                    ...prev!,
                                                    lastName: e.target.value
                                                }))}
                                            />
                                        </Col>
                                    </div>

                                    <Col sm={12} className="mt-2">
                                        <Label className="form-label" htmlFor="formrow-email-input">
                                            Email
                                        </Label>
                                        <Input
                                            required
                                            type="text"
                                            className="form-control"
                                            id="formrow-email-input"
                                            value={editingUser?.email}
                                            onChange={(e) => setEditingUser((prev) => ({
                                                ...prev!,
                                                email: e.target.value
                                            }))}
                                        />
                                    </Col>

                                    <Col sm={12} className="mt-2">
                                        <Label className="form-label" htmlFor="formrow-email-input">
                                            Invite link
                                        </Label> &nbsp;
                                        <Badge
                                            className="rounded-pill badge-soft-primary ms-1"
                                            onClick={async () => {
                                                navigator.clipboard.writeText(editingUser!.inviteUrl)
                                                    .then(() => {
                                                        setInviteUrlText('Copied 🎉')
                                                    })
                                                    .catch(err => {
                                                        setInviteUrlText('Failed to copy text: ' + err);
                                                    })

                                                setTimeout(function () {
                                                    setInviteUrlText("Copy");
                                                }, 2500)
                                            }}
                                        >
                                            &nbsp;{inviteUrlText}&nbsp;
                                        </Badge>
                                        <br />
                                        <code className="highlighter-rouge">
                                            {editingUser?.inviteUrl}
                                        </code>
                                    </Col>

                                    <Col sm={12} className="mt-2">
                                        <Label className="form-label" htmlFor="formrow-email-input">
                                            Archived
                                        </Label>
                                        <div className="form-check">
                                            <input
                                                checked={Boolean(editingUser?.deletedAt)}
                                                onChange={(e) => setEditingUser((prev) => ({
                                                    ...prev!,
                                                    deletedAt: e.target.checked
                                                        ? (editingUser?.deletedAt || new Date())
                                                        : undefined
                                                }))}
                                                className="form-check-input"
                                                type="checkbox"
                                                id="formCheck2"
                                            />
                                            <label className="form-check-label" htmlFor="formCheck2">
                                                Archived {
                                                    editingUser?.deletedAt
                                                        ? `at ${new Date(editingUser.deletedAt).toLocaleString()}`
                                                        : ''
                                                }
                                            </label>
                                            </div>
                                    </Col>


                                </CardBody>
                            </Card>
                        </div>
                        <div className="modal-footer">

                            {
                                errorUpdatingUser && (
                                    <div className="alert alert-danger" role="alert">
                                        {errorUpdatingUser}
                                    </div>
                                )
                            }
                            <button
                                disabled={isLoadingUpdatedUser}
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                    setEditingUser(undefined)
                                }}
                            >
                                Cancel
                            </button>

                            <button
                                disabled={isLoadingUpdatedUser}
                                type="button"
                                className="btn btn-dark"
                                onClick={() => {
                                    if (!editingUser?.firstName.trim().length) {
                                        alert('First name is required')
                                        return
                                    }
                                    if (!editingUser?.lastName.trim().length) {
                                        alert('Last name is required')
                                        return
                                    }
                                    if (!editingUser?.email.trim().length) {
                                        alert('Email is required')
                                        return
                                    }
                                    updateUser()
                                }}
                            >
                                {
                                    isLoadingUpdatedUser
                                        ? 'Updating...'
                                        : 'Update user'
                                }
                            </button>
                        </div>
                    </Modal>


                    <Modal
                        size="md"
                        isOpen={isAddingUser}
                        toggle={() => {
                            setFirstName('')
                            setLastName('')
                            setEmail('')
                            setIsAddingUser(false)
                        }}
                        scrollable={true}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">Add user to {restaurantAccount.businessName}</h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setFirstName('')
                                    setLastName('')
                                    setEmail('')
                                    setIsAddingUser(false)
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Cancel"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <Card>
                                <CardBody>

                                    <div className="row gx-3 gy-2 align-items-center">

                                        <Col sm={6}>
                                            <Label className="form-label" htmlFor="formrow-firstname-input">
                                                First name
                                            </Label>
                                            <Input
                                                required
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-input"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </Col>

                                        <Col sm={6}>
                                            <Label className="form-label" htmlFor="formrow-lastname-input">
                                                Last name
                                            </Label>
                                            <Input
                                                required
                                                type="text"
                                                className="form-control"
                                                id="formrow-lastname-input"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </Col>
                                    </div>

                                    <Col sm={12} className="mt-2">
                                        <Label className="form-label" htmlFor="formrow-email-input">
                                            Email
                                        </Label>
                                        <Input
                                            required
                                            type="text"
                                            className="form-control"
                                            id="formrow-email-input"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </Col>

                                    <Col sm={12} className="mt-2">
                                        <div className="form-check">
                                            <input
                                                checked={sendWelcomeEmail}
                                                onChange={(e) => setSendWelcomeEmail(e.target.checked)}
                                                className="form-check-input"
                                                type="checkbox"
                                                id="formCheck2"
                                            />
                                            <label className="form-check-label" htmlFor="formCheck2">
                                                Send welcome email
                                            </label>
                                        </div>
                                    </Col>

                                </CardBody>
                            </Card>
                        </div>
                        <div className="modal-footer">
                            <button
                                disabled={isLoadingAddingUser}
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                    setFirstName('')
                                    setLastName('')
                                    setEmail('')
                                    setIsAddingUser(false)
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={isLoadingAddingUser}
                                type="button"
                                className="btn btn-dark"
                                onClick={() => {
                                    if (!firstName.trim().length) {
                                        alert('First name is required')
                                        return
                                    }
                                    if (!lastName.trim().length) {
                                        alert('Last name is required')
                                        return
                                    }
                                    if (!email.trim().length) {
                                        alert('Email is required')
                                        return
                                    }
                                    addUser()
                                }}
                            >
                                {
                                    isLoadingAddingUser
                                        ? 'Loading'
                                        : 'Add user'
                                }
                            </button>
                        </div>
                    </Modal>


                </div>
            }

        </React.Fragment>
    );
};

export default RestaurantAccountUsers;
