import React, { useState } from "react";
import { Carousel, CarouselItem, CarouselControl } from "reactstrap";


const VenueImagesSlider = (props: { images: { url: string }[] } ) => {

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [animating, setAnimating] = useState<boolean>(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === props.images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? props.images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = props.images.map(item => {
    return (
      <CarouselItem 
       onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.url}
      >
        <img src={item.url} className="d-block img-fluid" alt={item.url} />
      </CarouselItem>
    );
  });

  return (
    <React.Fragment>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </React.Fragment>
  );
};

export default VenueImagesSlider;
