import React, { useEffect, useState } from "react";
import { FilePond, registerPlugin } from 'react-filepond'
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useQuery } from "react-query";
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    Row,
    Table,
    Label,
    Modal,
} from "reactstrap";
import { AttAdminApi, getLoggedInUser } from "src/helpers/att_api_helper";
import { PartnerDto } from "src/helpers/att-api-dtos/partner/partner.dto";
import { CountryDto } from "src/helpers/att-api-dtos/country/country.dto";
import { CountryService } from "src/services/country/country.service";
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { API_URL } from "src/helpers/api_helper";

interface ContentPartnersPageProps {
    history: any;
}

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const ContentPartnersPage = ({ history }: ContentPartnersPageProps) => {

    const [isAddingPartner, setIsAddingPartner] = useState<boolean>(false)
    const [partnerName, setPartnerName] = useState<string>('')
    const [partnerSquareLogoUrl, setPartnerSquareLogoUrl] = useState<string>('')
    const [files, setFiles] = useState<any[]>([])
    const [countries, setCountries] = useState<CountryDto[]>(CountryService.defaultCountries)
    const [country, setCountry] = useState<CountryDto>(CountryService.defaultCountries[0])
    const [pageSize] = useState<number>(20)

    const {
        data: partnersData,
        error: partnersError,
        isLoading: isLoadingPartners,
        refetch: fetchPartners,
    } = useQuery<
        { partners: PartnerDto[] },
        Error
    >({
        queryKey: [`content-partners`],
        queryFn: ({ pageParam }) => AttAdminApi.partners.list({
            offset: pageParam || 0,
        }),
        refetchOnWindowFocus: false,
        enabled: true,
        getNextPageParam: (lastPage, pages) => lastPage.partners.length
            ? (pages.length * pageSize) + lastPage.partners.length
            : 0,
    });

    const {
        data: addedPartner,
        error: errorAddingPartner,
        isLoading: isLoadingAddingPartner,
        refetch: addPartner,
    } = useQuery<
        PartnerDto,
        Error
    >({
        queryKey: [`add-partner`,],
        queryFn: () => AttAdminApi.partners.create({
            name: partnerName,
            country: country.code,
            logoSquareUrl: partnerSquareLogoUrl?.length ? partnerSquareLogoUrl : undefined,
        }),
        refetchOnWindowFocus: false,
        enabled: false,
    });

    useEffect(() => {
        if (addedPartner) {
            setPartnerName('')
            setPartnerSquareLogoUrl('')
            setIsAddingPartner(false)
            fetchPartners()
        }
    }, [addedPartner, fetchPartners]);

    const handleFetchCountries = async () => {
        const countries = await AttAdminApi.countries.list()
        setCountries(countries)
        if (countries.length) {
            setCountry(countries[0])
        }
    }

    useEffect(() => {
        handleFetchCountries()
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Partners | ATT</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Home" breadcrumbItem="Partners" />
                    <div style={{
                        margin: 20,
                    }}>
                        <Card>
                            <CardHeader>
                                <button style={{
                                    float: 'right',
                                }} onClick={() => {
                                    setIsAddingPartner(true)
                                }} className="btn btn-dark btn-sm">Add partner</button>
                            </CardHeader>
                            <CardBody className="px-0">
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Partner</th>
                                                <th># Linked venues</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                partnersError && (<Alert color="danger">
                                                    {partnersError}
                                                    <div>
                                                        <a
                                                            href="#!"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                fetchPartners();
                                                            }}
                                                            className="text-muted"
                                                        >
                                                            Try again
                                                        </a>

                                                    </div>
                                                </Alert>)
                                            }
                                            {
                                                isLoadingPartners && (<tr><td colSpan={4}>Loading...</td></tr>)
                                            }
                                            {
                                                partnersData?.partners.map((partner) => {
                                                    return (
                                                        <tr key={partner.id} onClick={async () =>
                                                            window.location.assign(`/content-partners/${partner.id}`)
                                                        }>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="flex-shrink-0 me-3">
                                                                        {
                                                                            partner.logoSquareUrl ? (
                                                                                <img
                                                                                    className="rounded avatar-md"
                                                                                    data-holder-rendered="true"
                                                                                    src={partner.logoSquareUrl}
                                                                                    alt=""
                                                                                />
                                                                            ) : <img
                                                                                className="rounded avatar-md"
                                                                                data-holder-rendered="true"
                                                                                src={'https://allthetables.s3.eu-west-2.amazonaws.com/restaurant_placeholder_grey.jpeg'}
                                                                                alt=""
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        <h5>{partner.name}</h5>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                            <h4>{partner.venueCount}</h4>
                                                            </td>
                                                            <td>
                                                                <button onClick={() => {
                                                                    history.push(`/content-partners/${partner.id}`)
                                                                }} className="btn btn-dark btn-sm">View</button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>

                        <Modal
                            size="md"
                            isOpen={isAddingPartner}
                            toggle={() => {
                                setIsAddingPartner(false)
                            }}
                            scrollable={true}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Add Partner</h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsAddingPartner(false)
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Cancel"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <Card>
                                    <CardBody>

                                        <div className="row gx-3 gy-2 align-items-center">

                                            <Col sm={6}>
                                                <Label className="form-label" htmlFor="formrow-firstname-input">
                                                    Partner name
                                                </Label>
                                                <Input
                                                    required
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-input"
                                                    value={partnerName}
                                                    onChange={(e) => setPartnerName(e.target.value)}
                                                />
                                            </Col>

                                            <div className="mb-3">
                                                <Label className="form-Label">Country</Label>
                                                <select
                                                    className="form-select"
                                                    onChange={e => countries && setCountry(countries[parseInt(e.target.value)])}
                                                >
                                                    {countries && countries.map(
                                                        (country, i) => <option
                                                            key={country.code}
                                                            value={i}>{country.flag} {country.name}
                                                        </option>
                                                    )}
                                                </select>
                                            </div>

                                            <Col sm={6}>
                                                <label
                                                    className="form-label font-size-13"
                                                >
                                                    Thumbnail Image
                                                </label>

                                                <Row>

                                                    {
                                                        Boolean(partnerSquareLogoUrl?.length) ? <Col lg={12}>
                                                            <img
                                                                className="img-thumbnail offer-image-preview"
                                                                alt={'Thumbnail'}
                                                                src={partnerSquareLogoUrl}
                                                                data-holder-rendered="true"
                                                            />
                                                        </Col>
                                                            : <FilePond
                                                                files={files}
                                                                onupdatefiles={setFiles}
                                                                onprocessfile={(error, file) => {
                                                                    if (error || !isAddingPartner) {
                                                                        return
                                                                    }
                                                                    const uploadResponse: { url: string } = JSON.parse(file.serverId)
                                                                    setPartnerSquareLogoUrl(uploadResponse.url)
                                                                }}
                                                                credits={false}
                                                                allowMultiple={false}
                                                                maxFiles={1}
                                                                server={{
                                                                    url: `${API_URL}admin/files/thumbnail`,
                                                                    headers: {
                                                                        'x-support-jwt-auth': `${getLoggedInUser()?.auth?.accessToken}`,
                                                                    }
                                                                }}
                                                                name="file"
                                                                labelIdle=''
                                                            />
                                                    }
                                                </Row>
                                            </Col>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="modal-footer">

                                {
                                    errorAddingPartner && (
                                        <div className="alert alert-danger" role="alert">
                                            {errorAddingPartner}
                                        </div>
                                    )
                                }
                                <button
                                    disabled={isLoadingAddingPartner}
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        setIsAddingPartner(false)
                                    }}
                                >
                                    Cancel
                                </button>

                                <button
                                    disabled={isLoadingAddingPartner}
                                    type="button"
                                    className="btn btn-dark"
                                    onClick={() => {
                                        if (!partnerName?.trim().length) {
                                            alert('name is required')
                                            return
                                        }
                                        addPartner()
                                    }}
                                >
                                    {
                                        isLoadingAddingPartner
                                            ? 'Adding...'
                                            : 'Add Partner'
                                    }
                                </button>
                            </div>
                        </Modal>


                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default ContentPartnersPage
