import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useQuery } from "react-query";
import {
    Alert,
    Badge,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    Label,
    Modal,
    Row,
    Table,
} from "reactstrap";
import { RestaurantAccountDto } from "src/helpers/att-api-dtos/restaurant-account/restaurant-account.dto";
import { AttAdminApi, getLoggedInUser } from "src/helpers/att_api_helper";
import { CountryDto } from "src/helpers/att-api-dtos/country/country.dto";
import { CountryService } from "src/services/country/country.service";

// FilePond:
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { API_URL } from "src/helpers/api_helper";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

interface RestaurantAccountsPageProps {
    history: any;
}

const RestaurantAccountsPage = ({ history }: RestaurantAccountsPageProps) => {

    const [files, setFiles] = useState<any[]>([])

    const [countries, setCountries] = useState<CountryDto[]>(CountryService.defaultCountries)
    const [country, setCountry] = useState<CountryDto>(CountryService.defaultCountries[0])

    const [isLoadingAddingAccount, setIsLoadingAddingAccount] = useState<boolean>(false)
    const [addingAccountError, setAddingAccountError] = useState<string>('')

    const [isAddingAccount, setIsAddingAccount] = useState<boolean>(false)

    const [newAccountFirstName, setNewAccountFirstName] = useState<string>('')
    const [newAccountLastName, setNewAccountLastName] = useState<string>('')
    const [newAccountBusinessName, setNewAccountBusinessName] = useState<string>('')
    const [newAccountWebsite, setNewAccountWebsite] = useState<string>('')
    const [newAccountEmail, setNewAccountEmail] = useState<string>('')
    const [newAccountLogoUrl, setNewAccountLogoUrl] = useState<string>('')

    const [pageSize] = useState<number>(20)
    const [query, setQuery] = useState<string>('')

    const handleFetchCountries = async () => {
        const countries = await AttAdminApi.countries.list()
        setCountries(countries)
        if (countries.length) {
            setCountry(countries[0])
        }
    }

    useEffect(() => {
        handleFetchCountries()
    }, []);

    const clearNewAccountForm = () => {
        setNewAccountFirstName('')
        setNewAccountLastName('')
        setNewAccountBusinessName('')
        setNewAccountWebsite('')
        setNewAccountEmail('')
        setNewAccountLogoUrl('')
    }

    const addAccount = async () => {
        setIsLoadingAddingAccount(true)

        try {
            const response = await AttAdminApi.restaurantAccounts.create({
                businessName: newAccountBusinessName,
                email: newAccountEmail,
                firstName: newAccountFirstName,
                lastName: newAccountLastName,
                country: country.code,
                website: newAccountWebsite,
                logoUrl: newAccountLogoUrl.length ? newAccountLogoUrl : undefined,
            })

            if (response) {
                clearNewAccountForm()
                setIsAddingAccount(false)
                fetchRestaurantAccounts()
            }
        } catch (error: any) {
            setAddingAccountError(error.message || 'An error occurred')
        }
        setIsLoadingAddingAccount(false)
    }

    const {
        data: restaurantAccountsData,
        error: restaurantAccountsError,
        isLoading: isLoadingRestaurantAccounts,
        refetch: fetchRestaurantAccounts,
    } = useQuery<
        { restaurantAccounts: RestaurantAccountDto[] },
        Error
    >({
        queryKey: [`restaurant-accounts`, query],
        queryFn: ({ pageParam }) => AttAdminApi.restaurantAccounts.list({
            offset: pageParam as number,
            query,
        }),
        refetchOnWindowFocus: false,
        enabled: true,
        getNextPageParam: (lastPage, pages) => lastPage.restaurantAccounts.length
            ? (pages.length * pageSize) + lastPage.restaurantAccounts.length
            : 0,
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Restaurant Accounts | ATT</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Home" breadcrumbItem="Restaurant Accounts" />
                    <div style={{
                        margin: 20,
                    }}>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col md={8}>
                                        <div>
                                            <Input
                                                className="form-control"
                                                type="search"
                                                placeholder="Search for an account"
                                                id="query-search-input"
                                                value={query}
                                                onChange={(e) => setQuery(e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-flex justify-content-end">
                                            <button
                                                onClick={() => setIsAddingAccount(true)}
                                                className="btn btn-dark btn-sm"
                                            >
                                                Add Account
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="px-0">
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Account</th>
                                                <th>Venues</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                restaurantAccountsError && (<Alert color="danger">
                                                    {restaurantAccountsError}
                                                    <div>
                                                        <a
                                                            href="#!"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                fetchRestaurantAccounts();
                                                            }}
                                                            className="text-muted"
                                                        >
                                                            Try again
                                                        </a>

                                                    </div>
                                                </Alert>)
                                            }
                                            {
                                                isLoadingRestaurantAccounts && (<tr><td colSpan={4}>Loading...</td></tr>)
                                            }
                                            {
                                                restaurantAccountsData?.restaurantAccounts.map((restaurantAccount) => {
                                                    return (
                                                        <tr key={restaurantAccount.id} onClick={async () =>
                                                            window.location.assign(`/restaurant-accounts/${restaurantAccount.id}`)
                                                        }>
                                                            <td>
                                                                <b>
                                                                    {restaurantAccount.businessName}
                                                                </b>
                                                                <div className="card-title-desc">
                                                                    {restaurantAccount.createdAt}
                                                                </div>
                                                                <div>
                                                                    {restaurantAccount.users.map((accountUser) => (
                                                                        <Badge pill className="badge-soft-info me-1">
                                                                            {accountUser.firstName} {accountUser.lastName} ({accountUser.email})
                                                                        </Badge>
                                                                    ))}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {
                                                                    restaurantAccount.venues.length === 0 &&
                                                                    <Badge pill className="badge rounded-pill badge-soft-danger">
                                                                        No venues linked
                                                                    </Badge>
                                                                }
                                                                {
                                                                    restaurantAccount.venues.map(
                                                                        (venue) => <Badge pill className="badge rounded-pill badge-soft-success">
                                                                            {venue.name} {
                                                                                venue.bookingProviders.map((p) => <span>
                                                                                    ({p.providerType}) &nbsp;
                                                                                </span>)
                                                                            }
                                                                        </Badge>
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    restaurantAccount.paymentMethods.length === 0
                                                                        ? <Badge pill className="badge rounded-pill badge-soft-danger">
                                                                            No payment methods
                                                                        </Badge>
                                                                        : <Badge pill className="badge rounded-pill badge-soft-success">
                                                                            Billing setup
                                                                        </Badge>
                                                                }
                                                            </td>
                                                            <td>
                                                                <button onClick={() => {
                                                                    history.push(`/restaurant-accounts/${restaurantAccount.id}`)
                                                                }} className="btn btn-dark btn-sm">View</button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </Container>

                <Modal
                    size="md"
                    isOpen={isAddingAccount}
                    toggle={() => {
                        clearNewAccountForm()
                        setIsAddingAccount(false)
                    }}
                    scrollable={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Add New Restaurant Account</h5>
                        <button
                            type="button"
                            onClick={() => {
                                clearNewAccountForm()
                                setIsAddingAccount(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Cancel"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <Card>
                            <CardBody>

                                <div className="mb-3">
                                    <Label className="form-Label">Country</Label>
                                    <select
                                        className="form-select"
                                        onChange={e => countries && setCountry(countries[parseInt(e.target.value)])}
                                    >
                                        {countries && countries.map(
                                            (country, i) => <option
                                                key={country.code}
                                                value={i}>{country.flag} {country.name}
                                            </option>
                                        )}
                                    </select>
                                </div>

                                <div className="row gx-3 gy-2 align-items-center mt-2">
                                    <Col sm={12}>
                                        <Label className="form-label" htmlFor="formrow-firstname-input">
                                            Account/Business Name
                                        </Label>
                                        <Input
                                            required
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-input"
                                            value={newAccountBusinessName}
                                            onChange={(e) => setNewAccountBusinessName(e.target.value)}
                                        />
                                    </Col>
                                </div>

                                <div className="row gx-3 gy-2 align-items-center mt-2">
                                    <Col sm={12}>
                                        <Label className="form-label" htmlFor="formrow-firstname-input">
                                            Website
                                        </Label>
                                        <Input
                                            required
                                            type="url"
                                            className="form-control"
                                            id="formrow-firstname-input"
                                            value={newAccountWebsite}
                                            onChange={(e) => setNewAccountWebsite(e.target.value)}
                                        />
                                    </Col>
                                </div>

                                <div className="row gx-3 gy-2 align-items-center">
                                    <Col sm={6}>
                                        <Label className="form-label" htmlFor="formrow-firstname-input">
                                            First Name
                                        </Label>
                                        <Input
                                            required
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-input"
                                            value={newAccountFirstName}
                                            onChange={(e) => setNewAccountFirstName(e.target.value)}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Label className="form-label" htmlFor="formrow-lastname-input">
                                            Last Name
                                        </Label>
                                        <Input
                                            required
                                            type="text"
                                            className="form-control"
                                            id="formrow-lastname-input"
                                            value={newAccountLastName}
                                            onChange={(e) => setNewAccountLastName(e.target.value)}
                                        />
                                    </Col>
                                </div>

                                <Col sm={12} className="mt-2">
                                    <Label className="form-label" htmlFor="formrow-email-input">
                                        Email (Required)
                                    </Label>
                                    <Input
                                        required
                                        type="email"
                                        className="form-control"
                                        id="formrow-email-input"
                                        value={newAccountEmail}
                                        onChange={(e) => setNewAccountEmail(e.target.value)}
                                    />
                                </Col>

                                <Col sm={12} className="mt-2">

                                    {
                                        Boolean(newAccountLogoUrl?.length) ? <Col lg={12}>
                                            <img
                                                className="img-thumbnail offer-image-preview"
                                                alt={'Thumbnail'}
                                                src={newAccountLogoUrl}
                                                data-holder-rendered="true"
                                            />
                                        </Col>
                                            : <FilePond
                                                files={files}
                                                onupdatefiles={setFiles}
                                                onprocessfile={(error, file) => {
                                                    if (error) {
                                                        return
                                                    }
                                                    const uploadResponse: { url: string } = JSON.parse(file.serverId)
                                                    setNewAccountLogoUrl(uploadResponse.url)
                                                }}
                                                credits={false}
                                                allowMultiple={false}
                                                maxFiles={1}
                                                server={{
                                                    url: `${API_URL}admin/files/thumbnail`,
                                                    headers: {
                                                        'x-support-jwt-auth': `${getLoggedInUser()?.auth?.accessToken}`,
                                                    }
                                                }}
                                                name="file"
                                                labelIdle=''
                                            />
                                    }
                                </Col>

                            </CardBody>
                        </Card>
                    </div>
                    <div className="modal-footer">
                        {
                            addingAccountError && (
                                <Alert color="danger">
                                    {addingAccountError}
                                </Alert>
                            )
                        }
                        <button
                            disabled={isAddingAccount}
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                                clearNewAccountForm()
                                setIsAddingAccount(false)
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            disabled={isLoadingAddingAccount}
                            type="button"
                            className="btn btn-dark"
                            onClick={() => {
                                if (!newAccountBusinessName.trim().length) {
                                    alert('Account name is required')
                                    return
                                }
                                if (!newAccountEmail.trim().length) {
                                    alert('Account email is required')
                                    return
                                }
                                addAccount()
                            }}
                        >
                            {
                                isLoadingAddingAccount
                                    ? 'Loading'
                                    : 'Add Account'
                            }
                        </button>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default RestaurantAccountsPage
