import PriceDto from "./att-api-dtos/types/price.dto"
import getSymbolFromCurrency from 'currency-symbol-map'
import { useEffect, useRef } from "react"
import { v4 as uuidv4 } from 'uuid';
import VenueDto from "./att-api-dtos/venue/venue.dto.interface";

const isValidDate = (date: any) => {
  return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

const generateUUID = () => uuidv4()

const getDeviceId = (): string => {
  const deviceId: string | null = localStorage.getItem("deviceId")
  if (!deviceId) {
    const dId = uuidv4()
    localStorage.setItem("deviceId", dId)
    return dId
  }
  return deviceId
}

const formatVenueAddress = (venue: VenueDto): string => {
  let fullAddressString = "";

  if (venue.location.address.line1) {
    fullAddressString = `${venue.location.address.line1}`;
  }
  if (venue.location.address.line2) {
    fullAddressString = fullAddressString.length
      ? `${fullAddressString}, ${venue.location.address.line2}`
      : venue.location.address.line2;
  }
  if (venue.location.address.street) {
    fullAddressString = fullAddressString.length
      ? `${fullAddressString}, ${venue.location.address.street}`
      : venue.location.address.street;
  }
  if (venue.location.address.town) {
    fullAddressString = fullAddressString.length
      ? `${fullAddressString}, ${venue.location.address.town}`
      : venue.location.address.town;
  }
  if (venue.location.address.postCode) {
    fullAddressString = fullAddressString.length
      ? `${fullAddressString}, ${venue.location.address.postCode}`
      : venue.location.address.postCode;
  }
  if (venue.location.address.countryCode) {
    fullAddressString = fullAddressString.length
      ? `${fullAddressString}, ${venue.location.address.countryCode}`
      : venue.location.address.countryCode;
  }

  return fullAddressString;
};

const priceFormat = (price: PriceDto) => {
  const currency = currencyFormat(price.units)
  const symbol = getSymbolFromCurrency(price.currencyCode.toLocaleUpperCase())
  return symbol + currency
}

const currencyFormat = (units: number) => {
  return (units / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const usePollingEffect = (
  asyncCallback: any,
  dependencies = [],
  {
    interval = 10_000, // 10 seconds,
    onCleanUp = () => { }
  } = {},
) => {
  const timeoutIdRef: any = useRef(null)
  useEffect(() => {
    let _stopped = false
      // Side note: preceding semicolon needed for IIFEs.
      ; (async function pollingCallback() {
        try {
          await asyncCallback()
        } finally {
          // Set timeout after it finished, unless stopped
          timeoutIdRef.current = !_stopped && setTimeout(
            pollingCallback,
            interval
          )
        }
      })()
    // Clean up if dependencies change
    return () => {
      _stopped = true // prevent racing conditions
      clearTimeout(timeoutIdRef.current)
      onCleanUp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, interval])
}

export {
  currencyFormat,
  priceFormat,
  usePollingEffect,
  generateUUID,
  getDeviceId,
  isValidDate,
  formatVenueAddress,
}
