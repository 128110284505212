import React, { useState } from "react";
import { RestaurantAccountDto } from "src/helpers/att-api-dtos/restaurant-account/restaurant-account.dto";
import { useQuery } from "react-query";
import { AttAdminApi } from "src/helpers/att_api_helper";
import { Col, Input, Label } from "reactstrap";

const RestaurantAccountDetails = ({ restaurantAccount }: { restaurantAccount: RestaurantAccountDto }) => {

    const [businessName, setBusinessName] = useState<string>(restaurantAccount.businessName)

    const {
        error: updateRestaurantAccountError,
        isLoading: isUpdatingRestaurantAccount,
        refetch: updateRestaurantAccount,
    } = useQuery<
        void,
        Error
    >({
        queryKey: [`update-restaurant-account`, restaurantAccount.id],
        queryFn: ({ pageParam }) => AttAdminApi.restaurantAccounts.updateDetails(
            restaurantAccount.id, {
            businessName: restaurantAccount.businessName,
        },
        ),
        refetchOnWindowFocus: false,
        enabled: false,
    });

    return (
        <React.Fragment>
            {
                updateRestaurantAccountError && (
                    <div className="alert alert-danger" role="alert">
                        {updateRestaurantAccountError}
                        <div>
                            <a
                                href="#!"
                                onClick={(e) => {
                                    e.preventDefault();
                                    updateRestaurantAccount();
                                }}
                                className="text-muted"
                            >
                                Try again
                            </a>
                        </div>
                    </div>
                )
            }

            <form className="row gx-3 gy-2 align-items-center">
                <Label htmlFor="specificSizeInputName">
                    Business Name
                </Label>
                <Col sm={10}>
                    <Input
                        type="text"
                        className="form-control"
                        id="specificSizeInputName"
                        placeholder="Enter Name"
                        onChange={(e: any) => {
                            setBusinessName(e.target.value)
                        }}
                        value={businessName}
                        required
                    />
                </Col>
                <Col sm={2}>
                    <button
                        disabled={isUpdatingRestaurantAccount}
                        className="btn btn-light"
                        type="button"
                    >
                        {
                            isUpdatingRestaurantAccount
                                ? 'Saving...'
                                : 'Update'
                        }
                    </button>
                </Col>
            </form>
        </React.Fragment>
    );
};

export default RestaurantAccountDetails;
