import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Container, Row, Table } from "reactstrap";
import { AttAdminApi } from "../../helpers/att_api_helper";
import CuisineFilterDto from "../../helpers/att-api-dtos/cuisines/cuisine-filter.dto.interface";
import { useDispatch } from "react-redux";

const ManageCuisinesPage = () => {
  const dispatch = useDispatch();

  const [cuisineFilters, setCuisineFilters] = useState<{
    cuisineFilters: CuisineFilterDto[];
  }>({ cuisineFilters: [] });

  const downloadCuisineFilters = async () => {
    setCuisineFilters(await AttAdminApi.cuisines.cuisineFilters.list());
  };

  useEffect(() => {
    downloadCuisineFilters();
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cuisine Filters | ATT</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Cuisine Filters" />
          <Row>
            <Card>
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Cuisine Filters</h4>
              </div>
              <CardBody className="px-0">
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Cuisine Filter</th>
                        <th>Mapped Cuisines</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cuisineFilters.cuisineFilters.map((cuisineFilter) => {
                        return (
                          <tr key={cuisineFilter.id}>
                            <th scope="row">{cuisineFilter.name}</th>
                            <td>
                                <ul>
                                {
                                    cuisineFilter.mappedCuisines.map((mappedCuisine) => {
                                        return <li  key={`${cuisineFilter.id}-${mappedCuisine.id}`}>{mappedCuisine.name}</li>
                                    })
                                }
                                </ul>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageCuisinesPage;
