import { CountryDto } from "src/helpers/att-api-dtos/country/country.dto";

export class CountryService {

    static defaultCountries: CountryDto[] = [{
        name: 'United Kingdom',
        flag: '🇬🇧',
        code: 'GB',
        defaultCurrency: {
          name: 'British Pound Sterling',
          code: 'GBP',
          symbol: '£',
        },
      },
      {
        name: 'United States',
        flag: '🇺🇸',
        code: 'US',
        defaultCurrency: {
          name: 'United State Dollar',
          code: 'USD',
          symbol: '$',
        },
      }]
}
