import React, { useEffect, useRef, useState } from "react";
import { registerPlugin } from 'react-filepond'
import MetaTags from "react-meta-tags";
import { useQuery } from "react-query";
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    Row,
    Table,
    Label,
    Modal,
    Badge,
    Button,
    Form,
} from "reactstrap";
import { AttAdminApi } from "src/helpers/att_api_helper";
import { GeoPoint } from "src/helpers/att-api-dtos/availability/geo-rect.dto.interface";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { LinkedVenuePartnerLeadDto } from "src/helpers/att-api-dtos/venue/linked-venue-partner-lead.dto";
import { VenueLeadDto } from "src/helpers/att-api-dtos/venue/venue-lead.dto";
import { formatVenueAddress } from "src/helpers/utils";
import VenueDto from "src/helpers/att-api-dtos/venue/venue.dto.interface";

const libraries: any[] = ["places"]

interface VenueLeadsPageProps {
    history: any;
}

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const VenueLeadsPage = ({ history }: VenueLeadsPageProps) => {

    // Google place search:
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyDgohtZ966jJlToDhVXQlJ_egGA7gvEkXc",
        libraries,
    });

    // Google place location
    const placeSearchRef = useRef<Input | null>(null);
    const [searchResult, setSearchResult] =
        useState<google.maps.places.Autocomplete | null>(null);
    const [location, setLocation] = useState<GeoPoint | undefined>(undefined);

    // State used to control the modal for linking/connecting a venue:
    const [isAddingVenue, setAddingVenue] = useState(false);
    const [addingVenueError, setAddingVenueError] = useState<string | undefined>();

    const [venueSearchQuery, setVenueSearchQuery] = useState<string>('');
    const [isLoadingAddVenue, setIsLoadingAddVenue] = useState(false);

    const [isAddingLead, setIsAddingLead] = useState<boolean>(false)
    const [venueLeadToAdd, setVenueLeadToAdd] = useState<string | undefined>()
    const [pageSize] = useState<number>(20)

    const {
        data: priorityLeadsData,
        error: priorityLeadsError,
        isLoading: isLoadingPriorityLeads,
        refetch: fetchPriorityLeads,
    } = useQuery<
        { leads: VenueLeadDto[] },
        Error
    >({
        queryKey: [`priority-leads`],
        queryFn: ({ pageParam }) => AttAdminApi.leads.list(pageParam),
        refetchOnWindowFocus: false,
        enabled: true,
        getNextPageParam: (lastPage, pages) => lastPage.leads.length
            ? (pages.length * pageSize) + lastPage.leads.length
            : 0,
    });

    const {
        data: linkedVenuesData,
        error: linkedVenuesError,
        isLoading: isLoadingLinkedVenues,
        refetch: fetchLinkedVenues,
    } = useQuery<
        { linkedVenueLeads: LinkedVenuePartnerLeadDto[] },
        Error
    >({
        queryKey: [`linked-venue-leads`],
        queryFn: ({ pageParam }) => AttAdminApi.leads.partnerLinked.list(pageParam),
        refetchOnWindowFocus: false,
        enabled: true,
        getNextPageParam: (lastPage, pages) => lastPage.linkedVenueLeads.length
            ? (pages.length * pageSize) + lastPage.linkedVenueLeads.length
            : 0,
    });

    const {
        data: createLeadData,
        error: createLeadError,
        isLoading: isLoadingCreateLead,
        refetch: createNewLead,
    } = useQuery<
        VenueLeadDto,
        Error
    >({
        queryKey: [`create-leads`],
        queryFn: () => AttAdminApi.leads.createLead(venueLeadToAdd!),
        refetchOnWindowFocus: false,
        enabled: false,
    });

    useEffect(() => {
        if (createLeadData) {
            fetchLinkedVenues()
            fetchPriorityLeads()
            setIsAddingLead(false)
        }
    }, [createLeadData, fetchLinkedVenues, fetchPriorityLeads])

     // Query for finding venues:
     const {
        data: venueListData,
        error: venueListError,
        isLoading: isLoadingVenueList,
    } = useQuery<
        {
            venues: VenueDto[];
            offsetVenueId?: string;
            limit: number;
        },
        Error
    >({
        queryKey: [`list-venues`, venueSearchQuery, location],
        queryFn: () => AttAdminApi.venues.list({
            location,
            query: venueSearchQuery,
        }),
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Leads | ATT</title>
                </MetaTags>
                <Container fluid>
                    <div style={{
                        margin: 20,
                    }}>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xl={6}>
                                        <h4>
                                            Sales Leads
                                        </h4>
                                    </Col>
                                    <Col xl={6}>
                                    <div className="d-flex justify-content-end">
                                        <Button className="btn btn-sm btn-dark m-2" onClick={() => {
                                            setAddingVenue(true)
                                        }}>
                                            Add Sales Lead
                                        </Button>

                                        <Button className="btn btn-sm btn-dark m-2" onClick={() => {
                                            window.open('https://app-eu1.hubspot.com/contacts/144932276/objects/0-3/views/all/board')
                                        }}>
                                            View HubSpot Funnel
                                        </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="px-0">
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Venue</th>
                                                <th>Content partner(s)</th>
                                                <th>Booking Provider</th>
                                                <th>Linked Restaurant Accounts</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                priorityLeadsError && (<Alert color="danger">
                                                    {priorityLeadsError}
                                                    <div>
                                                        <a
                                                            href="#!"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                fetchPriorityLeads();
                                                            }}
                                                            className="text-muted"
                                                        >
                                                            Try again
                                                        </a>
                                                    </div>
                                                </Alert>)
                                            }
                                            {
                                                isLoadingPriorityLeads && (<tr><td colSpan={4}>Loading...</td></tr>)
                                            }
                                            {
                                                priorityLeadsData?.leads.map((priorityLead) => {
                                                    return (
                                                        <tr key={`lead-${priorityLead.venue.id}`}>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="flex-shrink-0 me-3">
                                                                        {
                                                                            priorityLead.venue.images?.length > 0 ? (
                                                                                <img
                                                                                    className="rounded avatar-md"
                                                                                    data-holder-rendered="true"
                                                                                    src={priorityLead.venue.images[0].url}
                                                                                    alt=""
                                                                                />
                                                                            ) : <img
                                                                                className="rounded avatar-md"
                                                                                data-holder-rendered="true"
                                                                                src={'https://allthetables.s3.eu-west-2.amazonaws.com/restaurant_placeholder_grey.jpeg'}
                                                                                alt=""
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div className="flex-grow-1" style={{ maxWidth: '300px' }}>
                                                                        <h5>{priorityLead.venue.name}</h5>
                                                                        <div className="mb-0">
                                                                            {priorityLead.venue.cuisineFilters.map((cuisine) => (
                                                                                <Badge key={`lead-cuisine-${priorityLead.venue.id}-${cuisine.id}`} pill className="badge-soft-success me-1 mb-0">
                                                                                    {cuisine.name}
                                                                                </Badge>
                                                                            ))}
                                                                        </div>
                                                                        <div className="mt-0"> {formatVenueAddress(priorityLead.venue)} </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {
                                                                    priorityLead.partners?.length === 0 && <Badge pill className="badge-soft-danger me-1">
                                                                        No partners
                                                                    </Badge>
                                                                }
                                                                {
                                                                    priorityLead.partners?.map((partner) => (
                                                                        <Badge key={`lead-partner-${priorityLead.venue.id}-${partner.id}`} pill className="badge-soft-info me-1">
                                                                            {partner.name}
                                                                        </Badge>
                                                                    ))
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    priorityLead.venue.bookingProviders.length === 0 && <Badge pill className="badge-soft-danger me-1">
                                                                        No booking providers
                                                                    </Badge>
                                                                }
                                                                {
                                                                    priorityLead.venue.bookingProviders.map((provider) => (
                                                                        <Badge pill key={`lead-provider-${priorityLead.venue.id}-${provider.providerId}`} className="badge-soft-info me-1">
                                                                            {provider.providerType}
                                                                        </Badge>
                                                                    ))
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    priorityLead.restaurantAccounts?.length === 0 && <Badge className="badge-soft-danger me-1">
                                                                        None linked
                                                                    </Badge>
                                                                }
                                                                {priorityLead.restaurantAccounts.map((restaurantAccount) => <Badge id={`${priorityLead.id}-badge-${restaurantAccount.id}`} className="me-2 bg-success">
                                                                    {restaurantAccount.name}
                                                                </Badge>)}
                                                            </td>
                                                            <td>
                                                                {
                                                                    priorityLead.hubSpotDealId ? <button
                                                                        onClick={() => {
                                                                        }}
                                                                        className="btn btn-sm btn-outline-dark m-2"
                                                                    >
                                                                        View in Hubspot
                                                                    </button>
                                                                        : <button
                                                                            onClick={() => {
                                                                            }}
                                                                            className="btn btn-sm btn-outline-primary m-2"
                                                                        >
                                                                            Create in Hubspot
                                                                        </button>
                                                                }

                                                                <button
                                                                    onClick={() => {
                                                                        window.location.assign(`/venue-directory/${priorityLead.venue.id}`)
                                                                    }}
                                                                    className="btn btn-sm btn-outline-dark m-2"
                                                                >
                                                                    Manage Venue
                                                                </button>

                                                                <button
                                                                    onClick={() => {
                                                                        window.location.assign(`/priority-leads/${priorityLead.id}`)
                                                                    }}
                                                                    className="btn btn-sm btn-outline-dark m-2"
                                                                >
                                                                    View Lead
                                                                </button>



                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>

                        </Card>
                    </div>
                    <div style={{
                        margin: 20,
                    }}>
                        <Card>
                            <CardHeader>
                                <h4>Venues linked to content partner</h4>
                                <p>
                                    Venues linked to content partner that have not yet signed up to AllTheTables. This venues should be prioritized for sales outreach as we can send them bookings from our content partner.
                                </p>
                            </CardHeader>
                            <CardBody className="px-0">
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Venue</th>
                                                <th># Content Partners</th>
                                                <th>Linked restaurant account</th>
                                                <th>Booking Provider</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                linkedVenuesError && (<Alert color="danger">
                                                    {linkedVenuesError}
                                                    <div>
                                                        <a
                                                            href="#!"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                fetchLinkedVenues();
                                                            }}
                                                            className="text-muted"
                                                        >
                                                            Try again
                                                        </a>
                                                    </div>
                                                </Alert>)
                                            }
                                            {
                                                isLoadingLinkedVenues && (<tr><td colSpan={4}>Loading...</td></tr>)
                                            }
                                            {
                                                linkedVenuesData?.linkedVenueLeads.map((venueLead) => {
                                                    return (
                                                        <tr key={`linked-venue-${venueLead.venueId}`}>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="flex-shrink-0 me-3">
                                                                        {
                                                                            venueLead.venue.images?.length > 0 ? (
                                                                                <img
                                                                                    className="rounded avatar-md"
                                                                                    data-holder-rendered="true"
                                                                                    src={venueLead.venue.images[0].url}
                                                                                    alt=""
                                                                                />
                                                                            ) : <img
                                                                                className="rounded avatar-md"
                                                                                data-holder-rendered="true"
                                                                                src={'https://allthetables.s3.eu-west-2.amazonaws.com/restaurant_placeholder_grey.jpeg'}
                                                                                alt=""
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div className="flex-grow-1" style={{ maxWidth: '300px' }}>
                                                                        <h5>{venueLead.venue.name}</h5>
                                                                        <div className="mb-0">
                                                                            {venueLead.venue.cuisineFilters?.map((cuisine) => (
                                                                                <Badge pill key={`linked-venue-${venueLead.venueId}-cuisine-${cuisine.id}`} className="badge-soft-success me-1 mb-0">
                                                                                    {cuisine.name}
                                                                                </Badge>
                                                                            ))}
                                                                        </div>
                                                                        <div className="mt-0"> {formatVenueAddress(venueLead.venue)} </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h4>{venueLead.contentPartnerCount}</h4>
                                                            </td>
                                                            <td>
                                                                {
                                                                    venueLead.restaurantAccountCount > 0
                                                                        ? (
                                                                            <Badge className="me-2 bg-success">Linked - Billing needs setup</Badge>
                                                                        )
                                                                        : (
                                                                            <Badge className="badge-soft-danger me-1">No account</Badge>
                                                                        )
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    venueLead.venue.bookingProviders.length === 0 && <Badge pill className="badge-soft-danger me-1">
                                                                        No booking providers
                                                                    </Badge>
                                                                }
                                                                {
                                                                    venueLead.venue.bookingProviders.map((provider) => (
                                                                        <Badge key={`linked-venue-${venueLead.venueId}-provider-${provider.providerId}`} pill className="badge-soft-info me-1">
                                                                            {provider.providerType}
                                                                        </Badge>
                                                                    ))
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    venueLead.leadId
                                                                        ? <button
                                                                            onClick={() => {
                                                                                // TODO
                                                                            }}
                                                                            className="btn btn-sm btn-outline-dark"
                                                                        >
                                                                            View lead
                                                                        </button>
                                                                        : <button
                                                                            onClick={() => {
                                                                                setVenueLeadToAdd(venueLead.venueId)
                                                                                setIsAddingLead(true)
                                                                            }}
                                                                            className="btn btn-sm btn-dark"
                                                                        >
                                                                            Create lead
                                                                        </button>
                                                                }


                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>

                        <Modal
                            size="md"
                            isOpen={isAddingLead}
                            toggle={() => {
                                setIsAddingLead(false)
                            }}
                            scrollable={true}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Add Lead</h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsAddingLead(false)
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Cancel"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <Card>
                                    <CardBody>
                                        <div className="row gx-3 gy-2 align-items-center">
                                            Would like to mark this linked venue as a sales lead?
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="modal-footer">

                                {
                                    createLeadError && (
                                        <div className="alert alert-danger" role="alert">
                                            {createLeadError}
                                        </div>
                                    )
                                }
                                <button
                                    disabled={isLoadingCreateLead}
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        setIsAddingLead(false)
                                    }}
                                >
                                    Cancel
                                </button>

                                <button
                                    disabled={isLoadingCreateLead}
                                    type="button"
                                    className="btn btn-dark"
                                    onClick={() => {
                                        if (!venueLeadToAdd) {
                                            alert('Please select a venue to add')
                                            return
                                        }
                                        createNewLead()
                                    }}
                                >
                                    {
                                        isLoadingCreateLead
                                            ? 'Adding...'
                                            : 'Add Lead'
                                    }
                                </button>
                            </div>
                        </Modal>
                    </div>

                    <Modal
                        size="xl"
                        isOpen={isAddingVenue}
                        toggle={() => {
                            setAddingVenue(false);
                        }}
                        scrollable={true}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">Add a sales lead</h5>
                            <button
                                type="button"
                                onClick={() => setAddingVenue(false)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Cancel"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">

                            <Card>
                                <CardHeader>
                                    <Form>
                                        <Row>
                                            <Col md={6}>
                                                <div>
                                                    <Label
                                                        htmlFor="query-search-input"
                                                        className="form-Label"
                                                    >
                                                        Search
                                                    </Label>
                                                    <Input
                                                        className="form-control"
                                                        type="search"
                                                        placeholder="Enter a venue name"
                                                        id="query-search-input"
                                                        value={venueSearchQuery}
                                                        onChange={(e) => setVenueSearchQuery(e.target.value)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div>
                                                    <Label
                                                        htmlFor="example-search-input"
                                                        className="form-Label"
                                                    >
                                                        Location
                                                    </Label>
                                                    {isLoaded ? (
                                                        <Autocomplete
                                                            onPlaceChanged={() => {
                                                                if (!searchResult) {
                                                                    return;
                                                                }
                                                                const placeLocation =
                                                                    searchResult.getPlace()?.geometry?.location;

                                                                if (placeLocation) {
                                                                    setLocation({
                                                                        latitude: parseFloat(placeLocation?.lat().toFixed(8)),
                                                                        longitude: parseFloat(placeLocation?.lng().toFixed(8)),
                                                                    });
                                                                }
                                                            }}
                                                            onLoad={(autocomplete) =>
                                                                setSearchResult(autocomplete)
                                                            }
                                                        >
                                                            <Input
                                                                className="form-control"
                                                                type="search"
                                                                placeholder="Search for a location to narrow your search"
                                                                id="example-search-input"
                                                                ref={placeSearchRef}
                                                            />
                                                        </Autocomplete>
                                                    ) : (
                                                        <>Loading...</>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                    </Form>
                                </CardHeader>

                                <CardBody className="px-0">
                                    <div>
                                        <div>
                                            {addingVenueError ? (
                                                <Alert color="danger">{addingVenueError}</Alert>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <Table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Address</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    venueListError && (<Alert color="danger">{venueListError.message || 'An error occurred listing venues.'}</Alert>)
                                                }
                                                {
                                                    isLoadingVenueList && 'Loading...'
                                                }
                                                {venueListData?.venues.map((venue) => {
                                                    return (
                                                        <tr key={venue.id}>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="flex-shrink-0 me-3">
                                                                        {
                                                                            venue.images?.length > 0 ? (
                                                                                <img
                                                                                    className="rounded avatar-md"
                                                                                    data-holder-rendered="true"
                                                                                    src={venue.images[0].url}
                                                                                    alt=""
                                                                                />
                                                                            ) : <img
                                                                                className="rounded avatar-md"
                                                                                data-holder-rendered="true"
                                                                                src={'https://allthetables.s3.eu-west-2.amazonaws.com/restaurant_placeholder_grey.jpeg'}
                                                                                alt=""
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        <h5>{venue.name}</h5>
                                                                        <p className="mb-0">
                                                                            {venue.cuisineFilters.map((cuisine) => (
                                                                                <Badge pill className="badge-soft-success me-1">
                                                                                    {cuisine.name}
                                                                                </Badge>
                                                                            ))}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{formatVenueAddress(venue)}</td>
                                                            <td>
                                                                <Button
                                                                    disabled={isLoadingAddVenue}
                                                                    onClick={async (e) => {
                                                                        e.preventDefault();
                                                                        setIsLoadingAddVenue(true);
                                                                        setAddingVenueError(undefined)
                                                                        try {
                                                                            await AttAdminApi.leads.createLead(venue.id)
                                                                            setAddingVenue(false)
                                                                            fetchLinkedVenues()
                                                                            fetchPriorityLeads()
                                                                        } catch (e: any) {
                                                                            setAddingVenueError(e.message || e || 'An error occurred linking your venue. Please try again.')
                                                                        }
                                                                        setIsLoadingAddVenue(false);
                                                                    }}
                                                                    className="btn btn-dark"
                                                                >
                                                                    Select
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => setAddingVenue(false)}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default VenueLeadsPage
