import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Badge, Card, CardBody, Container, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import { AttAdminApi } from "../../helpers/att_api_helper";
import CrawlerDto from "../../helpers/att-api-dtos/admin/worker/crawler.dto.interface";
import CrawlerStatusType from "../../helpers/att-api-dtos/admin/worker/crawler-status-type.dto.interface";
import { usePollingEffect } from "../../helpers/utils";
import ReactTimeAgo from "react-time-ago";


const CrawlerStatusPage = () => {

  const [crawlerStatuses, setCrawlerStatuses] = useState<{
    crawlers: CrawlerDto[];
  }>({ crawlers: [] });

  usePollingEffect(
    async () => setCrawlerStatuses(await AttAdminApi.crawlers.status.list()),
    [],
    { interval: 3000 }
  )

  const renderStatusBadge = (status: CrawlerStatusType) =>
    status === "active" ? (
      <Badge className="me-2 bg-success">Active</Badge>
    ) : (
      <Badge className="me-2 bg-danger">Inactive</Badge>
    );

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Crawlers | ATT</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Crawlers" />
          <Row>
            <Card>
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  Status of Crawlers
                </h4>
              </div>
              <CardBody className="px-0">
                <SimpleBar className="table-responsive px-3">
                  <table className="table align-middle table-nowrap table-borderless">
                    <tbody>
                      {crawlerStatuses.crawlers.map((crawlerStatus) => {
                        return (
                          <tr key={crawlerStatus.id}>
                            <td style={{ width: "50px" }}>
                              <div
                                className={
                                  crawlerStatus.status === "active"
                                    ? "font-size-22 text-success"
                                    : "font-size-22 text-danger"
                                }
                              >
                                <i
                                  className={
                                    crawlerStatus.status === "active"
                                      ? "bx bx-check-circle d-block"
                                      : "bx bx-dizzy d-block"
                                  }
                                ></i>
                              </div>
                            </td>

                            <td>
                              <div>
                                <h5 className="font-size-14 mb-1">
                                  {crawlerStatus.name}
                                </h5>
                                <p className="text-muted mb-0 font-size-12">
                                  {renderStatusBadge(crawlerStatus.status)}
                                  {crawlerStatus.isRunning && (
                                    <Badge className="me-2 text-black bg-warning">
                                      Running...
                                    </Badge>
                                  )}
                                </p>
                              </div>
                            </td>

                            <td>
                              <div className="text-end">
                                <p className="text-muted mb-0 font-size-12">
                                  Last Started
                                </p>
                                <h5 className="font-size-14 mb-0">
                                  {crawlerStatus.lastStartedAt ? <ReactTimeAgo date={new Date(crawlerStatus.lastStartedAt)} locale="en-US" /> : "Never"}
                                </h5>
                              </div>
                            </td>

                            <td>
                              <div className="text-end">
                                <p className="text-muted mb-0 font-size-12">
                                  Last Activity
                                </p>
                                <h5 className="font-size-14 mb-0">
                                  {crawlerStatus.lastActivityAt ? <ReactTimeAgo date={new Date(crawlerStatus.lastActivityAt)} locale="en-US" /> : "Never"}
                                </h5>
                              </div>
                            </td>

                            <td>
                              <div className="text-end">
                                <p className="text-muted mb-0 font-size-12">
                                  Last completed
                                </p>
                                <h5 className="font-size-14 mb-0">
                                  {crawlerStatus.lastCompletedAt ? <ReactTimeAgo date={new Date(crawlerStatus.lastCompletedAt)} locale="en-US" /> : "Never"}
                                </h5>
                              </div>
                            </td>

                            <td>
                              {crawlerStatus.isRunning ? (
                                <button
                                  type="button"
                                  className="btn btn-soft-danger waves-effect waves-light"
                                >
                                  Cancel
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-soft-primary waves-effect waves-light"
                                  onClick={async () => await AttAdminApi.crawlers.start(crawlerStatus.id)}
                                >
                                  Start
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </SimpleBar>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CrawlerStatusPage;
