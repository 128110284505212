import React from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { userRoutes, authRoutes } from "./routes/allRoutes";
import { useSelector } from "react-redux";
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";

import TimeAgo from "javascript-time-ago";
import en from 'javascript-time-ago/locale/en.json'

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";

try {
  TimeAgo.addDefaultLocale(en);
} catch (e) {
  //console.error(e);
}

const App = () => {
  const { layoutType } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
  }));

  function getLayout() {
    let layoutCls: Object = VerticalLayout;
    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <React.Fragment>
      <GoogleOAuthProvider clientId="492973709339-vf50gtc4gq10kl53dn3bhhrke2jr2jp7.apps.googleusercontent.com">
        <Router>
          <Switch>
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {userRoutes.map((route: any, idx: number) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
      </GoogleOAuthProvider>
    </React.Fragment>
  );
};

export default App;
