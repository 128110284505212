import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import AdminUserAuthenticationDto from "../../../helpers/att-api-dtos/admin/auth/admin-user-authentication.dto.interface";

import { getUserProfile } from "../../../helpers/att_api_helper";

const ProfileTab = () => {
  const [userProfile] = useState<AdminUserAuthenticationDto | null>(getUserProfile())

  return (
    <React.Fragment>
      <Row>
        <Col xl={9} lg={8}>
          <Card>
            <CardBody>
              <Row>
                <div className="col-sm order-2 order-sm-1">
                  <div className="d-flex align-items-start mt-3 mt-sm-0">
                    <div className="flex-grow-1">
                      <div>
                        <h5 className="font-size-16 mb-1">{userProfile?.user.firstName} {userProfile?.user.lastName}</h5>
                        <p className="text-muted font-size-13">
                          {userProfile?.user.email}
                        </p>

                       
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-auto order-1 order-sm-2">
                  <div className="d-flex align-items-start justify-content-end gap-2">
                    <div>
                      <button type="button" className="btn btn-soft-light">
                        <i className="me-1"></i> Message
                      </button>
                    </div>
                    <div>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn btn-link font-size-16 shadow-none text-muted"
                          tag="a"
                        >
                          <i className="bx bx-dots-horizontal-rounded"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <li>
                            <DropdownItem to="#">Action</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem to="#">Another action</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem to="#">
                              Something else here
                            </DropdownItem>
                          </li>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </Row>


            </CardBody>
          </Card>
        </Col>

      </Row>
    </React.Fragment>
  );
};

export default ProfileTab;
