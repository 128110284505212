import React, { useEffect, useState } from "react";
import { RestaurantAccountDto } from "src/helpers/att-api-dtos/restaurant-account/restaurant-account.dto";
import { Label, Table, Badge } from "reactstrap";
import { PaymentMethodDto } from "src/helpers/att-api-dtos/payment-method/payment-method.dto";

const RestaurantAccountPaymentMethods = ({ restaurantAccount }: { restaurantAccount: RestaurantAccountDto }) => {

    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodDto[]>(restaurantAccount.paymentMethods)

    useEffect(() => {
        setPaymentMethods(restaurantAccount.paymentMethods)
    }, [restaurantAccount])

    return (<React.Fragment>

        <div className="table-responsive mt-4">
            <Label>
                Payment methods&nbsp;
            </Label>

            <Table>
                {
                    paymentMethods.length === 0 && <Badge pill className="badge-soft-danger me-1">
                            No payment methods setup
                        </Badge>
                }
                <tbody>
                    {paymentMethods.map((paymentMethod) => {
                        return (
                            <>
                                <tr key={paymentMethod.id}>
                                    <td>
                                        {
                                            paymentMethod.paymentMethodType === 'card'
                                                ? paymentMethod.brand?.toUpperCase() + ' ending in ' + paymentMethod.lastFour
                                                : 'Direct Debit with account ending in ' + paymentMethod.lastFour
                                        }
                                    </td>
                                    <td>
                                        {
                                            paymentMethod.paymentMethodType === 'card'
                                                ? paymentMethod.expMonth + '/' + paymentMethod.expYear
                                                : 'Sort code: ' + paymentMethod.sortCode
                                        }
                                    </td>
                                    <td>
                                        {
                                            paymentMethod.isDefault && <Badge pill className="badge-soft-primary me-1">
                                                Default payment method
                                            </Badge>
                                        }
                                    </td>
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    </React.Fragment>)
}

export default RestaurantAccountPaymentMethods;
