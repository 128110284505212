import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Alert, Badge, Card, CardBody, CardHeader, Container, Input, Label, Modal, Row } from "reactstrap";
import { AttAdminApi } from "../../helpers/att_api_helper";
import SimpleBar from "simplebar-react";
import { AdminCuratedListDto } from "src/helpers/att-api-dtos/curated-lists/admin-curated-list.interface.dto";
import { useInfiniteQuery } from "react-query";
import { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";

const ManageCuratedListsPage = () => {

    const pageSize = 20
    const { SearchBar } = Search;

    // Searching of offers
    const [searchQuery, setSearchQuery] = useState<string | undefined>()

    // If filtering published state
    const [publishedStateFilter, ] = useState<
        true | false | undefined
    >()

    // If creating a new payment request
    const [newCuratedList, setNewCuratedList] = useState<
        AdminCuratedListDto | undefined
    >();

    const [newCuratedListError, setNewCuratedListError] = useState<string | undefined>();
    const [iLoadingNewCuratedList, setIsLoadingNewCuratedList] = useState<boolean>(false);

    const {
        data: curatedListsData,
        error,
        isLoading,
        refetch: refetchCuratedLists,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery<
        {
            lists: AdminCuratedListDto[]
            offset?: number
        },
        Error
    >({
        queryKey: [
            `curated-lists`,
            publishedStateFilter,
            searchQuery,
        ],
        queryFn: ({ pageParam }) => AttAdminApi.curatedLists.list({
            offset: pageParam as any,
            query: searchQuery,
            published: publishedStateFilter,
            pageSize,
        }),
        getNextPageParam: (lastPage, pages) => (lastPage.offset || 0) + pageSize,
    });

    const renderPublishedStatusBadge = (publishedAt?: Date) =>
        Boolean(publishedAt) ? (
            <Badge className="me-2 bg-success">Published</Badge>
        ) : (
            <Badge className="me-2 bg-danger">Unpublished</Badge>
        );

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Curated Lists | ATT</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Home" breadcrumbItem="Manage Lists" />
                    <Row>
                        <Card>
                            <div className="card-header align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">Curated Lists</h4>
                            </div>

                            <CardHeader>
                                <div className="row align-ite  ms-center">
                                    <div className="col-md-10 align-items-left">
                                        <div className="search-box d-inline-block">
                                            <div className="position-relative">
                                                <SearchBar placeholder='Search lists' searchText={searchQuery} onSearch={(e) => {
                                                    console.log('e', e)
                                                    setSearchQuery(e)
                                                }} />
                                                <i className="bx bx-search-alt search-icon-search" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                            <div>
                                                <Link
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setNewCuratedList({
                                                            name: "",
                                                            description: "",
                                                        })
                                                    }}
                                                    to="#"
                                                    className="btn btn-primary"
                                                >
                                                    <i className="bx bx-plus me-1"></i> New List
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardHeader>

                            <CardBody className="px-0">
                                <SimpleBar className="table-responsive px-3">
                                    <table className="table align-middle table-nowrap table-borderless">
                                        <tbody>
                                            {curatedListsData?.pages.map((curatedListPage) => curatedListPage.lists?.map((curatedList) => {

                                                return (
                                                    <tr key={curatedList.id}>
                                                        <td style={{ width: "50px" }}>
                                                            <div
                                                                className={
                                                                    Boolean(curatedList.publishedAt)
                                                                        ? "font-size-22 text-success"
                                                                        : "font-size-22 text-danger"
                                                                }
                                                            >
                                                                <i
                                                                    className={
                                                                        Boolean(curatedList.publishedAt)
                                                                            ? "bx bx-check-circle d-block"
                                                                            : "bx bx-dizzy d-block"
                                                                    }
                                                                ></i>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div>
                                                                <h5 className="font-size-14 mb-1">
                                                                    {curatedList.name}
                                                                </h5>
                                                                <p className="text-muted mb-0 font-size-12">
                                                                    {renderPublishedStatusBadge(curatedList.publishedAt)}
                                                                </p>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className="text-end">
                                                                <p className="text-muted mb-0 font-size-12">
                                                                    # Venues
                                                                </p>
                                                                <h5 className="font-size-14 mb-0">
                                                                    {
                                                                        curatedList.venueCount || 0
                                                                    }
                                                                </h5>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-soft-primary waves-effect waves-light"
                                                                onClick={async () =>
                                                                    window.location.assign(`/manage-curated-lists/${curatedList.id}`)
                                                                }
                                                            >
                                                                Manage
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            }))
                                            }
                                        </tbody>
                                    </table>

                                    {
                                        error && (
                                            <Alert color="danger">
                                                {error.message || 'An error occurred.'}
                                            </Alert>
                                        )
                                    }
                                    {
                                        isLoading && 'Loading...'
                                    }
                                    {
                                        isFetchingNextPage && 'Loading more...'
                                    }
                                    {
                                        hasNextPage && (
                                            <button
                                                onClick={() => fetchNextPage()}
                                                disabled={isFetchingNextPage}
                                            >
                                                Load More
                                            </button>
                                        )
                                    }
                                </SimpleBar>
                            </CardBody>
                        </Card>
                    </Row>

                    <Modal
                        isOpen={Boolean(newCuratedList)}
                        toggle={() => {
                            setNewCuratedList(undefined);
                        }}
                        scrollable={true}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">New Curated List</h5>
                            <button
                                type="button"
                                onClick={() => setNewCuratedList(undefined)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Cancel"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div>
                                    {newCuratedListError ? (
                                        <Alert color="danger">{newCuratedListError}</Alert>
                                    ) : null}
                                </div>

                                <div>
                                    <div className="mb-3">
                                        <Label
                                            htmlFor="example-text-input"
                                            className="form-Label"
                                        >
                                            Name
                                        </Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            placeholder="Rooftop bars"
                                            onChange={(e) =>
                                                setNewCuratedList((old) => {
                                                    if (!old) return old;
                                                    return { ...old, name: e.target.value };
                                                })
                                            }
                                            defaultValue=""
                                            id="example-text-input"
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="example-text-input" className="form-Label">
                                        Description
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="textarea"
                                        placeholder="Description"
                                        onChange={(e) =>
                                            setNewCuratedList((old) => {
                                                if (!old) return old;
                                                return { ...old, description: e.target.value };
                                            })
                                        }
                                        defaultValue=""
                                        id="example-text-input"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setNewCuratedList(undefined)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    disabled={iLoadingNewCuratedList}
                                    className="btn btn-primary"
                                    onClick={async () => {

                                        setIsLoadingNewCuratedList(true)
                                        setNewCuratedListError(undefined)

                                        try {
                                            await AttAdminApi.curatedLists.create(newCuratedList!)
                                            setNewCuratedList(undefined)
                                            refetchCuratedLists()
                                        } catch (e: any) {
                                            setNewCuratedListError(e.message || e)
                                            return
                                        }

                                        setIsLoadingNewCuratedList(false)
                                    }}
                                >
                                    {iLoadingNewCuratedList ? 'Loading...' : 'Create Curated List'}
                                </button>
                            </div>
                        </div>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ManageCuratedListsPage;
