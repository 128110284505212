import React, { useEffect, useState, useRef } from "react";
import { RestaurantAccountDto } from "src/helpers/att-api-dtos/restaurant-account/restaurant-account.dto";
import { RestaurantAccountVenueDto } from "src/helpers/att-api-dtos/venue/restaurant-account-venue.dto";
import { useQuery } from "react-query";
import { Alert, Table } from "reactstrap";
import { Col, Input, Label, Badge, Modal, Card, CardBody, Button, CardHeader, Form, Row } from "reactstrap";
import { formatVenueAddress } from "src/helpers/utils";
import { AttAdminApi } from "src/helpers/att_api_helper";
import { GeoPoint } from "src/helpers/att-api-dtos/availability/geo-rect.dto.interface";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import VenueDto from "src/helpers/att-api-dtos/venue/venue.dto.interface";
import SweetAlert from "react-bootstrap-sweetalert";

const libraries: any[] = ["places"]

const RestaurantAccountVenues = ({
    restaurantAccount,
    venueAdded,
    venueRemoved,
    history,
}: {
    restaurantAccount: RestaurantAccountDto,
    venueAdded: (id: string) => void,
    venueRemoved: (id: string) => void,
    history: any;
}) => {

    const [venues, setVenue] = useState<RestaurantAccountVenueDto[]>(restaurantAccount.venues)

    useEffect(() => {
        setVenue(restaurantAccount.venues)
    }, [restaurantAccount.venues])

    // Google place search:
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyDgohtZ966jJlToDhVXQlJ_egGA7gvEkXc",
        libraries,
    });

    // Google place location
    const placeSearchRef = useRef<Input | null>(null);
    const [searchResult, setSearchResult] =
        useState<google.maps.places.Autocomplete | null>(null);
    const [location, setLocation] = useState<GeoPoint | undefined>(undefined);

    // State used to control the modal for linking/connecting a venue:
    const [isAddingVenue, setAddingVenue] = useState(false);
    const [addingVenueError, setAddingVenueError] = useState<string | undefined>();

    // Linking & Unlinking a venue:
    const [venueSearchQuery, setVenueSearchQuery] = useState<string>('');
    const [isLoadingAddVenue, setIsLoadingAddVenue] = useState(false);
    const [confirmUnlink, setConfirmUnlink] = useState<string | undefined>();
    const [isUnlinking, setIsUnlinking] = useState(false);
    const [unlinkingError, setUnlinkingError] = useState<string | undefined>();

    const {
        data: venueListData,
        error: venueListError,
        isLoading: isLoadingVenueList,
    } = useQuery<
        {
            venues: VenueDto[];
            offsetVenueId?: string;
            limit: number;
        },
        Error
    >({
        queryKey: [`list-venues`, venueSearchQuery, location],
        queryFn: () => AttAdminApi.venues.list({
            location,
            query: venueSearchQuery,
        }),
    });

    return (
        <React.Fragment>

            {
                <div className="table-responsive mt-4">
                    <Label>
                        Linked venues &nbsp;
                        <Badge
                            className="rounded-pill badge-soft-primary ms-1"
                            onClick={() => setAddingVenue(true)}
                        >
                            &nbsp;Add new&nbsp;
                        </Badge>
                    </Label>
                    <Table className="table table-hover mb-0">
                        <tbody>
                            {venues.map((venue) => {
                                return (
                                    <>
                                        <tr key={venue.id}>
                                            <td>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        {
                                                            venue.images?.length > 0 ? (
                                                                <img
                                                                    className="rounded avatar-md"
                                                                    data-holder-rendered="true"
                                                                    src={venue.images[0].url}
                                                                    alt=""
                                                                />
                                                            ) : <img
                                                                className="rounded avatar-md"
                                                                data-holder-rendered="true"
                                                                src={'https://allthetables.s3.eu-west-2.amazonaws.com/restaurant_placeholder_grey.jpeg'}
                                                                alt=""
                                                            />
                                                        }
                                                    </div>
                                                    <div className="flex-grow-1" style={{ maxWidth: '300px' }}>
                                                        <h5>{venue.name}</h5>
                                                        <div className="mb-0">
                                                            {venue.cuisineFilters.map((cuisine) => (
                                                                <Badge pill className="badge-soft-success me-1 mb-0">
                                                                    {cuisine.name}
                                                                </Badge>
                                                            ))}
                                                        </div>
                                                        <div className="mt-0"> {formatVenueAddress(venue)} </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    venue.bookingProviders.length === 0 && <Badge pill className="badge-soft-danger me-1">
                                                        No booking providers
                                                    </Badge>
                                                }
                                                {
                                                    venue.bookingProviders.map((provider) => (
                                                        <Badge pill className="badge-soft-info me-1">
                                                            {provider.providerType}
                                                        </Badge>
                                                    ))
                                                }
                                            </td>
                                            <td>
                                                {venue.contact.website}<br /> {venue.contact.phone} <br /> {venue.contact.email}
                                            </td>
                                            <td>
                                                {
                                                    venue.venueLead
                                                        ? <button style={{
                                                            float: 'right',
                                                            marginLeft: 10,
                                                        }} className="btn btn-sm btn-outline-dark waves-effect" onClick={() => history.push(`/venue-directory/${venue.id}`)}>View Lead</button>
                                                        : <button style={{
                                                            float: 'right',
                                                            marginLeft: 10,
                                                        }} className="btn btn-sm btn-outline-dark waves-effect" onClick={() => history.push(`/venue-directory/${venue.id}`)}>Create Lead</button>
                                                }
                                                <div style={{
                                                    minWidth: '200px',
                                                    float: 'right',
                                                }}>
                                                    <button style={{
                                                        float: 'right',
                                                        marginLeft: 10,
                                                    }} className="btn btn-sm btn-outline-dark waves-effect" onClick={() => history.push(`/venue-directory/${venue.id}`)}>View</button>
                                                    <button style={{
                                                        float: 'right',
                                                        marginLeft: 10,
                                                    }} onClick={() => setConfirmUnlink(venue.id)} className="btn btn-outline-danger btn-sm">Unlink</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </Table>

                </div>
            }

            {
                Boolean(confirmUnlink) && <SweetAlert
                    title="Are you sure you want to unlink this venue?"
                    disabled={isUnlinking}
                    error={true}
                    showCancel={true}
                    confirmBtnBsStyle="danger"
                    confirmBtnText="I understand"
                    cancelBtnBsStyle="success"
                    cancelBtnText="Go back"
                    onConfirm={async () => {

                        setIsUnlinking(true)
                        setUnlinkingError(undefined)

                        try {
                            await AttAdminApi.restaurantAccounts.venues.linked.remove(
                                restaurantAccount.id!,
                                confirmUnlink!,
                            )
                            venueRemoved(confirmUnlink!)
                            setConfirmUnlink(undefined)
                        } catch (e: any) {
                            setUnlinkingError(e.message || e || 'An error occurred unlinking your venue. Please try again.')
                        }

                        setIsUnlinking(false)

                    }}
                    onCancel={() => setConfirmUnlink(undefined)}
                >
                    {
                        unlinkingError && <Alert color="danger">{unlinkingError}</Alert>
                    }
                    {
                        isUnlinking
                            ? 'Unlinking...'
                            : 'The venues will no longer be linked to the account, which will effect billing.'
                    }
                </SweetAlert>
            }

            <Modal
                size="xl"
                isOpen={isAddingVenue}
                toggle={() => {
                    setAddingVenue(false);
                }}
                scrollable={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Connect a venue</h5>
                    <button
                        type="button"
                        onClick={() => setAddingVenue(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Cancel"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body">

                    <Card>
                        <CardHeader>
                            <Form>
                                <Row>
                                    <Col md={6}>
                                        <div>
                                            <Label
                                                htmlFor="query-search-input"
                                                className="form-Label"
                                            >
                                                Search
                                            </Label>
                                            <Input
                                                className="form-control"
                                                type="search"
                                                placeholder="Enter a venue name"
                                                id="query-search-input"
                                                value={venueSearchQuery}
                                                onChange={(e) => setVenueSearchQuery(e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div>
                                            <Label
                                                htmlFor="example-search-input"
                                                className="form-Label"
                                            >
                                                Location
                                            </Label>
                                            {isLoaded ? (
                                                <Autocomplete
                                                    onPlaceChanged={() => {
                                                        if (!searchResult) {
                                                            return;
                                                        }
                                                        const placeLocation =
                                                            searchResult.getPlace()?.geometry?.location;

                                                        if (placeLocation) {
                                                            setLocation({
                                                                latitude: parseFloat(placeLocation?.lat().toFixed(8)),
                                                                longitude: parseFloat(placeLocation?.lng().toFixed(8)),
                                                            });
                                                        }
                                                    }}
                                                    onLoad={(autocomplete) =>
                                                        setSearchResult(autocomplete)
                                                    }
                                                >
                                                    <Input
                                                        className="form-control"
                                                        type="search"
                                                        placeholder="Search for a location to narrow your search"
                                                        id="example-search-input"
                                                        ref={placeSearchRef}
                                                    />
                                                </Autocomplete>
                                            ) : (
                                                <>Loading...</>
                                            )}
                                        </div>
                                    </Col>
                                </Row>

                            </Form>
                        </CardHeader>

                        <CardBody className="px-0">
                            <div>
                                <div>
                                    {addingVenueError ? (
                                        <Alert color="danger">{addingVenueError}</Alert>
                                    ) : null}
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Address</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            venueListError && (<Alert color="danger">{venueListError.message || 'An error occurred listing venues.'}</Alert>)
                                        }
                                        {
                                            isLoadingVenueList && 'Loading...'
                                        }
                                        {venueListData?.venues.map((venue) => {
                                            return (
                                                <tr key={venue.id}>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0 me-3">
                                                                {
                                                                    venue.images?.length > 0 ? (
                                                                        <img
                                                                            className="rounded avatar-md"
                                                                            data-holder-rendered="true"
                                                                            src={venue.images[0].url}
                                                                            alt=""
                                                                        />
                                                                    ) : <img
                                                                        className="rounded avatar-md"
                                                                        data-holder-rendered="true"
                                                                        src={'https://allthetables.s3.eu-west-2.amazonaws.com/restaurant_placeholder_grey.jpeg'}
                                                                        alt=""
                                                                    />
                                                                }
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h5>{venue.name}</h5>
                                                                <p className="mb-0">
                                                                    {venue.cuisineFilters.map((cuisine) => (
                                                                        <Badge pill className="badge-soft-success me-1">
                                                                            {cuisine.name}
                                                                        </Badge>
                                                                    ))}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{formatVenueAddress(venue)}</td>
                                                    <td>
                                                        <Button
                                                            disabled={isLoadingAddVenue}
                                                            onClick={async (e) => {
                                                                e.preventDefault();
                                                                setIsLoadingAddVenue(true);
                                                                setAddingVenueError(undefined)
                                                                try {
                                                                    await AttAdminApi.restaurantAccounts.venues.linked.add(
                                                                        restaurantAccount.id,
                                                                        venue.id,
                                                                    );
                                                                    venueAdded(venue.id)
                                                                    setAddingVenue(false)
                                                                } catch (e: any) {
                                                                    setAddingVenueError(e.message || e || 'An error occurred linking your venue. Please try again.')
                                                                }
                                                                setIsLoadingAddVenue(false);
                                                            }}
                                                            className="btn btn-dark"
                                                        >
                                                            Select
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setAddingVenue(false)}
                    >
                        Close
                    </button>
                </div>
            </Modal>

        </React.Fragment>
    )
}

export default RestaurantAccountVenues
