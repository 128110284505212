import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useQuery } from "react-query";
import {
    Card,
    CardBody,
    Container,
    Badge,
    CardHeader,
    Button,
} from "reactstrap";
import { AttAdminApi } from "src/helpers/att_api_helper";
import { PartnerDto } from "src/helpers/att-api-dtos/partner/partner.dto";
import ContentPartnerVenues from "./components/content-partner-venues.component";

interface ContentPartnerPageProps {
    history: any
    match: {
        params: {
            contentPartnerId: string
        }
    }
}

const ContentPartnerPage = (props: ContentPartnerPageProps) => {

    const [partner, setPartner] = useState<PartnerDto>()

    const {
        data: contentPartnerData,
        error: contentPartnerError,
        isLoading: isLoadingContentPartner,
        refetch: fetchContentPartner,
    } = useQuery<
        PartnerDto,
        Error
    >({
        queryKey: [`content-partner`, props.match.params.contentPartnerId],
        queryFn: () => AttAdminApi.partners.get(props.match.params.contentPartnerId),
        refetchOnWindowFocus: false,
        enabled: true,
    });

    useEffect(() => {
        if (contentPartnerData) {
            setPartner(contentPartnerData)
        }
    }, [contentPartnerData])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{partner?.name || 'Content Partner'} | ATT</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Restaurant Accounts" breadcrumbItem={partner?.name || 'Content Partner'} />
                    <div style={{
                        margin: 20,
                    }}>
                        <Card>

                            <CardHeader>
                                <Button color="dark" onClick={() => alert('partner portal not implemented yet')}>
                                    Sign in as partner
                                </Button>
                            </CardHeader>

                            {
                                isLoadingContentPartner && <CardBody>
                                    <h3>Loading...</h3>
                                </CardBody>
                            }
                            {
                                contentPartnerError && <CardBody>
                                    <Badge className="bg-danger">
                                        {contentPartnerError}
                                    </Badge>
                                </CardBody>
                            }
                            {
                                partner && <CardBody>
                                    { // 1] Manage the linked venues
                                    }
                                    <ContentPartnerVenues
                                        history={props.history}
                                        venueAdded={() => fetchContentPartner()}
                                        venueRemoved={() => fetchContentPartner()}
                                        partner={partner}
                                    />

                                    { // 2] Edit the API keys
                                    }

                                    { // 3] Manage the users
                                    }

                                    { // 4] manage payouts & COnnect status
                                    }
                                </CardBody>
                            }
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default ContentPartnerPage
